/**
 * @ngdoc service
 * @name BpmnPartsFactory
 * @module flowingly.bpmn.modeler
 *
 * @description  A service for creating frequently used GoJS BPMN parts
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/3d6860509d68b26fa20076f845bef9c0e6533309/src/Flowingly.Shared.Angular/flowingly.bpmn.modeler/flowingly.bpmn.parts.factory.js?at=master
 */

'use strict';
import angular from 'angular';
import { BpmnModeler } from './@types/services';

angular
  .module('flowingly.bpmn.modeler')
  .factory('BpmnPartsFactory', bpmnPartsFactory);

bpmnPartsFactory.$inject = ['goService', 'BPMN_CONSTANTS', 'BpmnCommonService'];

function bpmnPartsFactory(
  goService: GoJS,
  BPMN_CONSTANTS: BpmnModeler.BpmnConstants,
  BpmnCommonService: BpmnModeler.BpmnCommonService
) {
  const $GO = goService.GraphObject.make;

  const service = {
    getTextBlock,
    paletteIcon
  };
  return service;

  /**
   * This wrapper centers the gojs node and adds the text field to it.
   *
   * @param {any} goJsNode
   */
  function paletteIcon(goJsNode) {
    return $GO(
      goService.Part,
      goService.Panel.Vertical,
      {
        width: BPMN_CONSTANTS.palette.innerWidth,
        selectionAdorned: false
      },
      goJsNode,
      getTextBlock('text', undefined, BPMN_CONSTANTS.PaletteTextColour, 'move')
    );
  }

  function getTextBlock(
    bindingName,
    makeTwoWay,
    color,
    cursor,
    font?,
    editable?
  ) {
    ///
    /// bindingName = name of element to bind to
    /// makeTwoWay = make this a two way binding
    ///
    const binding = makeTwoWay
      ? new goService.Binding(bindingName).makeTwoWay()
      : new goService.Binding(bindingName);
    const textColor = color ? color : BPMN_CONSTANTS.TextColour;
    const textEditable = editable ? editable : BPMN_CONSTANTS.MakeNodesEditable;

    const options = {
      alignment: new goService.Spot(0.5, 0, 0, 3),
      editable: textEditable,
      textEditor: BpmnCommonService.getTextEditingTool(),
      textAlign: 'center',
      stroke: textColor,
      minSize: new goService.Size(BPMN_CONSTANTS.palette.innerWidth, 17),
      maxSize: new goService.Size(BPMN_CONSTANTS.palette.innerWidth, 9999999),
      wrap: goService.TextBlock.WrapFit,
      font: font ? font : 'normal 12px "Open Sans"'
    };
    if (cursor) {
      (options as any).cursor = cursor;
    }
    return $GO(goService.TextBlock, options, binding);
  }

  /// PRIVATE ///////////////////////////////////////////////////////////////////////
}

export type BpmnPartsType = ReturnType<typeof bpmnPartsFactory>;
