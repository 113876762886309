/**
 * @ngdoc service
 * @name brandingService
 * @module flowingly.services
 *
 * @description A service for changing branding
 *
 */

'use strict';
import angular from 'angular';

angular
  .module('flowingly.services')
  .factory('brandingService', brandingService);

brandingService.$inject = [];

function brandingService() {
  const service = {
    setPageTitle: setPageTitle,
    setFavIcon: setFavIcon
  };

  return service;

  //////////// Public API Methods

  function setPageTitle(newTitle: string) {
    document.title = newTitle;
  }

  function setFavIcon(newFavIcon: string) {
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = newFavIcon;
  }
}

export type BrandingServiceType = ReturnType<typeof brandingService>;
