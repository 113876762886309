/**
 * @ngdoc directive
 * @name mention
 * @module flowingly.components
 * @description This is a component for editting text contents with ability to search mentions
 *
 * Input attributes
 * contents: the text contents reference, we need use reference here, then the place consume this component can get latest text contents without extra efforts
 * debounceInterval: the interval in millisecond for model debounce option, eg. how long the interval gap to take next model changes and then search api for match mentions
 *
 * @usage
 * ```
 * <flowingly-mention contents="$ctrl.comment" debounce-interval="$ctrl.debounceInterval"></flowingly-mention>
 * ```
 */

import * as angular from 'angular';
import { ICommentMention } from '../../interfaces/comment-mention.interface';

class MentionComponentController {
  static $inject = [
    '$q',
    'commentApiService',
    'flowinglyMentionService',
    'lodashService',
    'avatarService',
    'notificationService',
    'validationService'
  ];
  public contents: string;
  public commentTargetId: string;
  public flowOwnerOnly: string;
  public debounceInterval: number;
  public hasXssVulnerableString: boolean = false;
  public resultStack: ICommentMention[][] = [];

  constructor(
    private $q: ng.IQService,
    private commentApiService,
    private flowinglyMentionService,
    private lodashService,
    private avatarService,
    private notificationService,
    private validationService
  ) {}

  $onInit() {
    this.debounceInterval = this.debounceInterval || 100;
  }

  /**
   * Find the first finished result in the execution stack.
   * The topmost can be null (because the request have not come back yet)
   */
  get searchResult() {
    for (let i = this.resultStack.length - 1; i >= 0; i--) {
      const result = this.resultStack[i];
      if (result) return result;
    }
  }

  searchAvailableMentions(term: string): void {
    if (term.length > 0) {
      let index = this.resultStack.push(null) - 1; // placeholder

      return this.commentApiService
        .searchMentions(term, this.flowOwnerOnly, this.commentTargetId)
        .then((data: ICommentMention[]) => {
          this.lodashService.forEach(data, (mention) => {
            mention.avatarUrl = this.avatarService.getAvatarUrl(
              mention.actorId
            );
          });

          this.resultStack[index] = data;
          return this.$q.when(data);
        });
    }
  }

  onMentionSelect(item: ICommentMention): string {
    return this.flowinglyMentionService.transformMentionForDisplay(item);
  }

  onContentsChange() {
    this.hasXssVulnerableString = false;
    let commentContents: string =
      this.flowinglyMentionService.transformMentionDisplayToStore(
        this.contents || ''
      );

    if (this.validationService.isXssVulnerableString(commentContents)) {
      this.hasXssVulnerableString = true;
    }
  }

  handlePaste(e): void {
    e.preventDefault();
    let copyData = e.originalEvent.clipboardData.getData('text/plain');

    if (this.flowinglyMentionService.trimSpaces(copyData) !== '') {
      this.contents += copyData;
    }
  }
}

export class MentionComponent implements ng.IComponentOptions {
  public bindings: any;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      contents: '=',
      debounceInterval: '<?',
      flowOwnerOnly: '<',
      commentTargetId: '<'
    };

    this.templateUrl = 'flowingly.mention.tmpl.html';
  }

  controller = MentionComponentController;
}

angular
  .module('flowingly.components')
  .component('flowinglyMention', new MentionComponent());
