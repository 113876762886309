/**
 * @ngdoc service
 * @name browserUtilsService
 * @module flowingly.services
 *
 * @description A service for checking browser vendor etc
 *
 * ## Notes
 *
 * ###API
 * * isIE - check if current browser is IE
 *
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.services/browser-utils.service.js?at=master
 */

'use strict';
import angular from 'angular';

angular
  .module('flowingly.services')
  .factory('browserUtilsService', browserUtilsService);

browserUtilsService.$inject = ['$document'];

function browserUtilsService($document) {
  const service = {
    isIE: isIE
  };
  return service;

  //////////// Public Methods
  function isIE() {
    return $document[0].documentMode != undefined;
  }
}

export type BrowserUtilsServiceType = ReturnType<typeof browserUtilsService>;
