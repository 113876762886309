/*
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/d27046be6d211f40517a7c660b31095886c2e3a7/src/Flowingly.Shared.Angular/flowingly.services/flowingly.api.service.js?at=master
 */

'use strict';
import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

angular
  .module('flowingly.services')
  .factory('flowinglyApiService', flowinglyApiService);

flowinglyApiService.$inject = ['$http', 'APP_CONFIG'];

function flowinglyApiService(
  $http: angular.IHttpService,
  APP_CONFIG: Services.AppConfigCommon
) {
  //API
  const service = {
    getFlowModelsReferencingComponent: getFlowModelsReferencingComponent,
    updateFlowModelsReferencingComponent: updateFlowModelsReferencingComponent
  };

  return service;

  function getFlowModelsReferencingComponent(flowModelId) {
    return $http
      .get<IResponseData>(
        APP_CONFIG.apiBaseUrl +
          `workflow/getFlowModelsReferencingComponent/${flowModelId}`,
        { noSpinner: true }
      )
      .then(function (response) {
        return response.data.dataModel;
      });
  }

  function updateFlowModelsReferencingComponent(flowModelId) {
    return $http
      .post<IResponseData>(
        APP_CONFIG.apiBaseUrl +
          `workflow/updateFlowModelsReferencingComponent/${flowModelId}`,
        { noSpinner: true }
      )
      .then(function (response) {
        return response.data.dataModel;
      });
  }
}

export type FlowinglyApiServiceType = ReturnType<typeof flowinglyApiService>;
