/**
 * @ngdoc service
 * @module flowingly.modeler.services
 * @name componentApiService
 *
 * @description A service for fetching component information from the API
 *
 * Converted to ts on 14/01/2020
 * https://bitbucket.org/flowingly-team/flowingly-source-code/src/47514093659793b4abe7c6b2bc1ba87735ac282e/src/Flowingly.Shared.Angular/flowingly.services/flowingly.component.service.js?at=master
 */

'use strict';
import angular from 'angular';
import { Services } from '../@types/services';

angular
  .module('flowingly.services')
  .factory('componentApiService', componentApiService);

componentApiService.$inject = [
  '$q',
  '$http',
  'APP_CONFIG',
  'flowinglyConstants'
];

function componentApiService(
  $q: angular.IQService,
  $http: angular.IHttpService,
  APP_CONFIG: Services.AppConfigCommon,
  flowinglyConstants: Services.FlowinglyConstants
) {
  //API
  const service = {
    getProcessMapComponents: getProcessMapComponents,
    getWorkflowComponents: getWorkflowComponents
  };

  return service;

  //////////// Public API Methods

  function getProcessMapComponents() {
    return $http
      .get<IUResponseData>(
        APP_CONFIG.apiBaseUrl +
          'modeler/getComponents/' +
          flowinglyConstants.flowModelPublishType.PROCESS_MAP_COMPONENT
      )
      .then(function (response) {
        return response.data.DataModel;
      });
  }

  function getWorkflowComponents() {
    return $http
      .get<IUResponseData>(
        APP_CONFIG.apiBaseUrl +
          'modeler/getComponents/' +
          flowinglyConstants.flowModelPublishType.WORKFLOW_COMPONENT
      )
      .then(function (response) {
        return response.data.DataModel;
      });
  }
}

export type ComponentApiServiceType = ReturnType<typeof componentApiService>;
