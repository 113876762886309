'use strict';
import { IQService } from 'angular';
import { FileFolder } from './flowingly.constants';
import { Services } from '@Shared.Angular/@types/services';

/**
 * @ngdoc service
 * @name fileService
 * @module flowingly.services
 *
 * @description A service for uploading a file to the server
 *
 * ## Notes
 * This is a basic service for uploading files.
 * We may we to investigate using:
 * https://github.com/nervgh/angular-file-upload
 * https://github.com/flowjs/ng-flow
 * 
 * ###Upload Form Data Method
 * 
    Angular’s default transformRequest function will try to serialize our FormData object, 
    so we override it with the identity function to leave the data intact. 
    Angular’s default Content-Type header for POST and PUT requests is application/json, 
    so we want to change this, too. 
    By setting ‘Content-Type’: undefined, the browser sets the Content-Type to multipart/form-data for us and fills in the correct boundary. 
    Manually setting ‘Content-Type’: multipart/form-data will fail to fill in the boundary parameter of the request.

 * ###API
 * * uploadFile - 
 * * removeFile - 
 * 
 * ###Usage
 *     fileService.appendUniqueSuffix(filename)
 *     fileService.uploadFile(apiBaseUrl + 'files/' + user.businessId + '/' + user.id + '/' + $ctrl.stepId + '/' + $ctrl.cell.id + '/' + $ctrl.rowId, fileToUpload).then()
 *     fileService.removeFile(user.businessId, user.id, $ctrl.fileId).then()
 */
declare const BlobBuilder;

export interface IFileMetaData {
  filename: string;
  size: number;
  uploadedDate: string;
}

angular.module('flowingly.services').factory('fileService', fileService);

fileService.$inject = [
  '$q',
  '$http',
  'APP_CONFIG',
  'guidService',
  'notificationService',
  '$window',
  '$timeout',
  'sessionService'
];

function fileService(
  $q: IQService,
  $http: angular.IHttpService,
  APP_CONFIG: Services.AppConfigCommon,
  guidService: Services.GuidService,
  notificationService: Services.NotificationService,
  $window: angular.IWindowService,
  $timeout: angular.ITimeoutService,
  sessionService: Services.SessionService
) {
  const _requestTimeout = '90000';
  let _url = undefined;
  let _userId = undefined;
  let _businessId = undefined;

  const service = {
    appendUniqueSuffix: appendUniqueSuffix,
    getFileMetaData: getFileMetaData,
    getFileById: getFileById,
    getFileByUrl: getFileByUrl,
    download: download,
    downloadById: downloadById,
    downloadByUrl: downloadByUrl,
    base64ToBlob: base64ToBlob,
    removeFile: removeFile,
    deleteFile: deleteFile,
    removeFileForId: removeFileForId,
    uploadFile: uploadFile,
    uploadTableFile: uploadTableFile,
    uploadCommentFile: uploadCommentFile,
    uploadAttachDocumentFile: uploadAttachDocumentFile,
    uploadEmbeddedImage: uploadEmbeddedImage,
    getDownloadLink: getDownloadLink,
    setUser: setUser,
    addFileDownloadClickEventListener: addFileDownloadClickEventListener,
    getBusinessImageIds: getBusinessImageIds,
    getAllowedFileTypes: getAllowedFileTypes,
    reduceImageFile: reduceImageFile,
    resizeImage: resizeImage,
    getFileFromDataUrl: getFileFromDataUrl,
    convertEmbeddedImagesToFileReferences:
      convertEmbeddedImagesToFileReferences,
    replaceFileIdsWithImages: replaceFileIdsWithImages,
    uploadTableAnonymousFile: uploadTableAnonymousFile,
    deleteAnonymousFile: deleteAnonymousFile
  };

  return service;

  //////////// API Methods

  // TODO: FLOW-6316 - This logic is to be redesigned along with the variable substitution logic. Dynamically adding
  // eventListener by manipulating DOM elements doesn't look tidy.
  function addFileDownloadClickEventListener() {
    $timeout(() => {
      const elements = document.getElementsByClassName(
        'file-upload-link-custom-email'
      );
      const getFileAndDownload = function () {
        const dataUrl = this.getAttribute('data-url');
        getFileByUrl(`${APP_CONFIG.apiBaseUrl}${dataUrl}`).success((file) => {
          download(file.data, file.filename, file.mimeType);
        });
      };

      for (let i = 0; i < elements.length; i++) {
        if (angular.element(elements[i]).data('clickApplied') !== true) {
          // Remove the dynamic event listener before adding, in-case to avoid duplicates.
          elements[i].removeEventListener('click', getFileAndDownload, false);
          elements[i].addEventListener('click', getFileAndDownload, false);
          // Attach a custom 'clickApplied' attribute to stamp out the fact that click has already
          // been applied.
          angular.element(elements[i]).data('clickApplied', true);
        }
      }
    });
  }

  function appendUniqueSuffix(filename: string) {
    return filename + '?' + guidService.new();
  }

  function setUser(userId, businessId) {
    _userId = userId;
    _businessId = businessId;
  }

  function getDownloadLink(fileId, isPublic = false) {
    if (isPublic) {
      return `${APP_CONFIG.apiBaseUrl}files/downloadpublic/${fileId}`;
    }
    return `${APP_CONFIG.apiBaseUrl}files/download/${fileId}`;
  }

  function getFileMetaData(fileId: string) {
    return $http
      .get<IFileMetaData>(`${APP_CONFIG.apiBaseUrl}files/${fileId}/meta`)
      .then((response) => response.data);
  }

  function getFileById(fileId, token = sessionService.getToken()) {
    const url = getDownloadLink(fileId, token === null);
    return getFileByUrl(url, token);
  }

  function getFileByUrl(url, token = sessionService.getToken()) {
    const requestConfig = {
      method: 'Get',
      headers: { Authorization: 'Bearer ' + token },
      url: url,
      cache: 'true'
    };

    return $http(requestConfig);
  }

  function downloadById(fileId, token = sessionService.getToken()) {
    return getFileById(fileId, token).success(({ data, filename, mimeType }) =>
      download(data, filename, mimeType)
    );
  }
  function downloadByUrl(url, token = sessionService.getToken()) {
    return getFileByUrl(url, token).success(({ data, filename, mimeType }) =>
      download(data, filename, mimeType)
    );
  }

  /**
        * This function is swiped directly from flow.download.link.directive.
        * See commit fca9d449e5ae3df1e8643e7619c1b9141ab953bc to the original
        * 
        * Documentation for browsers and devices that currently support downloading: FLOW-4239
          https://bizflo.atlassian.net/wiki/spaces/TECH/pages/edit/744390715?draftId=744292514&draftShareId=e7618004-f488-4c61-b3e1-9e54fa9334cc&
        * 
        * @param {any} b64Data
        * @param {any} contentType
        */
  function download(base64Content, filename, contentType) {
    let url;
    const blob = base64ToBlob(base64Content, contentType);
    const isFirefox = /Firefox/.test(navigator.userAgent);
    const isMobileApp = /FlowinglyMobile/.test(navigator.userAgent);
    const isIOSMobileApp =
      isMobileApp && /Apple Computer, Inc./.test(navigator.vendor)
        ? true
        : false;
    /*let isAndroidMobileApp = (isMobileApp && /Google Inc/.test(navigator.vendor)) ? true : false;*/

    if (isFirefox || isMobileApp) {
      url = window.URL || (window as any).webkitURL;
      const fileUrl = url.createObjectURL(blob);

      if (!isMobileApp) {
        $window.open(fileUrl, filename);
        url.revokeObjectURL(fileUrl);
      } else {
        if (!isIOSMobileApp) window.open(fileUrl);
      }
    } else {
      //IE
      if ($window.navigator.msSaveOrOpenBlob)
        $window.navigator.msSaveOrOpenBlob(blob, filename);
      else {
        const link = angular.element(document.createElement('a'));

        url = URL.createObjectURL(blob);

        link.attr('href', url);
        link.attr('download', filename);

        $timeout(function () {
          link[0].click();
        });
      }
    }
  }

  /**
   * This function is swiped directly from flow.download.link.directive.
   * See commit fca9d449e5ae3df1e8643e7619c1b9141ab953bc to the original\
   *
   * http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
   *
   * @param {any} b64Data
   * @param {any} contentType
   */
  function base64ToBlob(b64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    //var blob = new Blob(byteArrays, { type: contentType });
    const blob = new (Blob as any)(byteArrays, {
      encoding: 'UTF-8',
      type: contentType
    });
    return blob;
  }

  function removeFile(fileId, stepId) {
    return $http.post(`${getBaseApiUrl('remove')}/${fileId}/${stepId}`, {});
  }

  function deleteFile(fileId, stepId) {
    return $http.post(
      `${APP_CONFIG.apiBaseUrl}files/remove/${_businessId}/${_userId}/${fileId}/${stepId}`,
      {}
    );
  }

  function removeFileForId(fileId) {
    return $http.post(`${APP_CONFIG.apiBaseUrl}files/remove/${fileId}`, {});
  }

  function uploadFile(stepId, file, fieldkey) {
    const url = `${getBaseApiUrl()}/${stepId}/${fieldkey}`;
    return uploadFileByExtension(url, file);
  }

  function uploadTableFile(url, file) {
    const requestUrl = `${getBaseApiUrl()}${url}`;

    return uploadFileByExtension(requestUrl, file);
  }

  function uploadCommentFile(file) {
    const url = `${APP_CONFIG.apiBaseUrl}files/commentfile`;
    return uploadFileByExtension(url, file);
  }

  function uploadAttachDocumentFile(file) {
    const url = `${APP_CONFIG.apiBaseUrl}files/attachDocument`;
    return uploadFileByExtension(url, file);
  }

  function uploadEmbeddedImage(file, folder: string) {
    const url =
      `${APP_CONFIG.apiBaseUrl}files/embedded` +
      (folder ? `?folder=${folder}` : '');
    return uploadFileByExtension(url, file);
  }

  function uploadFileByExtension(url, file) {
    _url = url;
    // file limit is 50MB
    if (file.size >= 50 * 1024 * 1024) {
      notificationService.showErrorToast(
        'You cannot upload files greater than 50MB'
      );
      return $q.reject();
    }

    const extension = file.name
      .substring(file.name.lastIndexOf('.') + 1)
      .toLowerCase();
    if (
      extension === 'jpg' ||
      extension === 'jpeg' ||
      extension === 'png' ||
      extension === 'bpm'
    ) {
      return getFileObject(file).then(function (fileObject) {
        return uploadImageToServer(fileObject, getFileName(file.name));
      });
    } else {
      return uploadFileToserver(file);
    }
  }

  //////////// Private Methods

  function getBaseApiUrl(action?) {
    return `${APP_CONFIG.apiBaseUrl}files${
      action ? '/' + action : ''
    }/${_businessId}/${_userId}`;
  }

  // The BlobBuilder API has been deprecated in favour of Blob, but older browsers don't know about the Blob constructor
  function blobSafe(data, datatype) {
    let out;

    try {
      out = new Blob([data], { type: datatype });
    } catch (e) {
      (window as any).BlobBuilder =
        (window as any).BlobBuilder ||
        (window as any).WebKitBlobBuilder ||
        (window as any).MozBlobBuilder ||
        (window as any).MSBlobBuilder;

      if (e.name == 'TypeError' && (window as any).BlobBuilder) {
        const bb = new BlobBuilder();
        bb.append(data);
        out = bb.getBlob(datatype);
      } else if (e.name == 'InvalidStateError') {
        out = new Blob([data], { type: datatype });
      }
    }
    return out;
  }

  function dataUrItoBlob(dataUri) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataUri.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataUri.split(',')[1]);
    else byteString = unescape(dataUri.split(',')[1]);

    // separate out the mime component
    const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return blobSafe(ia, mimeString);
  }

  function getFileObject(file) {
    ///
    /// Convert the File object to an image object,
    /// complete with size, height and width
    ///
    const deferredObject = $q.defer();

    const reader = new FileReader();

    //http://stackoverflow.com/questions/12570834/how-to-preview-image-get-file-size-image-height-and-width-before-upload
    reader.onloadend = function (_file: any) {
      // This method is called by reader.readAsDataURL when it completes (pass or fail)
      const result: any = {};
      const image = new Image();
      image.src = _file.target.result;
      image.onload = function () {
        const w = (this as any).width,
          h = (this as any).height,
          s = file.size / 1024 + 'KB';

        result.dataUri = _file.target.result;
        result.width = w;
        result.height = h;
        result.size = s;
        result.name = file.name;

        return deferredObject.resolve(result);
      };
      image.onerror = function () {
        //do nothing
      };
    };

    reader.readAsDataURL(file);

    return deferredObject.promise;
  }

  function reduceImageFile(file, maxWidth, maxHeight) {
    return getImageFromFile(file).then((image) => {
      const maxDataPerPixel = 0.25;
      const data = image.src;
      if (image.height === 0 || image.width === 0) {
        return Promise.reject('No image dimensions available');
      }
      const dataPerPixel = data.length / (image.height * image.width);
      if (
        dataPerPixel <= maxDataPerPixel &&
        image.height <= maxHeight &&
        image.width <= maxWidth
      ) {
        return file;
      }
      const resizedData = resizeImage(image, maxWidth, maxHeight);
      const fileNameParts = file.name.split('.');
      if (fileNameParts.length > 1) {
        fileNameParts[fileNameParts.length - 1] = resizedData.extension;
      } else {
        fileNameParts.push(resizedData.extension);
      }

      const newFile = getFileFromDataUrl(
        resizedData.dataUrl,
        fileNameParts.join('.')
      );
      return newFile;
    });
  }

  function getFileFromDataUrl(dataUrl, fileName) {
    const parts = dataUrl.split(',');
    const mimeType = parts[0].match(/:(.*?);/)[1];
    const data = atob(parts[1]);
    let index = data.length;
    const u8Array = new Uint8Array(index);
    while (index--) {
      u8Array[index] = data.charCodeAt(index);
    }
    return new File([u8Array], fileName, { type: mimeType });
  }

  function getImageFromFile(file): Promise<HTMLImageElement> {
    const promise: Promise<HTMLImageElement> = new Promise(
      (resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (event) {
          const image = new Image();
          image.src = (event.target as any).result;
          resolve(image);
        };
        reader.readAsDataURL(file);
      }
    );
    return promise;
  }

  function resizeImage(
    image,
    maxWidth,
    maxHeight
  ): { dataUrl: string; extension: string } {
    const bufferACanvas = document.createElement('canvas');
    const bufferAContext = bufferACanvas.getContext('2d');
    const bufferBCanvas = document.createElement('canvas');
    const bufferBContext = bufferBCanvas.getContext('2d');
    const currentDimensions = {
      width: Math.floor(image.width),
      height: Math.floor(image.height)
    };
    bufferACanvas.width = currentDimensions.width;
    bufferACanvas.height = currentDimensions.height;
    bufferBCanvas.width = currentDimensions.width;
    bufferBCanvas.height = currentDimensions.height;
    bufferAContext.clearRect(0, 0, bufferACanvas.width, bufferACanvas.height);
    bufferAContext.drawImage(
      image,
      0,
      0,
      bufferACanvas.width,
      bufferACanvas.height
    );
    bufferBContext.clearRect(0, 0, bufferBCanvas.width, bufferBCanvas.height);

    const halfDimensions = {
      width: null,
      height: null
    };
    // Resize in steps to reduce artifacts
    while (
      currentDimensions.width * 0.5 > maxWidth ||
      currentDimensions.height * 0.5 > maxHeight
    ) {
      halfDimensions.width = Math.floor(currentDimensions.width * 0.5);
      halfDimensions.height = Math.floor(currentDimensions.height * 0.5);

      bufferBContext.clearRect(0, 0, bufferBCanvas.width, bufferBCanvas.height);
      bufferBContext.drawImage(
        bufferACanvas,
        0,
        0,
        currentDimensions.width,
        currentDimensions.height,
        0,
        0,
        halfDimensions.width,
        halfDimensions.height
      );

      bufferAContext.clearRect(0, 0, bufferACanvas.width, bufferACanvas.height);
      bufferAContext.drawImage(
        bufferBCanvas,
        0,
        0,
        halfDimensions.width,
        halfDimensions.height,
        0,
        0,
        halfDimensions.width,
        halfDimensions.height
      );

      currentDimensions.width = halfDimensions.width;
      currentDimensions.height = halfDimensions.height;
    }

    if (image.height > maxHeight || image.width > maxWidth) {
      bufferBCanvas.width =
        image.width > image.height
          ? maxWidth
          : Math.floor((maxHeight / image.height) * image.width);
      bufferBCanvas.height =
        image.height > image.width
          ? maxHeight
          : Math.floor((maxWidth / image.width) * image.height);
    } else {
      bufferBCanvas.width = image.width;
      bufferBCanvas.height = image.height;
    }

    const imgData = bufferAContext.getImageData(
      0,
      0,
      bufferACanvas.width,
      bufferACanvas.height
    );
    const data = imgData.data;
    let hasTransparency = false;
    for (let i = 0; i < data.length; i += 4) {
      if (data[i + 3] < 255) {
        hasTransparency = true;
        break;
      }
    }

    if (hasTransparency) {
      bufferBContext.clearRect(0, 0, bufferBCanvas.width, bufferBCanvas.height);
    } else {
      bufferBContext.fillStyle = '#FFF';
      bufferBContext.fillRect(0, 0, bufferBCanvas.width, bufferBCanvas.height);
    }

    bufferBContext.drawImage(
      bufferACanvas,
      0,
      0,
      currentDimensions.width,
      currentDimensions.height,
      0,
      0,
      bufferBCanvas.width,
      bufferBCanvas.height
    );

    if (hasTransparency) {
      return {
        dataUrl: bufferBCanvas.toDataURL('image/png'),
        extension: 'png'
      };
    }

    return {
      dataUrl: bufferBCanvas.toDataURL('image/jpeg', 0.75),
      extension: 'jpg'
    };
  }

  function uploadFileToserver(file) {
    ///
    /// Upload file bytes to server
    ///

    const formData = new FormData(document.createElement('form'));
    formData.append(getFileName(file.name), file);

    return uploadFormData(formData);
  }

  function uploadFormData(formData) {
    /*
                Angular’s default transformRequest function will try to serialize our FormData object, 
                so we override it with the identity function to leave the data intact. 
                Angular’s default Content-Type header for POST and PUT requests is application/json, 
                so we want to change this, too. 
                By setting ‘Content-Type’: undefined, the browser sets the Content-Type to multipart/form-data for us and fills in the correct boundary. 
                Manually setting ‘Content-Type’: multipart/form-data will fail to fill in the boundary parameter of the request.
            */
    const deferredObject = $q.defer();
    const defaultParams = {
      transformRequest: angular.identity,
      timeout: _requestTimeout,
      headers: { 'Content-Type': undefined }
    };
    $http
      .post(_url, formData, defaultParams)
      .success(function (data) {
        deferredObject.resolve({ valid: true, msg: '', data: data });
      })
      .error(function (error) {
        deferredObject.reject({ valid: false, msg: error });
      });

    return deferredObject.promise;
  }

  function uploadImageToServer(fileObject, filename) {
    ///
    /// Upload image bytes to server (after performing any required manipulation
    ///

    const fileBlob = dataUrItoBlob(fileObject.dataUri);

    //there is a different method for Image so that we can check filesize, manipulate image etc if need to
    //additionally we can return the dataUri for displaying thumbnails

    //create form data and add the blob data
    const formData = new FormData(document.createElement('form'));
    formData.append('blob', fileBlob, filename);

    return uploadFormData(formData);
  }

  function getFileName(rawFileName) {
    return rawFileName.substr(rawFileName.lastIndexOf('\\') + 1);
  }

  function getBusinessImageIds() {
    return $http.get(`${APP_CONFIG.apiBaseUrl}files/images/ids`).then(
      (response) => {
        return response.status === 200 ? response.data : null;
      },
      (error) => {
        return null;
      }
    );
  }

  function getAllowedFileTypes(mimeFilter) {
    return $http
      .get(
        `${APP_CONFIG.apiBaseUrl}files/allowedTypes?mimeFilter=${mimeFilter}`
      )
      .then(
        (response) => {
          return response.status === 200 ? response.data : null;
        },
        (error) => {
          return null;
        }
      );
  }

  async function convertEmbeddedImagesToFileReferences(
    sourceHtml: string,
    fieldName: string,
    folder: FileFolder
  ) {
    const timeStamp = new Date().getTime();
    const imageRegex = RegExp(
      `<img[^>]* src=(['"]{1}(data:image/([^;]+)[^'"]+)["']{1})[^>]*>`,
      'g'
    );
    const maxDimension = 850; // Max width of the runner step form at 1920x1080
    const previousDataUrls = [];
    const result = { value: sourceHtml };
    const uploadPromises = [Promise.resolve(result)];

    let regexMatch = imageRegex.exec(sourceHtml);
    let imageIndex = 0;
    while (regexMatch !== null) {
      const localRegexMatch = regexMatch;
      const originalDataUrl = regexMatch[2];
      if (
        previousDataUrls.some(
          (previousDataUrl) => previousDataUrl === originalDataUrl
        )
      ) {
        regexMatch = imageRegex.exec(sourceHtml);
        continue;
      }
      previousDataUrls.push(originalDataUrl);
      if (localRegexMatch[0].indexOf('data-file-id=') > -1) {
        const fileIdRegex = RegExp(`<img data-file-id="([^"]+)" [^>]*>`, 'g');
        const fileIdMatch = fileIdRegex.exec(localRegexMatch[0]);
        const fileId = fileIdMatch[1];
        result.value = result.value.replaceAll(
          localRegexMatch[0],
          `<img data-file-id="${fileId}" />`
        );
        continue;
      }

      const image: HTMLImageElement = await new Promise((resolved) => {
        const imageToLoad = new Image();
        imageToLoad.onload = () => {
          resolved(imageToLoad);
        };
        imageToLoad.src = originalDataUrl;
      });

      if (image.width === 0 || image.height === 0) {
        // Unsupported image types (e.g. .tiff) can be removed as they won't render
        result.value = result.value.replaceAll(localRegexMatch[0], '');
      } else {
        const newData = resizeImage(image, maxDimension, maxDimension);
        const resizedDataUrl = newData.dataUrl;
        const extension = newData.extension;

        const file = getFileFromDataUrl(
          resizedDataUrl,
          `${fieldName}_${timeStamp}_${imageIndex}.${extension}`
        );
        const uploadPromise = uploadEmbeddedImage(file, folder).then(
          (response) => {
            result.value = result.value.replaceAll(
              localRegexMatch[0],
              `<img data-file-id="${response.data}" />`
            );
            return result;
          },
          () => {
            // Upload failed so replace the image with the resized one as a better-than-nothing fallback
            result.value = result.value.replaceAll(
              localRegexMatch[0],
              `<img src="${resizedDataUrl}" />`
            );
            return result;
          }
        );
        uploadPromises.push(uploadPromise);
      }

      regexMatch = imageRegex.exec(sourceHtml);
      imageIndex++;
    }
    return await Promise.all(uploadPromises).then((results) => {
      return results[0].value;
    });
  }

  function replaceFileIdsWithImages(html: string) {
    const imageRegex = RegExp(`<img data-file-id="([^"]+)" [^>]*>`, 'g');
    let regexMatch = imageRegex.exec(html);
    const previousFileIds = [];
    const getFilePromises = [];

    while (regexMatch !== null) {
      const localRegexMatch = regexMatch;
      const fileId = regexMatch[1];
      if (previousFileIds.some((previousFileId) => previousFileId === fileId)) {
        regexMatch = imageRegex.exec(html);
        continue;
      }
      previousFileIds.push(fileId);

      const filePromise = getFileById(fileId).then((response) => {
        const imageHtml =
          `<img data-file-id="${fileId}"` +
          ` src="data:${response.data.mimeType};base64,${response.data.data}" />`;
        html = html.replaceAll(localRegexMatch[0], imageHtml);
      });
      getFilePromises.push(filePromise);

      regexMatch = imageRegex.exec(html);
    }
    return Promise.allSettled(getFilePromises).then(() => {
      return html;
    });
  }

  function uploadTableAnonymousFile(url, file) {
    if (file.size >= 50 * 1024 * 1024) {
      this.notificationService.showErrorToast(
        'You cannot upload files greater than 50MB'
      );
      return Promise.reject('');
    }

    const formData = new FormData(document.createElement('form'));
    const fileName = file.name.substr(file.name.lastIndexOf('\\') + 1);
    formData.append(fileName, file);

    return $http.post(`${APP_CONFIG.apiBaseUrl}${url}`, formData, {
      transformRequest: angular.identity,
      timeout: _requestTimeout,
      headers: { 'Content-Type': undefined }
    });
  }

  function deleteAnonymousFile(fileId: string, stepId: string) {
    return $http.delete(
      `${APP_CONFIG.apiBaseUrl}form/step/${stepId}/file/${fileId}`
    );
  }
}

export type FileServiceType = ReturnType<typeof fileService>;
