/*
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/2a45f5a1603c5731704b21be8fc3199711034ac7/src/Flowingly.Shared.Angular/flowingly.bpmn.modeler/flowingly.bpmn.service.js?at=master
 */

'use strict';
import angular from 'angular';
import { BpmnModeler } from './@types/services';
///
/// Facade service for creating GoJS diagrams using BPMN objects
///

angular.module('flowingly.bpmn.modeler').factory('BpmnService', BpmnService);

BpmnService.$inject = [
  'goService',
  'BpmnGatewayNodeService',
  'BPMN_CONSTANTS',
  'BpmnCommonService',
  'BpmnCommandService',
  'BpmnEventNodeService',
  'BpmnActivityNodeService',
  'BpmnPoolNodeService',
  'BpmnLaneNodeService',
  'BpmnComponentNodeService'
];

function BpmnService(
  goService: GoJS,
  BpmnGatewayNodeService: BpmnModeler.BpmnGatewayNodeService,
  BPMN_CONSTANTS: BpmnModeler.BpmnConstants,
  BpmnCommonService: BpmnModeler.BpmnCommonService,
  BpmnCommandService: BpmnModeler.BpmnCommandService,
  BpmnEventNodeService: BpmnModeler.BpmnEventNodeService,
  BpmnActivityNodeService: BpmnModeler.BpmnActivityNodeService,
  BpmnPoolNodeService: BpmnModeler.BpmnPoolNodeService,
  BpmnLaneNodeService: BpmnModeler.BpmnLaneNodeService,
  BpmnComponentNodeService: BpmnModeler.BpmnComponentNodeService
) {
  initialise();

  const service = {
    getBpmnLinkingTool: getBpmnLinkingTool,
    getDefaultActivityCardModel: getDefaultActivityCardModel,
    getLinkTemplateMap: getLinkTemplateMap,
    getNodeTemplateMap: getNodeTemplateMap,
    getPaletteNodeTemplateMap: getPaletteNodeTemplateMap,
    getGroupTemplateMap: getGroupTemplateMap,
    getPaletteGroupTemplateMap: getPaletteGroupTemplateMap,
    getDrawCommandHandler: getDrawCommandHandler
  };
  return service;

  /// PUBLIC ///////////////////////////////////////////////////////////////////////

  function getBpmnLinkingTool() {
    return BpmnCommonService.getBpmnLinkingTool();
  }

  ///
  /// Get default state of activity node (model)
  ///
  function getDefaultActivityCardModel() {
    return BpmnActivityNodeService.getDefaultActivityCardModel();
  }

  ///
  /// Get a node template map for assigning to a goModel Diagram (within sample.diagram.directive.js)
  ///
  function getNodeTemplateMap() {
    const nodeTemplateMap = new goService.Map('string', goService.Node);
    nodeTemplateMap.add(
      'gateway',
      getNode('gateway', { GatewayNodeSize: 100 })
    );
    nodeTemplateMap.add(
      BPMN_CONSTANTS.GatewayType.EXCLUSIVE_GATEWAY,
      getNode(BPMN_CONSTANTS.GatewayType.EXCLUSIVE_GATEWAY, {
        GatewayNodeSize: 100
      })
    );
    nodeTemplateMap.add(
      BPMN_CONSTANTS.GatewayType.DIVERGE,
      getNode(BPMN_CONSTANTS.GatewayType.DIVERGE, { GatewayNodeSize: 100 })
    );
    nodeTemplateMap.add(
      BPMN_CONSTANTS.GatewayType.CONVERGE,
      getNode(BPMN_CONSTANTS.GatewayType.CONVERGE, { GatewayNodeSize: 100 })
    );
    nodeTemplateMap.add('event', getNode('event'));
    nodeTemplateMap.add('activity', getNode('activity'));
    nodeTemplateMap.add('component', getNode('component'));

    return nodeTemplateMap;
  }
  ///
  /// Gets the group nodes template map for assigning to the 'groupTemplateMap' property
  /// in the go diagram.
  ///
  function getGroupTemplateMap() {
    const groupTemplateMap = new goService.Map('string', goService.Group);
    groupTemplateMap.add('Pool', getNode('Pool'));
    groupTemplateMap.add('Lane', getNode('Lane'));
    return groupTemplateMap;
  }

  ///
  /// Get a link template map. This describes how the links look when loaded.
  ///
  function getLinkTemplateMap() {
    const linkTemplateMap = new goService.Map('string', goService.Link);
    linkTemplateMap.add('', getLinkTemplate()); // default
    return linkTemplateMap;
  }

  ///
  /// Get a node template map for assigning to a goModel Palette (within sample.palette.directive.js)
  ///
  function getPaletteNodeTemplateMap() {
    const nodeTemplateMap = new goService.Map();

    nodeTemplateMap.add('event', getPaletteNode('event'));
    nodeTemplateMap.add('activity', getPaletteNode('activity'));

    nodeTemplateMap.add(
      BPMN_CONSTANTS.GatewayType.EXCLUSIVE_GATEWAY,
      getPaletteNode(BPMN_CONSTANTS.GatewayType.EXCLUSIVE_GATEWAY)
    );
    nodeTemplateMap.add(
      BPMN_CONSTANTS.GatewayType.DIVERGE,
      getPaletteNode(BPMN_CONSTANTS.GatewayType.DIVERGE)
    );
    nodeTemplateMap.add(
      BPMN_CONSTANTS.GatewayType.CONVERGE,
      getPaletteNode(BPMN_CONSTANTS.GatewayType.CONVERGE)
    );

    nodeTemplateMap.add('component', getPaletteNode('component'));
    return nodeTemplateMap;
  }

  ///
  /// Get a group template map for assigning to a goModel Palette (within sample.palette.directive.js)
  ///
  function getPaletteGroupTemplateMap() {
    const palGroupTemplateMap = new goService.Map();
    ////palGroupTemplateMap.add("subprocess", getPaletteNode("subprocess"));
    palGroupTemplateMap.add('Pool', getPaletteNode('Pool'));
    //palGroupTemplateMap.add("Lane", getPaletteNode("Lane"));
    return palGroupTemplateMap;
  }

  ///
  /// Get a custom command handler for draw events; for assigning to a goModel Diagram (within sample.diagram.directive.js)
  ///
  function getDrawCommandHandler() {
    return BpmnCommandService.getDrawCommandHandler();
  }

  /// PRIVATE ///////////////////////////////////////////////////////////////////////
  function initialise() {
    createEmptyShape();
  }

  function getLinkTemplate() {
    return BpmnCommonService.getLinkTemplate();
  }

  /// Get a node of the specified category, defined by the (optional) options
  /// If options is not supplied, default values will be used
  function getNode(category, options?) {
    switch (category) {
      case BPMN_CONSTANTS.GATEWAY: // a generic gateway
        return BpmnGatewayNodeService.getNode(category, options);

      case BPMN_CONSTANTS.GatewayType.EXCLUSIVE_GATEWAY:
        return BpmnGatewayNodeService.getNode(category, options);

      case BPMN_CONSTANTS.GatewayType.DIVERGE:
        return BpmnGatewayNodeService.getNode(category, options);

      case BPMN_CONSTANTS.GatewayType.CONVERGE:
        return BpmnGatewayNodeService.getNode(category, options);

      case 'event':
        return BpmnEventNodeService.getNode(options);

      case 'activity':
        return BpmnActivityNodeService.getNode(options);

      case 'Pool':
        return BpmnPoolNodeService.getNode(options);

      case 'Lane':
        return BpmnLaneNodeService.getNode(options);

      case 'component':
        return BpmnComponentNodeService.getNode(options);
    }
  }

  /// Get a node of the specified category, defined by the (optional) options
  /// If options is not supplied, default values will be used
  function getPaletteNode(category, options?) {
    switch (category) {
      case 'gateway': //palette only has a generic node
        return BpmnGatewayNodeService.getPaletteNode(options, true);

      case BPMN_CONSTANTS.GatewayType.EXCLUSIVE_GATEWAY:
        return BpmnGatewayNodeService.getPaletteNode(options, true);

      case BPMN_CONSTANTS.GatewayType.DIVERGE:
        return BpmnGatewayNodeService.getPaletteNode(options, false, false);

      case BPMN_CONSTANTS.GatewayType.CONVERGE:
        return BpmnGatewayNodeService.getPaletteNode(options, false, true);

      case 'event':
        return BpmnEventNodeService.getPaletteNode(options);

      case 'activity':
        return BpmnActivityNodeService.getPaletteNode(options);

      case 'Pool':
        return BpmnPoolNodeService.getPaletteNode(options);

      case 'Lane':
        return BpmnLaneNodeService.getPaletteNode(options);

      case 'component':
        return BpmnComponentNodeService.getPaletteNode(options);
    }
  }

  function createEmptyShape() {
    goService.Shape.defineFigureGenerator('Empty', function (shape, w, h) {
      return new goService.Geometry();
    });
  }
}

export type BpmnServiceType = ReturnType<typeof BpmnService>;
