import angular from 'angular';

import {
  FormFieldConditionOption,
  FormFieldConditionAction,
  FormFieldType
} from '../flowingly.services/flowingly.constants';

export default class ConditionalFormService {
  static $inject = [];
  private fieldsAddedToUI: any[];
  private conditionsToExecute: any[];

  constructor() {
    this.fieldsAddedToUI = [];
    this.conditionsToExecute = [];
  }

  storeFieldAddedToUI(field: any) {
    this.fieldsAddedToUI.push(field);
  }

  refreshUI(triggerField: any, form: any) {
    const fieldSchema = triggerField.schema;
    if (fieldSchema.conditions != null) {
      for (let i = 0; i < fieldSchema.conditions.length; i++) {
        try {
          const condition = fieldSchema.conditions[i];
          const triggerFieldValue = form.data[fieldSchema.name];
          this.storeConditionToExecute(condition, triggerField);
          this.executeCondition(triggerField, triggerFieldValue);
        } catch (error) {
          console.error(error);
        }
      }
    }
  }

  isFieldHidden(fieldName: string) {
    const field = this.fieldsAddedToUI.find(
      (field) => field.name === fieldName
    );

    if (field != null && field.isVisible != null) {
      if (field.isVisible === false) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  isFieldHiddenByConditions(fieldName: string) {
    const field = this.fieldsAddedToUI.find(
      (field) => field.name === fieldName
    );

    if (field != null && field.isHiddenByConditions != null) {
      return field.isHiddenByConditions;
    }
    return false;
  }

  emptyFieldsArray() {
    if (this.fieldsAddedToUI.length === 0) {
      return;
    }
    this.fieldsAddedToUI = [];
  }

  private storeConditionToExecute(condition: any, triggerField: any) {
    this.conditionsToExecute.push({
      triggerField: triggerField.name,
      actionField: condition.actions[0].actionField,
      condition: condition.decisions[0].condition,
      action: condition.actions[0].action,
      triggerFieldType: triggerField.schema.type,
      decisionValue: condition.decisions[0].value
    });
  }

  private executeCondition(triggerField: any, triggerFieldValue: any) {
    for (let i = 0; i < this.fieldsAddedToUI.length; i++) {
      const conditionToExecute = this.conditionsToExecute.find(
        (o) =>
          o.triggerField == triggerField.name &&
          o.actionField == this.fieldsAddedToUI[i].schema.name
      );

      if (this.isConditionValid(conditionToExecute)) {
        const targetField = this.fieldsAddedToUI[i];
        switch (conditionToExecute.condition.toLowerCase()) {
          case FormFieldConditionOption[
            FormFieldConditionOption.Equals
          ].toLowerCase(): {
            if (
              triggerFieldValue != null &&
              !angular.equals({}, triggerFieldValue)
            ) {
              if (
                this.areValuesEqual(
                  triggerField,
                  conditionToExecute.decisionValue,
                  triggerFieldValue
                )
              ) {
                this.executeAction(
                  conditionToExecute.action,
                  targetField,
                  true
                );
              } else {
                this.executeAction(
                  conditionToExecute.action,
                  targetField,
                  false
                );
              }
            }
            break;
          }
          case FormFieldConditionOption[
            FormFieldConditionOption.NotEquals
          ].toLowerCase(): {
            if (
              triggerFieldValue != null &&
              !angular.equals({}, triggerFieldValue)
            ) {
              if (
                this.areValuesEqual(
                  triggerField,
                  conditionToExecute.decisionValue,
                  triggerFieldValue
                )
              ) {
                this.executeAction(
                  conditionToExecute.action,
                  targetField,
                  false
                );
              } else {
                this.executeAction(
                  conditionToExecute.action,
                  targetField,
                  true
                );
              }
            }
            break;
          }
          case FormFieldConditionOption[
            FormFieldConditionOption.Empty
          ].toLowerCase(): {
            if (this.isFieldEmpty(triggerField, triggerFieldValue)) {
              this.executeAction(conditionToExecute.action, targetField, true);
            } else {
              this.executeAction(conditionToExecute.action, targetField, false);
            }
            break;
          }
          case FormFieldConditionOption[
            FormFieldConditionOption.Filled
          ].toLowerCase(): {
            if (!this.isFieldEmpty(triggerField, triggerFieldValue)) {
              this.executeAction(conditionToExecute.action, targetField, true);
            } else {
              this.executeAction(conditionToExecute.action, targetField, false);
            }
            break;
          }
        }
      }
    }
  }

  private isConditionValid(conditionToExecute: any) {
    if (!conditionToExecute) {
      return false;
    }

    if (!conditionToExecute.condition || conditionToExecute.condition === -1) {
      return false;
    }

    if (!conditionToExecute.action || conditionToExecute.action === -1) {
      return false;
    }

    return true;
  }

  private isAnEmptyObject(fieldValue: object) {
    return Object.keys(fieldValue).length === 0;
  }

  private isFieldEmpty(triggerField: any, fieldValue: any) {
    switch (triggerField.schema.type.toLowerCase()) {
      case FormFieldType.TASK_LIST:
      case FormFieldType.MULTISELECT_LIST: {
        if (
          fieldValue === null ||
          fieldValue === '' ||
          fieldValue === undefined
        ) {
          return true;
        }

        if (this.isAnEmptyObject(fieldValue)) {
          return true;
        } else {
          const value = JSON.stringify(fieldValue).indexOf('true') !== -1;
          return !value;
        }
      }
      case FormFieldType.CHECKBOX: {
        if (fieldValue === false) {
          return true;
        }
        return (
          fieldValue === null || fieldValue === '' || fieldValue === undefined
        );
      }
      case FormFieldType.NUMBER:
      case FormFieldType.CURRENCY: {
        if (
          fieldValue === undefined ||
          fieldValue === null ||
          fieldValue === '' ||
          Number.isNaN(fieldValue)
        ) {
          return true;
        }
        return false;
      }
      default: {
        return (
          fieldValue === null || fieldValue === '' || fieldValue === undefined
        );
      }
    }
  }

  private areValuesEqual(
    triggerField: any,
    expectedValue: any,
    currentFieldValue: any
  ) {
    switch (triggerField.schema.type.toLowerCase()) {
      case FormFieldType.RADIO_BUTTON_LIST:
      case FormFieldType.DROPDOWN_LIST: {
        return expectedValue === currentFieldValue;
      }
      case FormFieldType.MULTISELECT_LIST: {
        const valueInCurrentFieldValueObj =
          currentFieldValue[Number(expectedValue)];

        if (valueInCurrentFieldValueObj === true) {
          return true;
        }

        return false;
      }
      case FormFieldType.CHECKBOX: {
        return currentFieldValue;
      }
      default: {
        console.warn(
          `'${triggerField.schema.type.toLowerCase()}' is not supported to be a decision field for conditional rules.`
        );
        return false;
      }
    }
  }

  private executeAction(action: any, targetField: any, valueIsTruthy: boolean) {
    switch (action) {
      case FormFieldConditionAction[
        FormFieldConditionAction.Show
      ].toLowerCase(): {
        if (valueIsTruthy) {
          targetField.isVisible = true;
          targetField.isHiddenByConditions = false;
        } else {
          targetField.isVisible = false;
          targetField.isHiddenByConditions = true;
        }
        break;
      }
      case FormFieldConditionAction[
        FormFieldConditionAction.Hide
      ].toLowerCase(): {
        if (valueIsTruthy) {
          targetField.isVisible = false;
          targetField.isHiddenByConditions = true;
        } else {
          targetField.isVisible = true;
          targetField.isHiddenByConditions = false;
        }
        break;
      }
      default: {
        console.warn('Unknown action!');
        break;
      }
    }
  }
}

angular
  .module('flowingly.services')
  .service('conditionalFormService', ConditionalFormService);

export type ConditionalFormServiceType = InstanceType<
  typeof ConditionalFormService
>;
