/**
 * @name flowIfEnvironment
 * @module flowingly.directives
 * @description Custom ng-if implementation that shows the elmenent only if the current environmenent matches the one(s) defined.
 * ### Notes
 * see: based on official ng-if directive
 *       https://github.com/angular/angular.js/blob/master/src/ng/directive/ngIf.js
 *
 * NOTES:
 * Obviously this is a temporary solution, since it requires the code to be updated each time environment changes
 * Relies on the Flowingly Environment being set on the window (it currently is)
 *
 * @usage
 * ```
 *   //show div if the environment is qa
 *   <div if-environment="['qa']" ></div>
 *
 *   //show div if the user has qa OR development
 *   <div if-environment="['qa', 'permission2']" ></div>
 *
 * ```
 *
 * Convert to 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.directives/permissions/flow.if.environment.directive.js?at=master
 */

'use strict';

import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

angular.module('flowingly.directives').directive('ifEnvironment', [
  '$parse',
  'APP_CONFIG',
  'permissionsService',
  function ifPermitted(
    $parse: angular.IParseService,
    APP_CONFIG: Services.AppConfigCommon,
    permissionsService: Services.PermissionsService
  ) {
    return {
      multiElement: true,
      transclude: true,
      priority: 400,
      terminal: false,
      restrict: 'A',
      link: function (
        $scope: angular.IScope,
        $element: JQuery<Element>,
        $attr: angular.IAttributes,
        ctrl: angular.IController,
        $transclude: angular.ITranscludeFunction
      ) {
        const currentEnvironment = APP_CONFIG.flowinglyEnvironment;
        const allowedEnvironments = $parse($attr.ifEnvironment)($scope);

        doEnvironmentCheck();

        function doEnvironmentCheck() {
          let correctEnvrionment = false;

          //if there are no environment defined; then we dont need to check
          const skipCheck =
            allowedEnvironments === undefined ||
            allowedEnvironments.length === 0;

          if (!skipCheck) {
            correctEnvrionment = permissionsService.checkEnvironment(
              allowedEnvironments,
              currentEnvironment
            );
          }

          if (skipCheck || correctEnvrionment) {
            $transclude(function (clone) {
              $element.append(clone);
            });
          }
        }
      }
    };
  }
]);
