'use strict';
import angular from 'angular';
angular
  .module('flowingly.services')
  .factory('smartlookService', smartlookService);

smartlookService.$inject = ['$window'];

function smartlookService($window: angular.IWindowService) {
  const service = {
    AttemptToIdentifyUser: attemptToIdentifyUser,
    AttemptToEnableInputRecording: attemptToEnableInputRecording
  };

  return service;

  function attemptToIdentifyUser(user, attempt = 0) {
    if ($window.smartlook) {
      const script = document.createElement('script');
      script.textContent =
        `smartlook("identify", "${user.email}", ` +
        JSON.stringify({
          id: user.id,
          businessId: user.businessId,
          businessName: user.businessName
        }) +
        ');';
      document.head.appendChild(script);
    } else if (attempt < 10) {
      setTimeout(() => attemptToIdentifyUser(user, attempt + 1), 500);
    } else {
      console.error('Smartlook not found');
    }
  }

  function attemptToEnableInputRecording(attempt = 0) {
    if ($window.smartlook) {
      const script = document.createElement('script');
      script.textContent =
        "smartlook('record', { forms: true, numbers: true, emails: true });";
      document.head.appendChild(script);
    } else if (attempt < 10) {
      setTimeout(() => attemptToEnableInputRecording(attempt + 1), 500);
    } else {
      console.error('Smartlook not found');
    }
  }
}

export type SmartlookServiceType = ReturnType<typeof smartlookService>;
