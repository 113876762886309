/*
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/2da9828445bec39275c1bdfcdfe4e3dc1ff1f531/src/Flowingly.Shared.Angular/flowingly.components/facade/kendo.datasource.facade.js?at=master
 */
(function () {
  'use strict';
  /**
   *  Because kendo datasource is so confusing and does not natively support
   *  our use case of fetching data as you type unless you use the autocomplete
   *  module. However the autocomplete module does not have the same features
   *  as the combobox (which we want).
   *
   *  So we use a facade to simplify the implementation details.
   */
  angular.module('flowingly.components').factory('kendoDataSourceFacade', [
    '$q',
    'lodashService',
    function ($q, lodashService) {
      const { isArray, isFunction } = lodashService;

      class DataSourceFacade {
        kendoDsConfig: any;
        data: any;
        kendoDS: any;

        constructor(data) {
          this.data = data;
          this.kendoDsConfig = {
            serverFiltering: isFunction(data),
            transport: {
              read: this.dataBridge()
            }
          };
          this.kendoDS = new kendo.data.DataSource(this.kendoDsConfig);
        }

        /**
         * Reruns the underlying kendo data retrieval.
         * Kendo hands off the data to the kendo input.
         */
        update() {
          return this.kendoDS.read();
        }

        /**
         * Returns a function that can be used as a databridge
         * between Kendo and the flowingly app.
         *
         * Typically this would be the read value you supply
         * to a new Kendo Datasource.
         *
         * @return {function}
         */
        dataBridge() {
          const me = this;
          return function (options) {
            return me.fetchData(me.data).then((fetchedResults) => {
              this._lastFetchedRes = fetchedResults;
              options.success(fetchedResults);
            });
          };
        }

        getCurrentList() {
          return this.kendoDS.transport._lastFetchedRes;
        }

        /**
         * Given data, return it as a promise.
         * If given an array, resolve to the array.
         * If given a function, resolve to the return value.
         *
         * @param {array|function} data
         * @param {string} term
         * @return {promise}
         */
        fetchData(data, term?) {
          if (isArray(data)) {
            return $q.resolve(data);
          } else if (isFunction(data)) {
            return $q.resolve(data(term));
          } else {
            throw Error(
              'Unsupported type supplied to kendo as data. Only Array or FN'
            );
          }
        }

        /**
         * Returns the underlying Kendo datasource.
         *
         * @returns {kendo.data.Datasource}
         */
        getAsKendoBridge() {
          return this.kendoDS;
        }
      }

      return DataSourceFacade;
    }
  ]);
})();
