(function () {
  'use strict';

  /**
   * The kendo event names are ambiguous,
   * so we rename it in this module through
   * this map for clarity.
   */
  const FACADE_KENDO_EVENT_MAP = {
    onSelect: 'change',
    onChange: 'filtering',
    onClose: 'close',
    onOpen: 'open',
    onDataBound: 'dataBound'
  };

  class ComboBoxFacadeController {
    // from bindings
    ngModel: any; // the ngModel will be assigned the whole object.
    data: any; // ARRAY or FUNCTION
    // if function, the function must return an array.
    // is capable of handling promises.
    kOptions: any; // OBJECT   kendo options if you want to get down and dirty
    autocomplete: any; // BOOLEAN  if true, runs DATA every search
    fieldText: any; // STRING   maps the display text to this field. DEFAULTS to Name
    fieldValue: any; // STRING   maps the value to this field. DEFAULTS to Id
    placeholder: any; // STRING   placeholder value
    minLength: any; // NUMBER   min length before searching
    clearButton: any; // BOOLEAN  k-clear-button
    isEnabled: any; // BOOLEAN  works the same as ng-disabled="!value"
    noEmpty: any; // BOOLEAN  disallows empty
    onChange: any; // FUNCTION fires everytime you change the input
    onSelect: any; // FUNCTION activates when an item is selected
    onOpen: any; // FUNCTION runs when you open the box
    onClose: any; // FUNCTION executes when you close the box

    private _kendoConfig: any;
    private _previousVal: any;
    private _data: any;
    private _dataContext: any;
    private svcs: any;
    private dataSource: any;
    private kendoOptions: any;

    constructor(
      $timeout,
      $attrs,
      $q,
      lodashService,
      DataSourceFacade,
      $element,
      $scope
    ) {
      /**
       * Webpack build somehow not injecting values into this pointer the way grunt build does
       * So need to pass data from $scope.$ctrl to this pointer to avoid the Exception data is a required attribute for kendo-combo-box-facade and its extensions
       */
      const {
        data,
        clearButton,
        minLength,
        fieldText,
        fieldValue,
        placeholder,
        isEnabled,
        autocomplete,
        noEmpty
      } = $scope.$ctrl;
      this.data = data;
      this.clearButton = clearButton;
      this.minLength = minLength;
      this.fieldText = fieldText;
      this.fieldValue = fieldValue;
      this.placeholder = placeholder;
      this.isEnabled = isEnabled;
      this.autocomplete = autocomplete;
      this.noEmpty = noEmpty;

      if (!this.data)
        throw new Error(`data is a required attribute for 
                                kendo-combo-box-facade and its extensions`);

      this.svcs = {
        $attrs,
        $q,
        lodashService,
        DataSourceFacade,
        $timeout,
        $element,
        $scope
      };
      const { defaults } = lodashService;
      const { onChange, onSelect, onOpen, onClose } = $scope.$ctrl;

      this._kendoConfig = {};
      this.setAttr('clearButton', this.clearButton, true)
        .setAttr('minLength', this.minLength, 2)
        .setAttr('dataTextField', this.fieldText, 'Name')
        .setAttr('dataValueField', this.fieldValue, 'Id')
        .setAttr('placeholder', this.placeholder, '')
        .setAttr('enable', this.isEnabled, true)
        .setAutoComplete(this.autocomplete)
        .setNoEmpty(this.noEmpty)
        .event('onChange', onChange)
        .event('onSelect', onSelect)
        .event('onClose', onClose)
        .event('onOpen', onOpen)
        .initDataSource();

      // special case for non-zero min length
      // because in most cases ngModel will initially be zero length
      // When zero length, it fails the minLength requirement
      // so no initial data will be outed.
      if (this._kendoConfig.minLength > 0) {
        $timeout(() => {
          this.dataSource.update();
        }, 100);
      }

      this.kendoOptions = defaults(this.getKendoConfig(), this.kOptions);
    }

    /**
     * Return the kendo json options that you
     * can plug into the combobox
     */
    getKendoConfig() {
      return this._kendoConfig;
    }

    /**
     * Set a field on the underlying kendo configuration.
     *                      *
     * @param {string} field
     * @param {any} val
     * @param {any} defaultVal
     */
    setAttr(field, val, defaultVal) {
      const value = val === undefined ? defaultVal : val;
      if (value !== undefined) {
        this._kendoConfig[field] = value;
      }
      return this;
    }

    /**
     * Wrapper for setAttr that converts ngDisabled
     * to enable
     *
     * @param {any} isDisabledRaw
     */
    setDisable(isDisabledRaw) {
      const isDisabled = isDisabledRaw === undefined ? false : isDisabledRaw;
      this.setAttr('enable', !isDisabled, true);
    }

    /**
     * Creates the data source and assigns it
     * to the kendoConfig datasource
     *
     * @param {array|function} data
     */
    initDataSource(data?) {
      this.dataSource = new this.svcs.DataSourceFacade(this.data);
      this._kendoConfig.dataSource = this.dataSource.getAsKendoBridge();
      return this;
    }

    /**
     * Attach an event to the component.
     *
     * @param {string} event Name of the event
     * @param {function} cb Callback of the event.
     */
    event(event, cb) {
      if (event && cb) {
        // We have renamed the events in facade because of
        // the ambiguity in the name.
        const eventName = FACADE_KENDO_EVENT_MAP[event];
        if (!eventName) {
          throw new Error('Event is upassable to Kendo');
        }

        // we dont override, we keep it just incase another reusable
        // component needs these events.
        if (this._kendoConfig[eventName]) {
          const { flow } = this.svcs.lodashService;
          cb = flow(this._kendoConfig[eventName], cb);
        }
        this._kendoConfig[eventName] = cb;
      }
      return this;
    }

    setAutoComplete(isOn) {
      if (!isOn) {
        return this;
      }
      const me = this;

      this._data = this.data;
      this.data = function () {
        const context = me.getDataContext();
        return me._data(context);
      };

      const autoCompleteConfig = {
        delay: 500,
        autoBind: false,
        ignoreCase: true,
        filter: 'contains',
        filtering: function ({ sender }) {
          const context = me.createDataContext();
          context.term = sender.input.val();
        }
      };

      this._kendoConfig = {
        ...this._kendoConfig,
        ...autoCompleteConfig
      };

      return this;
    }

    createDataContext() {
      this._dataContext = {};
      return this._dataContext;
    }

    getDataContext() {
      return this._dataContext || this.createDataContext();
    }

    setNoEmpty(shouldBeNoEmpty) {
      if (shouldBeNoEmpty) {
        const me = this;

        // on initialize if not empty is on we replace ngModel with the first result
        me.event('onDataBound', () => {
          if (!me.ngModel) {
            me.ngModel = me.dataSource.getCurrentList()[0];
            const cb = this._kendoConfig['change'];
            if (cb) {
              me.svcs.$timeout(() => cb(), 10);
            }
          }
          me._previousVal = me.ngModel;
        });

        const restorePreviousModelIfNone = function (event) {
          if (!event) {
            return;
          }
          const fieldText = me._kendoConfig.dataTextField;
          const { find } = me.svcs.lodashService;

          const currentList = me.dataSource.getCurrentList();
          const searchTerm = event.sender.input.val();
          const exists = find(currentList, {
            [fieldText]: searchTerm
          });

          if (!exists) {
            event.sender.input.val(me._previousVal[fieldText]);
            me.ngModel = me._previousVal;
          } else {
            me._previousVal = me.ngModel;
          }
        };

        me.event('onSelect', restorePreviousModelIfNone);
        me.event('onClose', restorePreviousModelIfNone);
      }
      return this;
    }

    // Kendo binds it to the scope via kendo-combo-box
    get KendoComboBoxAPI() {
      return this.svcs.$scope.KendoComboBoxAPI;
    }
  }

  /**
   * This is a facade for the kendo combobox. It is hard
   * to use for our use case, so we decorate the directive for
   * easier reuse.
   *
   * Example:
   *
   *      <kendo-combo-box>           // original
   *      <kendo-combo-box-facade>    // new one
   *
   *      // TYPICAL USAGE
   *      <kendo-combo-box-facade
   *          data="(ARRAY || FUNCTION)"
   *          ng-model="">
   *
   *          </kendo-combo-box-facade>
   *
   */
  ComboBoxFacadeController.$inject = [
    '$timeout',
    '$attrs',
    '$q',
    'lodashService',
    'kendoDataSourceFacade',
    '$element',
    '$scope'
  ];

  angular
    .module('flowingly.components')
    .controller('kendoComboBoxFacadeController', ComboBoxFacadeController);
  angular.module('flowingly.components').component('kendoComboBoxFacade', {
    bindings: {
      ngModel: '=', // the ngModel will be assigned the whole object.

      data: '<', // ARRAY or FUNCTION
      // if function, the function must return an array.
      // is capable of handling promises.

      kOptions: '<', // OBJECT   kendo options if you want to get down and dirty
      autocomplete: '<', // BOOLEAN  if true, runs DATA every search

      fieldText: '@', // STRING   maps the display text to this field. DEFAULTS to Name
      fieldValue: '@', // STRING   maps the value to this field. DEFAULTS to Id
      placeholder: '@', // STRING   placeholder value
      minLength: '@', // NUMBER   min length before searching
      clearButton: '<', // BOOLEAN  k-clear-button
      isEnabled: '<', // BOOLEAN  works the same as ng-disabled="!value"
      noEmpty: '<', // BOOLEAN  disallows empty

      onChange: '<', // FUNCTION fires everytime you change the input
      onSelect: '<', // FUNCTION activates when an item is selected
      onOpen: '<', // FUNCTION runs when you open the box
      onClose: '<' // FUNCTION executes when you close the box
    },
    controller: 'kendoComboBoxFacadeController',
    template: `
                    <select 
                        style="width:100%;"
                        kendo-combo-box="KendoComboBoxAPI"
                        k-ng-model="$ctrl.ngModel"
                        k-options="$ctrl.kendoOptions">
                    </select>
                `
  });
})();
