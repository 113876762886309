/*
 * @ngdoc service
 * @name pubsubService
 * @module flowingly.services
 *
 * @description A service for publishing and subcribing to events.
 *
 * ## Notes
 * Events are only fired if:
 * * The event exists in the eventNames array
 * * There is at least one subscriber to that event
 * 
 * ###API
 * * subscriptions - list of all subcribers, organised by event name
 * * subscribe - registers a callback method to be called when the named event is publish
 * * publish - publish an event of given name
 * 
 * ###Usage
 *    
 * pubsubService.subscribe('WORKFLOW_PUBLISHED', onWorkflowPublished, 'workflow.canvas.controller');
 * pubsubService.publish('WORKFLOW_PUBLISHED', {});
 *
 * pubsubService.subscribe('FORM_TABLE_VALID', clearTableError, 'runnerCardController', $ctrl.formErrors);
 * 
 *     function setTableError(event, data, param) {
                  param.table = undefined;
                  param.table = true;
       }


* Converted to ts on 14/01/2020
* See https://bitbucket.org/flowingly-team/flowingly-source-code/src/be47d178bb5e304e72ef443ecfbee02850192a8d/src/Flowingly.Shared.Angular/flowingly.services/pubSub.service.js?at=master
*/

'use strict';
import angular from 'angular';
angular.module('flowingly.services').factory('pubsubService', pubsubService);

pubsubService.$inject = ['APP_CONFIG', '$timeout', 'lodashService'];

function pubsubService(APP_CONFIG, $timeout, lodashService) {
  const _logging = APP_CONFIG.enableConsoleLogging;

  let isConnectionReady = false;

  let pendingPublishCol = [];

  const systemEventNames = [
    'READY_FOR_SIGNALR',
    'SIGNALR_CONNECTED',
    'SIGNALR_CONNECT_FAILED'
  ];

  const noneSystemEventNames = [
    //signalR events
    'SIGNALR_WORKFLOW_UNPUBLISHED_CHANGES',
    'SIGNALR_WORKFLOW_PUBLISHED',
    'SIGNALR_WORKFLOW_UNPUBLISHED',
    'SIGNALR_FLOW_MODEL_STATUS_CHANGED',
    'SIGNALR_NEW_FLOW_MODEL_SAVED',
    'SIGNALR_USER_PROFILE_UPDATED', //currently only used when avatar deleted / updated / added server side
    'SIGNALR_USER_NOTIFICATION_COUNT_CHANGED',
    'SIGNALR_RUNNER_NEW_FLOW_COMMENT_COUNT',
    'SIGNALR_RUNNER_NEW_FLOW_COMMENT',
    'SIGNALR_SETUP_CATEGORY_DELETED', //used to update client side lists
    'SIGNALR_SETUP_FLOW_MODEL_DELETED', //used to update client side lists
    'SIGNALR_RUNNER_WITHDRAW_FLOW',
    'SIGNALR_RUNNER_COMPLETE_STEP',
    'SIGNALR_RUNNER_REASSIGN_STEP',
    'SIGNALR_RUNNER_START_FLOW',
    'SIGNALR_WORKFLOW_NAME_CHANGED',
    'SIGNALR_ACTOR_DELETED',
    'SIGNALR_RUNNER_USER_TEAM_UPDATED',
    'SIGNALR_RUNNER_STEP_WEBHOOK_UPDATED',

    'SIGNALR_RUNNER_STEP_TASK_CANCELLED',
    'SIGNALR_RUNNER_NEW_STEP_TASK_COMMENT', // When a new step task comment is added in the API.
    'SIGNALR_RUNNER_STEP_TASK_CREATED', // When a step task is created in the API.
    'SIGNALR_RUNNER_STEP_TASK_UPDATED', // When a step task is updated in the API.

    'SIGNALR_RUNNER_STEP_INTEGRATION_PROCESSING_UPDATED',

    //Modeler events
    'CARD_DESIGNER_CLOSE_EDITORS',
    'CARD_DESIGNER_OPEN_EDITORS',
    'CARD_DESIGNER_DRAG_DROP',

    'CARD_UPDATED',

    'DIAGRAM_LINK_INSERTED',
    'DIAGRAM_LINK_RELINKED',
    'DIAGRAM_LINK_DELETED',
    'DIAGRAM_PARTS_DELETED',
    'DIAGRAM_NODE_SELECTED',
    'DIAGRAM_POSITION_CHANGED',
    'DIAGRAM_CLICKED',
    'DIAGRAM_NODE_CLICKED',
    'DIAGRAM_ELEMENT_DROPPED',
    'TASK_PANEL_OPENCLOSE',
    'GATEWAY_FIELD_CHANGED',
    'WORKFLOW_NODE_CHANGED',
    'STEP_TYPE_CHANGED',
    'GATEWAY_TYPE_CHANGED',
    'GATEWAY_DEFAULT_GATE_CHANGED',
    'DIAGRAM_MODIFIED',
    'DIAGRAM_LOADED',
    'SELECTED_NODE_CHANGED',
    'MODELER_FILE_EVENT',
    'MODELER_ACTION_EVENT',
    'WORKFLOW_VALID', //the workflow has no errors (after validation) //TODO consider combinig wiht blow = WORKFLOW_VALIDATED
    'WORKFLOW_INVALID', //the workflow has one or more errors (after validation)
    'WORKFLOW_UPDATED', //the worklow or the associated schema has changed (new / imported / saved)
    'WORKFLOW_LOADED', //a workflow has been loaded
    'STEP_REF_SEQUENCE_CHANGED', // when step ref sequence has changed
    'STEP_ACTOR_CHANGED', // when actor is assigned to a step
    'NOTIFY_INITIATOR_CHANGED', //when the user toggles => Notify Initiator when this step is completed?
    'PUBLICFORM_SETTING_CHANGED', //when the user toggles => Make this a public form
    'WORKFLOW_SAVE_COMPLETED',
    'WORKFLOW_PUBLISHED',
    'WORKFLOW_UNPUBLISHED',
    'WORKFLOW_CLONED',
    'UPDATE_EDITING',
    'WORKFLOW_DESIGNER_FORM_FIELDS_CHANGED',
    'WORKFLOW_DESIGNER_FORM_FIELDS_ADDED',
    'FORM_FIELD_NAME_OR_STEP_NAME_CHANGED',
    'ADD_NEW_SWIM_LANE',
    'REMOVE_SWIM_LANE',
    'STEPRULE_SETTING_CHANGED',
    'WORKFLOW_PUBLISH_PROCESSED',

    'IMAGEUPLOAD_UPLOAD_STARTED',
    'IMAGEUPLOAD_UPLOAD_COMPLETED',

    //RUNNER EVENTS
    'RUNNER_RESIZE_GRID',
    'STEP_NEW_NUDGE', //when step waiting on actor get nudged, thus need notify area which display step nudge history
    'FILEUPLOAD_FILE_ERROR', //a file is required, but not present on form
    'FILEUPLOAD_FILE_VALID', //a file is required and present on form (or not required)
    'FILEUPLOAD_FILE_READY',
    'FILEUPLOAD_UPLOAD_STARTED',
    'FILEUPLOAD_UPLOAD_COMPLETED',
    'FILEUPLOAD_REMOVAL_STARTED',
    'FILEUPLOAD_REMOVAL_COMPLETED',
    'FILEUPLOAD_UPLOAD_FAILED', //file upload has failed for some reason
    'FORM_TABLE_VALID',
    'FORM_TABLE_INVALID',

    //GENERAL EVENTS
    'WINDOW_BLUR',
    'USER_LOGGED_IN',
    'USER_LOGGED_OUT',
    'FILE_SELECTED',
    'CLIENT_USER_PROFILE_UPDATED', //user's profile &/Or avatar is updated

    //INTERCOM EVENTS
    'INTERCOM_UNREAD_MESSAGE_COUNT_CHANGED',

    // When integration settings has been updated, (for ex. to show/hide integration icon)
    'STEP_INTEGRATION_SETTING_CHANGED',

    'STEP_TASK_ENABLED_CHANGED', // When step tasks have been enabled/disabled on a node in Modeler.
    'STEP_TASK_CREATED', // When a step task is created
    'STEP_TASK_UPDATED', // When a step task is updated
    'STEP_TASK_CANCELLED', // When a step task is canceled

    'WORKFLOW_FIELDS_UPDATED', // When runner step fields are updated.

    'KENDO_FACADE_UPDATE_COLLECTION', // When collection bound to kendo-combobox-facade needs reloading.

    //V2(REACT) EVENTS
    'PROCESSMAPV2_CLICKED',
    'PROCESSMAPV2_LOADED',
    'PROCESSMAPVIEWV2_LOADED'
  ];

  const eventNames = systemEventNames.concat(noneSystemEventNames);

  const service = {
    // event subscriptions that are broadcasted.
    subscriptions: {},
    subscribe: subscribe,
    publish: publish,
    subscribeAll: subscribeAll,
    unsubscribeAll: unsubscribeAll
  };

  initialise();

  return service;

  ///
  /// public methods
  ///

  function subscribeAll(callback, subscriberId, param) {
    logInfo(subscriberId + ' subscribeAll');
    const subscriber = {
      id: subscriberId,
      callback: callback,
      param: param
    };
    if (subscriberId) {
      lodashService.forEach(service.subscriptions, function (event) {
        lodashService.pullAllBy(event, [{ id: subscriberId }], 'id');
      });
    }
    lodashService.forEach(service.subscriptions, function (event) {
      event.push(subscriber);
    });
  }

  function subscribe(event, callback, subscriberId, param?) {
    ///
    /// Subscribe for notifications of an event
    /// event - the event name to subscribe to
    /// callback - method to call when this event is published
    /// subscriberId - optional subscriber id to prevent multiple subscriptions
    /// param - optional param that will be passed to the callback method when it is invoked
    ///
    //allow subscription only for known events
    if (eventNames.indexOf(event) !== -1) {
      const subscriber = {
        id: subscriberId,
        callback: callback,
        param: param
      };
      //If an id was provided, remove the old subscriptionso that it can be re-added
      //It is important we re-add it as the previous subscription might relate to an object that has been destroyed
      if (subscriberId) {
        lodashService.pullAllBy(
          service.subscriptions[event],
          [{ id: subscriberId }],
          'id'
        );
      }
      //subscribe
      service.subscriptions[event].push(subscriber);
      logInfo('subscribe: ' + subscriberId + ' to: ' + event);
    } else {
      console.error('tyring to subscribe to unknown event: ' + event);
    }
    return service;
  }

  function publish(event, data) {
    //capture this state of the subscriptions before timeout
    //in case they change before timeout activates (on state chnage);
    const subscribers = service.subscriptions[event];

    $timeout(function () {
      //get all the subscribers for this event

      logInfo('try publish event ' + event);

      let len = subscribers ? subscribers.length : 0;

      logInfo('publishing event ' + event);
      logInfo('subscribers ' + len);

      //apparently while(--len) doesn't work for some reason
      // it throws an exception for subscribers[len] once at the start
      while (len) {
        len -= 1;
        logInfo('to ' + subscribers[len].id);

        if (
          (isConnectionReady || systemEventNames.indexOf(event) !== -1) &&
          subscribers[len].callback &&
          angular.isFunction(subscribers[len].callback)
        ) {
          try {
            //call the callback registered with the event & data
            subscribers[len].callback(event, data, subscribers[len].param);
          } catch (e) {
            logError(e);
          }
        } else {
          if (!isConnectionReady) {
            pendingPublishCol.push({
              event: event,
              data: data
            });
          }
        }
      }
    }, 0);
  }

  //finds all subscriptions across all events that match this id and removes them
  function unsubscribeAll(subscriberId) {
    for (const propertyName in service.subscriptions) {
      if (service.subscriptions[propertyName].length > 0) {
        lodashService.pullAllBy(
          service.subscriptions[propertyName],
          [{ id: subscriberId }],
          'id'
        );
      }
    }
  }

  function unsubscribe(event, subscriberId) {
    const index = service.subscriptions[event].indexOf(subscriberId);
    service.subscriptions[event].splice(index, 1);
  }

  ///
  /// private methods
  ///

  function initialise() {
    //we first register known events that can be subscribed to
    lodashService.forEach(eventNames, function (eventName) {
      service.subscriptions[eventName] = [];
    });

    subscribe(
      'SIGNALR_CONNECTED',
      function () {
        isConnectionReady = true;

        //execute all pending publish as now connection is established
        lodashService.forEach(pendingPublishCol, function (evt) {
          publish(evt.event, evt.data);
        });
        pendingPublishCol = [];
      },
      'runner.signalR.client'
    );

    subscribe(
      'SIGNALR_CONNECT_FAILED',
      function () {
        isConnectionReady = false;
      },
      'runner.signalR.client'
    );
  }

  function logInfo(message) {
    if (_logging && (_logging == 'true' || _logging == true)) {
      console.log(message);
    }
  }

  function logError(message) {
    if (_logging && (_logging == 'true' || _logging == true)) {
      console.error(message);
    }
  }
}

// get type of pubsubService
export type PubSubServiceType = ReturnType<typeof pubsubService>;
