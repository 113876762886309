/** * @ngdoc directive
 * @name flowSidemenu
 * @module flowingly.directives.sidemenu
 * @description A directive to managing the side menu
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Sidemenu+Directive
 * The menu content is initialised in the sideMenuService
 * 
   ####Attributes
   * hide-to-small if true the menu will shrink rather than close (hide)
   * default-open: if true the sidemenu will start open
   * auto-hide: menu will auto hide on mobile screens
   * open-menu: if true open the sidemenu, else close it
   * mobile: width at which mobile style menu displayed (for desktop set to undefined)
   * is-mobile: if in mobile view
   * icon-open: icon to show when menu open
   * icon-closed: icon to show when menu closed
   * logoutMethod: method to call when logout clicked
 * 
 * @usage
 * ```
     <flow-sidemenu
         default-open="true
         mobile="600"
        icon-open="APP_CONFIG.runnerUrl + '/Client/dist/vendor/_con/logo.png'"
        icon-closed="APP_CONFIG.runnerUrl + '/Client/dist/vendor/_con/logo-white.png'"
        icon-small="APP_CONFIG.runnerUrl + '/Client/assets/flowingly_icon_filled.png'">
     </flow-sidemenu>
 * ```
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/d66b8d4edb33f3681884377575fcbe5c7727d9f8/src/Flowingly.Shared.Angular/flowingly.directives/sidemenu/sidemenu.directive.js?at=master
 */

'use strict';
import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

angular.module('flowingly.directives.sidemenu').directive('flowSidemenu', [
  '$document',
  '$state',
  'sideMenuService',
  'pubsubService',
  'userApiService',
  'avatarService',
  'APP_CONFIG',
  'authService',
  function (
    $document: angular.IDocumentService,
    $state: angular.ui.IStateService,
    sideMenuService: Services.SideMenuService,
    pubsubService: Services.PubSubService,
    userApiService: Services.UserApiService,
    avatarService: Services.AvatarService,
    APP_CONFIG: Services.AppConfigCommon,
    authService: Services.AuthService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,

      templateUrl: 'sidemenu.tmpl.html',
      scope: {
        hideToSmall: '=',
        defaultOpen: '=',
        autoHide: '=',
        openMenu: '=',
        authService: '=?',
        sessionService: '=',
        mobile: '=',
        isMobile: '=',
        iconOpen: '=',
        iconClosed: '=',
        iconSmall: '=',
        logoutMethod: '&',
        pageHeader: '='
      },
      controllerAs: 'ctrl',
      controller: [
        '$scope',
        '$timeout',
        '$window',
        function (
          $scope: angular.IScope,
          $timeout: angular.ITimeoutService,
          $window: angular.IWindowService
        ) {
          const ctrl = this;

          ctrl.brandLogoClass = APP_CONFIG.brandLogoClass;

          const menuModes = {
            closed: 'closed',
            desktopPinned: 'desktopPinned',
            desktopShrunk: 'desktopShrunk',
            desktopHidden: 'desktopHidden',
            mobile: 'mobile'
          };
          let menuMode = undefined;
          if ($scope.sessionService === undefined) {
            $scope.sessionService = {
              isLoggedIn: function () {
                return false;
              },
              user: {}
            };
          }
          //ctrl.authenticated = $scope.authService.isAuthenticated();
          ctrl.authenticated = true;

          ctrl.open = $scope.defaultOpen;
          ctrl.autoHide = $scope.autoHide;
          ctrl.openUserMenu = false;
          ctrl.mobile = $scope.mobile;
          // get the menu structure from the menu service
          ctrl.menu = sideMenuService.menu;

          ctrl.logout = function () {
            //call the method passed in
            $scope.logoutMethod().then(function () {
              ctrl.openUserMenu = false;
            });
          };

          ctrl.editProfile = function () {
            $state.go('app.runner.profile');
          };

          ctrl.toggle = function () {
            ctrl.open = !ctrl.open;
            applyMenuMode(setMenuMode());

            // not the best to use hard coded waiting value, but need it to make resize grid working
            // if not wait, the grid resize too earlier, and not get the right width
            $timeout(function () {
              pubsubService.publish('RUNNER_RESIZE_GRID');
            }, 500);
          };

          ctrl.sideMenuClicked = function () {
            if (ctrl.mobile !== undefined) {
              if ($window.innerWidth <= ctrl.mobile || $scope.isMobile) {
                closeSideMenu();
              }
            }
          };

          function closeSideMenu() {
            ctrl.open = false;
            applyMenuMode(menuModes.closed);
          }

          function setMenuMode() {
            if (ctrl.mobile !== undefined) {
              if ($window.innerWidth <= ctrl.mobile || $scope.isMobile) {
                menuMode = menuModes.mobile;
              } else {
                menuMode = ctrl.open
                  ? menuModes.closed
                  : menuModes.desktopPinned;
                return menuMode;
              }
            } else {
              if (ctrl.open && $scope.hideToSmall) {
                menuMode = menuModes.desktopPinned;
              } else if (ctrl.open) {
                menuMode = menuModes.desktopPinned;
              }
            }
            if (!ctrl.open) {
              if ($scope.hideToSmall) {
                menuMode = menuModes.desktopShrunk;
              } else {
                menuMode = menuModes.closed;
              }
              return menuMode;
            }

            return menuMode;
          }

          function applyMenuMode(menuMode) {
            const yaybar = angular.element(
              $document[0].getElementsByClassName('yaybar')
            );
            const sideToggle = angular.element(
              $document[0].getElementsByClassName('side-toggle')
            );
            const contentwrap = angular.element(
              $document[0].getElementsByClassName('content-wrap')
            );

            yaybar.removeClass('hide-menu-menu');
            yaybar.removeClass('menu-desktop-shrunk-menu');
            yaybar.removeClass('menu-desktop-pinned-menu');
            contentwrap.removeClass('hide-menu-content');
            contentwrap.removeClass('menu-mobile-content');
            contentwrap.removeClass('menu-desktop-shrunk-content');
            contentwrap.removeClass('menu-desktop-pinned-content');

            switch (menuMode) {
              case menuModes.mobile:
                contentwrap.addClass('menu-mobile-content');
                return;

              case menuModes.desktopPinned:
                yaybar.addClass('menu-desktop-pinned-menu');
                contentwrap.addClass('menu-desktop-pinned-content');
                sideToggle.removeClass('hamburger');
                sideToggle.addClass('close-cross');
                return;

              case menuModes.desktopShrunk:
                yaybar.addClass('menu-desktop-shrunk-menu');
                contentwrap.addClass('menu-desktop-shrunk-content');
                sideToggle.addClass('hamburger');
                sideToggle.removeClass('close-cross');
                return;

              case menuModes.desktopHidden:
                yaybar.addClass('menu-desktop-hidden-menu');
                contentwrap.addClass('menu-desktop-hidden-content');
                return;

              default:
                //hide
                yaybar.addClass('hide-menu-menu');
                contentwrap.addClass('hide-menu-content');
                contentwrap.removeClass('menu-mobile');
                contentwrap.removeClass('menu-desktop-pinned-content');
                contentwrap.removeClass('menu-desktop-shrunk-content');
                contentwrap.removeClass('menu-desktop-hidden-content');
                yaybar.removeClass('menu-desktop-pinned-menu');
                yaybar.removeClass('menu-desktop-shrunk-menu');
                yaybar.removeClass('menu-desktop-hidden-menu');
                return;
            }
          }

          function updateUser(user) {
            if (user.id === undefined) {
              return;
            }
            ctrl.user = user;
            ctrl.user.avatarUrl = avatarService.getAvatarUrl(ctrl.user.id);
          }

          function logoutDeletedUser(jsonParams) {
            const params = JSON.parse(jsonParams);
            if (params.actorId !== ctrl.user.id) {
              return;
            }
            authService.logout();
          }

          const init = function () {
            if ($scope.authService) {
              ctrl.user = $scope.authService.getUser();
              //ctrl.user.fullName = ctrl.user.fullName;
              //ctrl.user.email = ctrl.user.email;

              if (!avatarService.haveAvatarList()) {
                userApiService.getUserAvatars().then((data) => {
                  avatarService.setActorsWithAvatars(data);
                  ctrl.user.avatarUrl = avatarService.getAvatarUrl(
                    ctrl.user.id
                  );
                });
              } else {
                ctrl.user.avatarUrl = avatarService.getAvatarUrl(ctrl.user.id);
              }
            }

            $scope.open = $scope.defaultOpen;

            $scope.$watch('openMenu', function (newValue, oldValue) {
              if (newValue !== oldValue) {
                ctrl.open = newValue;
                applyMenuMode(setMenuMode());
              }
            });
            applyMenuMode(setMenuMode());

            //hide the menu on small screens (if configured)
            $window.onresize = function () {
              applyMenuMode(setMenuMode());
            };

            const SUBSCRIBER_ID = 'sidemenu.directive';
            pubsubService.subscribe(
              'CLIENT_USER_PROFILE_UPDATED',
              (event, user) => updateUser(user),
              SUBSCRIBER_ID
            );
            pubsubService.subscribe(
              'SIGNALR_ACTOR_DELETED',
              (event, params) => logoutDeletedUser(params),
              SUBSCRIBER_ID
            );

            $scope.$on('$destroy', () => {
              pubsubService.unsubscribeAll(SUBSCRIBER_ID);
            });
          };

          init();
        }
      ]
    };
  }
]);
