/**
 * @name flowUserMenu
 * @module flowingly.user.menu
 * @description A DUMB component is used to display a user menu.
 * ### Notes
 * It is intended that it is rendered by a SMART component that handles interaction with server.
 * Right now, this is the sidemenu.
 * @usage
 * ```
    <flow-user-menu ng-if="ctrl.authenticated"
                    user="ctrl.user"
                    on-logout-method="ctrl.logout()">
        </flow-user-menu>
 * ```
 *
 * Converted to ts on 16/01/2020
 * SEe https://bitbucket.org/flowingly-team/flowingly-source-code/src/5bd2d7cc9ee013a523e9f3f4dc73ea1a19435c8c/src/Flowingly.Shared.Angular/flowingly.directives/usermenu/usermenu.directive.js?at=master
 */
'use strict';
import angular from 'angular';
import { Services } from '@Shared.Angular/@types/services';

angular.module('flowingly.user.menu').component('flowUserMenu', {
  templateUrl: 'usermenu.tmpl.html',
  bindings: {
    user: '<',
    iconSmall: '<',
    isMobile: '<',
    onLogoutMethod: '&'
  },

  controller: [
    '$scope',
    '$state',
    'dialogService',
    'APP_CONFIG',
    'tokenService',
    'permissionsService',
    'flowinglyConstants',
    'sessionService',
    'appInsightsService',
    function (
      $scope: angular.IScope,
      $state: angular.ui.IStateService,
      dialogService: Services.DialogService,
      APP_CONFIG: Services.AppConfigCommon,
      tokenService: Services.TokenService,
      permissionsService: Services.PermissionsService,
      flowinglyConstants: Services.FlowinglyConstants,
      sessionService: Services.SessionService,
      appInsightsService: Services.AppInsightsService
    ) {
      const $ctrl = this;
      $ctrl.editProfile = editProfile;
      $ctrl.logout = logout;
      $ctrl.showTenantDialog = showTenantDialog;
      $ctrl.viewTemplatesClicked = viewTemplatesClicked;
      $ctrl.showSupportButton = showSupportButton(APP_CONFIG.hideSupportButton);
      $ctrl.enableTenantSwitching = APP_CONFIG.enableTenantSwitching;
      $ctrl.tenant = tokenService.getTenant();
      $ctrl.tenantName = $ctrl.tenant.name || $ctrl.user.businessName;
      $ctrl.showEditProfile = !sessionService.inTenantBusiness();

      sessionService.onReady(() => {
        this.templateLibraryEnabled = APP_CONFIG.enableTemplateLibrary;
        $ctrl.helpLink = APP_CONFIG.brandingHelpUri;
        $ctrl.helpTitle = APP_CONFIG.brandingHelpTitle;
        $ctrl.showSupportButton = showSupportButton(
          APP_CONFIG.hideSupportButton
        );
        $ctrl.showTenantSwitch =
          APP_CONFIG.enableTenantSwitching &&
          permissionsService.currentUserHasPermission(
            flowinglyConstants.permissions.TENANT_ACCESS
          );
      });

      function showSupportButton(configHide) {
        return (
          (APP_CONFIG.hasLoaded && !configHide) ||
          permissionsService.currentUserHasPermission(
            flowinglyConstants.permissions.SUPPORT_ACCESS
          )
        );
      }

      function editProfile() {
        if (!$ctrl.showEditProfile) {
          return;
        }
        const userData = {};
        angular.copy($ctrl.user, userData);

        dialogService.showDialog({
          template:
            'Client/runner.setup/runner.setup.users/runner.setup.users.dialog.tmpl.html',
          controller: 'userDialogController',
          appendClassName: 'ngdialog-normal',
          scope: $scope,
          data: userData
        });
      }

      function showTenantDialog() {
        const tenant = {};
        angular.copy($ctrl.tenant, tenant);

        dialogService.showDialog({
          template: 'Client/runner.tenant/runner.tenant.dialog.tmpl.html',
          controller: 'tenantDialogController',
          appendClassName: 'ngdialog-normal',
          scope: $scope,
          data: { tenant: tenant, userBusinessId: $ctrl.user.businessId }
        });
      }

      function logout() {
        $ctrl.onLogoutMethod();
        $ctrl.openUserMenu = false;
      }

      function viewTemplatesClicked() {
        appInsightsService.trackPageView('template-library', {
          from: 'usermenu'
        });

        $state.go('app.runner.templates.library');
      }
    }
  ]
});
