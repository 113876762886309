/**
 * @ngdoc component
 * @name addComment
 * @module flowingly.components
 * @description This is a component for adding a new comment / feedback to flow / flow model
 *
 * Input attributes
 * commentTargetId - the id of comment target
 *
 * Output attributes
 * onAddComment - callback when add a new comment
 *
 * @usage
 * ```
 * <add-comment comment-target-type="1" comment-target-id="$ctrl.commentTargetId" load-comments="$ctrl.loadComments() on-add-comment="$ctrl.onAddComment({ comment: comment })"></add-comment>
 * ```
 */

import angular from 'angular';
import { IComment, CommentType } from '../../interfaces/comment.interface';
import { ICommentMention } from '../../interfaces/comment-mention.interface';

class AddCommentComponentController {
  static $inject = [
    '$timeout',
    'flowinglyMentionService',
    'commentApiService',
    'notificationService',
    'sessionService',
    'validationService',
    'flowinglyConstants',
    'fileService',
    'pubsubService',
    'tokenService'
  ];
  public commentTargetId: string;
  public commentTargetType: string;
  public flowOwnerOnly: boolean;
  public addCommentHeader: string;
  public userId: string;
  public loadComments: () => void;
  public onAddComment: (comment) => void;
  public debounceInterval = 100;
  private commentContent: string;
  private comment: IComment;
  private isAddButtonDisabled: boolean;
  public fileList: any[] = [];
  public commentTargetTypeFromReact: string;

  constructor(
    private $timeout: ng.ITimeoutService,
    private flowinglyMentionService,
    private commentApiService,
    private notificationService,
    private sessionService,
    private validationService,
    private flowinglyConstants,
    private fileService,
    private pubsubService,
    private tokenService
  ) {
    if (
      this.commentTargetType ==
        this.flowinglyConstants.commentTargetType.FLOW ||
      this.commentTargetType ==
        this.flowinglyConstants.commentTargetType.STEP_TASK
    ) {
      this.addCommentHeader = 'Add a comment';
    } else {
      this.addCommentHeader = 'Add feedback';
    }

    this.flowOwnerOnly = this.flowOwnerOnly || false;
  }

  $onInit() {
    this.pubsubService.subscribe(
      'SIGNALR_RUNNER_COMPLETE_STEP',
      () => {
        this.removeAllFiles();
      },
      'add-comment.component'
    );

    this.pubsubService.subscribe(
      'FILEUPLOAD_UPLOAD_STARTED',
      () => {
        this.disableAddButton();
      },
      'add-comment.component'
    );

    this.pubsubService.subscribe(
      'FILEUPLOAD_UPLOAD_COMPLETED',
      () => {
        this.enableAddButton();
      },
      'add-comment.component'
    );

    this.pubsubService.subscribe(
      'FILEUPLOAD_UPLOAD_FAILED',
      () => {
        this.enableAddButton();
      },
      'add-comment.component'
    );
  }

  addComment(): void {
    this.$timeout(() => {
      const mentions: ICommentMention[] =
        this.flowinglyMentionService.extractMentionsFromCommentText(
          this.commentContent || ''
        );
      const commentContents: string =
        this.flowinglyMentionService.transformMentionDisplayToStore(
          this.commentContent || ''
        );
      let confirmText, warningText;

      if (
        this.commentTargetType ==
          this.flowinglyConstants.commentTargetType.FLOW ||
        this.commentTargetType ==
          this.flowinglyConstants.commentTargetType.STEP_TASK
      ) {
        confirmText = 'Comment added';
        warningText = 'Cannot add empty comment';
      } else {
        confirmText = 'Feedback added';
        warningText = 'Cannot add empty feedback';
      }

      if (this.flowinglyMentionService.trimSpaces(commentContents) === '') {
        this.notificationService.showWarningToast(warningText);
        return;
      } else if (
        this.validationService.isXssVulnerableString(commentContents)
      ) {
        this.notificationService.showErrorToast(
          'The comment field contains invalid text. Please remove any HTML or JavaScript text from the field.'
        );
        return;
      }
      this.comment = {
        comment: commentContents,
        mentions: mentions,
        fileIds: this.fileList.map((f) => f.id),
        userId: this.sessionService.getUser().id,
        flowOwnerOnly: this.flowOwnerOnly,
        commentType: CommentType.UserComment
      };

      this.commentApiService
        .addFlowComment(
          this.commentTargetTypeFromReact == null ? this.commentTargetType : this.commentTargetTypeFromReact,
          this.commentTargetId,
          this.comment
        )
        .then(() => {
          this.commentContent = '';

          // notify parent component of the new comment just added.
          // As data is not from backend, need manually fill some of the details
          const currentUser = this.sessionService.getUser();
          const newComment: IComment = {
            fullName: currentUser.fullName,
            avatarUrl: currentUser.avatarUrl,
            userId: currentUser.id,
            commentedByName: currentUser.fullName,
            momentCommented: 'A Few Seconds Ago',
            comment: commentContents,
            files: this.fileList,
            commentType: CommentType.UserComment
          };
          this.onAddComment({ comment: newComment });
          this.fileList = [];

          this.notificationService.showSuccessToast(confirmText);
        });
    }, this.debounceInterval);
  }

  onFileUploaded(file) {
    this.fileList.push(file);
  }

  disableAddButton() {
    this.isAddButtonDisabled = true;
  }

  enableAddButton() {
    this.isAddButtonDisabled = false;
  }

  removeFile(index) {
    this.disableAddButton();
    const file = this.fileList.splice(index, 1)[0];
    const user = this.sessionService.getUser();
    this.fileService.setUser(user.id, this.tokenService.getTenant().id);
    this.fileService
      .removeFileForId(file.id)
      .then(() => {
        this.notificationService.showSuccessToast(file.filename + ' Removed');
        this.enableAddButton();
      })
      .catch(() => this.enableAddButton());
  }

  removeAllFiles() {
    if (!this.fileList || this.fileList.length === 0) return;

    const fileIds = this.fileList.map((f) => f.id);
    fileIds.forEach((id) => this.fileService.removeFileForId(id));

    this.fileList = [];
  }

  $onDestroy() {
    this.removeAllFiles();
  }
}

export class AddCommentComponent implements angular.IComponentOptions {
  public bindings: Bindings;
  public templateUrl: string;

  /* TODO: 2 way binding of commentTargetType isn't working when in react compoenent. 
      Adding a workaround until we re-write this in react or figure out why this commentTargetType bidning needs to be a 2 way binding
      it doesnt look like it needs to be but it breaks the component. Leaving this re-visit later */

  constructor() {
    this.bindings = {
      commentTargetId: '<',
      commentTargetType: '@',
      flowOwnerOnly: '<',
      onAddComment: '&',
      commentTargetTypeFromReact: '<'
    };

    this.templateUrl = 'add-comment.tmpl.html';
  }

  controller = AddCommentComponentController;
}

angular
  .module('flowingly.components')
  .component('addComment', new AddCommentComponent());
