/*
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/3931026c9aa9244a3d822dda422d31e927183539/src/Flowingly.Shared.Angular/flowingly.services/flowingly.http-error-interceptor.js?at=master
 */
(function () {
  'use strict';

  angular
    .module('flowingly.services')
    .factory('flowinglyHttpErrorInterceptor', flowinglyHttpErrorInterceptor);

  flowinglyHttpErrorInterceptor.$inject = [
    '$q',
    'notificationService',
    'tokenService'
  ];

  function flowinglyHttpErrorInterceptor(
    $q,
    notificationService,
    tokenService
  ) {
    const messageDuration = 5000;

    //API
    var service = {
      requestError: requestError,
      responseError: responseError
    };

    return service;

    //////////// Public API Methods

    function requestError(rejection) {
      if (rejection.status !== 401) {
        notificationService.showErrorToast(
          'oops! sorry, error has occured',
          messageDuration
        );
      }

      return $q.reject(rejection);
    }

    function responseError(rejection) {
      if (rejection.status === 401) {
        tokenService.setUnauthorisedAccess(true); // Cant use sessionService here as that needs to use $http and so
        // there will be a circular depdendency
      } else {
        parseErrorsAndDisplay(rejection);
      }

      return $q.reject(rejection);
    }

    function parseErrorsAndDisplay(rejection) {
      //find any errors in the response and display in toast

      const badRequest = 400;
      const serverError = 500;

      if (rejection.status === badRequest || rejection.status === serverError) {
        // HACK FIX - we _might_ receive this type of output "[ERR_222] err"
        // in which case
        const data = rejection.data;
        const message = data.message || rejection.data.Message;
        if (message) {
          var [all, errorCode, actualMsg] =
            /^(\[[a-zA-Z_0-9]+?\])? ?(.*)$/.exec(message);
          notificationService.showErrorToast(actualMsg, messageDuration);
          if (errorCode) {
            data.message = actualMsg;
            data.errorCode = errorCode.substr(1, errorCode.length - 2);
          }
        }
      } else {
        //handle everything else (most likely model validation errors on POST)

        var errors = [];
        var modelState =
          rejection.data != undefined ? rejection.data.modelState : {};
        for (var key in modelState) {
          for (var i = 0; i < modelState[key].length; i++) {
            errors.push(modelState[key][i]);
          }
        }

        if (rejection.data && rejection.data.error_description) {
          errors.push(rejection.data.error_description);
        }

        for (var key in errors) {
          notificationService.showErrorToast(errors[key], messageDuration);
        }
      }
    }
  }
})();
