/**
 * @ngdoc directive
 * @name flowSidemenuItem
 * @module flowingly.directives.sidemenu
 * @description A directive that represents an item in the side menu
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Sidemenu+Directive
 * 
   ####Attributes
   * item : The item to render
 * 
 * @usage
 * ```
      <flow-sidemenu-item ng-repeat="item in ctrl.menu" item="::item"></flow-sidemenu-item>
 * ```
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/d235410671e0af2382462948035fab5af45cf384/src/Flowingly.Shared.Angular/flowingly.directives/sidemenu/sidemenu.item.directive.js?at=master
 */

'use strict';
import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

angular.module('flowingly.directives.sidemenu').directive('flowSidemenuItem', [
  function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        item: '=',
        isMobile: '='
      },
      controller: [
        '$rootScope',
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'lodashService',
        'sideMenuService',
        function (
          $rootScope: angular.IRootScopeService,
          $scope: angular.IScope,
          $timeout: angular.ITimeoutService,
          $state: angular.ui.IStateService,
          $stateParams: angular.ui.IStateParamsService,
          lodashService: Lodash,
          sideMenuService: Services.SideMenuService
        ) {
          setHighlight(
            $state.current.name,
            $scope.item.target,
            $scope.item.data,
            $stateParams
          );

          $scope.mobileVer = $scope.isMobile ? '.mobile' : '';

          const stateListener = $rootScope.$on(
            '$stateChangeSuccess',
            function (event, toState, toParams) {
              setHighlight(
                $scope.item.target,
                toState.name,
                $scope.item.data,
                toParams
              );
            }
          );

          // Highlight or unhighlight menu item
          function setHighlight(name, toName, params, toParams) {
            if (!lodashService.isEmpty(toParams) && params != undefined) {
              $scope.item.active =
                name === toName &&
                JSON.stringify(params) === JSON.stringify(toParams);
            } else {
              if (name === 'app.runner.report') {
                name = 'app.runner.reports';
              }

              if (
                name &&
                (name.indexOf('app.runner.setup.') === 0 ||
                  name.indexOf('database') > -1)
              ) {
                name = 'app.runner.setup.users';
              }

              $scope.item.active = name === toName;
            }
          }

          // Called when user clicks on menu item (ng-click)
          $scope.navigate = function () {
            if (sideMenuService.menuClick() === 1) {
              // If only one click in the queue then do it
              letsGo();
              $timeout(function () {
                sideMenuService.menuTimeOut();
              }, 500);
            } else {
              // If more than one click in the queue then wait until 500ms after click to ensure
              // no more clicks (this stops a lot of unnecessary activity if user is click crazy
              $timeout(function () {
                if (sideMenuService.menuTimeOut() === 0) {
                  letsGo();
                }
              }, 500);
            }
          };

          // Lets go to the state
          function letsGo() {
            if ($scope.item.target == undefined) return;
            if ($scope.item.data) {
              $state.go($scope.item.target, $scope.item.data);
            } else {
              $state.go($scope.item.target);
            }
          }

          // Performs this action when clicked
          $scope.action = function () {
            $timeout(function () {
              $scope.item.action($scope.item.param);
            }, 0);
          };

          // Toggles open and close of dropdown (if dropdown menu)
          $scope.toggleOpenClose = function () {
            $scope.item.open = !$scope.item.open;
          };

          $scope.$on('$destroy', function () {
            //stop watching when scope is destroyed
            stateListener();
          });
        }
      ],
      template:
        `<li if-permitted="item.permissions" ng-class="{'label' : item.type=='label', active: item.active }" title="{{item.hovertext}}"> ` +
        `<div ng-include="'sidemenu-' + item.type + mobileVer +'.tmpl.html'"></div>` +
        `</li>`
    };
  }
]);
