'use strict';
import { Services } from '@Shared.Angular/@types/services';
import angular, { IWindowService } from 'angular';

angular
  .module('flowingly.services')
  .factory('authLoggingApiService', authLoggingApiService);

authLoggingApiService.$inject = ['APP_CONFIG', 'guidService', '$window'];

function authLoggingApiService(
  APP_CONFIG: Services.AppConfigCommon,
  guidService: Services.GuidService,
  $window: IWindowService
) {
  const service = {
    log: log
  };
  return service;

  function log(logText: string) {
    // [FLOW-6710] Dont send auth log if not enabled.
    if (
      $window.enableAuthenticationLogging &&
      $window.enableAuthenticationLogging.toString().toLowerCase().trim() ===
        'true'
    ) {
      const authenticationLog = {
        clientId: getClientId(),
        log: logText
      };

      const xhr = new XMLHttpRequest();
      xhr.open('POST', APP_CONFIG.apiBaseUrl + 'authenticationlog');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(authenticationLog));
    }

    return;
  }

  function getClientId() {
    const savedClientId = $window.localStorage.getItem(
      APP_CONFIG.keyForAuthLoggingId
    );
    if (!savedClientId) {
      const newClientId = guidService.new();
      $window.localStorage.setItem(APP_CONFIG.keyForAuthLoggingId, newClientId);
      return newClientId;
    }
    return savedClientId;
  }
}

export type AuthLoggingApiServiceType = ReturnType<
  typeof authLoggingApiService
>;
