import angular from 'angular';

declare const _: Lodash;

export class QueryableWorkflow {
  private _workflow: IWorkflowLike;
  private _schema: IWorkflowSchemaLike;

  public _rootQueryableNode: any = {};

  private _queryableNodesTable: QueryableWorfklowTable =
    new QueryableWorfklowTable({ primaryKey: 'key' });
  private _goNodeDataTable: QueryableWorfklowTable = new QueryableWorfklowTable(
    { primaryKey: 'key', indexes: ['id'] }
  );

  // similar to node table
  private _nodeTable: QueryableWorfklowTable = new QueryableWorfklowTable({
    primaryKey: 'Id',
    indexes: ['ModelerNodeId']
  });

  // one way relationship table "from"->"to"
  private _goLinksTable: QueryableWorfklowTable = new QueryableWorfklowTable({
    primaryKey: 'DummyPrimaryKey'
  });

  constructor(workflow: IWorkflowLike) {
    this._workflow = workflow;
    this._schema = JSON.parse(workflow.FlowSchema) as IWorkflowSchemaLike;

    // populate links table
    for (let i = this._schema.linkDataArray.length - 1; i >= 0; i--) {
      const link = this._schema.linkDataArray[i];
      const links = this._goLinksTable.getByPk(link.from) || [];
      links.push(link.to);
      links.DummyPrimaryKey = link.from; // just so it fits our pattern
      this._goLinksTable.insert(links);
    }

    // populate goNode table
    for (let i = this._schema.nodeDataArray.length - 1; i >= 0; i--) {
      const goNode = this._schema.nodeDataArray[i];
      this._goNodeDataTable.insert(goNode);
    }

    // populate node table
    for (let i = this._workflow.Nodes.length - 1; i >= 0; i--) {
      const node = this._workflow.Nodes[i];
      this._nodeTable.insert(node);
    }

    // begin graph construction
    const goStartNodes = this.goNodeData
      .toQueryable()
      .limit(1)
      .where((goNode) => goNode.item == 'start')
      .toList();

    if (typeof goStartNodes[0] !== 'undefined') {
      const goStartNode = goStartNodes[0];
      this._rootQueryableNode = this.makeQueryableNode(goStartNode.key, 0);
    }

    if (goStartNodes.length <= 0) this._rootQueryableNode = null;
  }

  /**
   * The graph building for the queryable nodes are a bit confusing to go through, so I'll try my best
   * to explain what is happening here.
   *
   *
   * @param key
   * @param depth
   * @param pid
   * @param parentPid
   */
  private makeQueryableNode(key: number, depth): QueryableWorkflowNode {
    const goNode = this._goNodeDataTable.getByPk(key);

    const isDivergeNode = goNode.category == 'divergeGateway';
    const isConvergeNode = goNode.category == 'convergeGateway';

    // determine the depth of the flow/subflow just for validation purposes
    if (isConvergeNode) {
      depth--;
    } else if (isDivergeNode) {
      depth++;
    }

    // insert the nodes into the table
    const queryableNode = new QueryableWorkflowNode(key, goNode);
    {
      queryableNode.depth = depth;
      this.queryableNodes.insert(queryableNode);
    }

    const childKeys = this._goLinksTable.getByPk(key);
    if (childKeys) {
      for (const childKey of childKeys) {
        //const goNode = this._goNodeDataTable[childKey];
        const queryableChildNode =
          this._queryableNodesTable.getByPk(childKey) ||
          this.makeQueryableNode(childKey, depth);
        queryableNode.addChild(queryableChildNode);
      }
    }

    return queryableNode;
  }

  get nodes() {
    return this._nodeTable;
  }

  get goNodeData() {
    return this._goNodeDataTable;
  }

  get goLinks() {
    return this._goLinksTable;
  }

  get queryableNodes() {
    return this._queryableNodesTable;
  }
}

class QueryableWorkflowNode {
  private _children: QueryableWorkflowNode[] = [];
  private _parents: QueryableWorkflowNode[] = [];
  private _key: number;
  private _goNodeData: any;

  public depth: number;

  constructor(key: number, goNode) {
    this._key = key;
    this._goNodeData = goNode;
  }

  addChild(queryableNode: QueryableWorkflowNode) {
    // we need to fix our ts configurations
    //                           - cassey
    if ((this._children as any).find((child) => queryableNode == child)) {
      return false;
    } else {
      this._children.push(queryableNode);
      queryableNode.addParent(this);
      return true;
    }
  }

  addParent(parentNode: QueryableWorkflowNode) {
    if (
      !_.find(
        this._parents,
        _.matchesProperty('_goNodeData.key', parentNode._goNodeData.key)
      )
    ) {
      this._parents.push(parentNode);
    }
  }

  get goNodeData() {
    return this._goNodeData;
  }

  get key() {
    return this._key;
  }

  get parents() {
    return [...this._parents];
  }

  get children() {
    return [...this._children];
  }
}

interface IQueryableWorfklowTableParams {
  primaryKey: string;
  indexes?: string[];
}

class QueryableWorfklowTable {
  private _pk: string | number;
  private _rowsPkIndex: object = {};
  private _indexes: object;

  private _rows: any[] = [];

  constructor(params: IQueryableWorfklowTableParams) {
    this._pk = params.primaryKey;

    // initialize indexes with dictionaries
    if (params.indexes) {
      this._indexes = {};
      for (const index of params.indexes) {
        this._indexes[index] = {};
      }
    }
  }

  /**
   * This is an O(1) fetch based on an index.
   *
   * usage:
   *      table = new QueryableWorfklowTable({ primaryKey:"id", indexes:["email"])
   *      table.insert({ id:1, email:"ryan@flowingly.net" );
   *      table.insert({ id:2, email:"eve@flowingly.net" );
   *      table.getByIndex("email", "eve@flowingly.net");
   *
   *
   * @param index
   * @param key
   */
  getByIndex(index: string, key: string | number) {
    return _.get(this._indexes, [index, key]);
  }

  /**
   * This is an O(1) fetch based on the primary key. You can achieve
   * the same thing by iterating through all the rows, but that is
   * inefficient.
   *
   * @param pk
   */
  getByPk(pk: string | number) {
    return this._rowsPkIndex[pk];
  }

  insert(item): void {
    const pkValue = item[this._pk];
    this._rows.push(item);
    this._rowsPkIndex[pkValue] = item;

    // update the index tables
    if (this._indexes) {
      for (const index in this._indexes as any) {
        const indexValue = item[index];
        this._indexes[index][indexValue] = item;
      }
    }
  }

  toQueryable() {
    return new QueryableWorfklowQuery(this._rows);
  }

  toList(): any[] {
    return this._rows;
  }
}

class QueryableWorfklowQuery {
  private _items: any[];
  private _limit: number = Number.MAX_VALUE;

  constructor(items) {
    this._items = items;
  }

  select(fn): QueryableWorfklowQuery {
    this._items = this._items.map(fn);
    return this;
  }

  where(fn): QueryableWorfklowQuery {
    const temp = [];
    for (let i = 0; i < this._items.length && temp.length < this._limit; i++) {
      const item = this._items[i];
      if (fn(item)) {
        temp.push(item);
      }
    }
    this._items = temp;
    return this;
  }

  limit(num): QueryableWorfklowQuery {
    this._limit = num;
    return this;
  }

  clone(): QueryableWorfklowQuery {
    return new QueryableWorfklowQuery(this.toList());
  }

  first() {
    return this._items[0];
  }

  last() {
    return this._items[this._items.length - 1];
  }

  toList() {
    return _.cloneDeep(this._items);
  }
}

export const QueryableWorkflowContainer = {
  QueryableWorkflow,
  QueryableWorkflowNode,
  QueryableWorfklowQuery
};

function bpmnQueryableWorkflow() {
  return {
    QueryableWorkflow,
    QueryableWorkflowNode,
    QueryableWorfklowQuery
  };
}
angular
  .module('flowingly.bpmn.modeler')
  .factory('BpmnQueryableWorkflow', [bpmnQueryableWorkflow]);

export type BpmnQueryableWorkflowType = ReturnType<
  typeof bpmnQueryableWorkflow
>;
