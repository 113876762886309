/**
 * @ngdoc service
 * @name flowsUtilityService
 * @module flowingly.runner.services
 *
 * @description Service that provides utliities for working with forms and form data
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/3157aea39be3b59e8b3e9df1fcef0d2c8d9c1e14/src/Flowingly.Shared.Angular/flowingly.components/flows.utility.service.js?at=master
 */

'use strict';

import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

angular
  .module('flowingly.components')
  .factory('flowsUtilityService', flowsUtilityService);

flowsUtilityService.$inject = [
  '$stateParams',
  '$location',
  'APP_CONFIG',
  'lodashService',
  'momentService'
];

function flowsUtilityService(
  $stateParams: angular.ui.IStateParamsService,
  $location: angular.ILocationService,
  APP_CONFIG: Services.AppConfigCommon,
  lodashService: Lodash,
  momentService: Moment
) {
  const service = {
    getStateParamValue: getStateParamValue,
    isMobileDevice: isMobileDevice,
    isTablet: isTablet,
    orderItemsAlphabetically: orderItemsAlphabetically,
    removeFlow: removeFlow,
    removeStep: removeStep,
    toCamel: toCamel,
    isInSupportHours: isInSupportHours
  };

  return service;

  //////////// Public API Methods

  function orderItemsAlphabetically(flows, property = 'name') {
    return flows.sort(function (a, b) {
      if (a[property].toLowerCase() < b[property].toLowerCase()) return -1;
      if (a[property].toLowerCase() > b[property].toLowerCase()) return 1;
      return 0;
    });
  }

  function toCamel(o) {
    let build, key, destKey, value;

    if (o instanceof Array) {
      build = [];
      for (key in o) {
        value = o[key];

        if (typeof value === 'object') {
          value = toCamel(value);
        }
        build.push(value);
      }
    } else {
      build = {};
      for (key in o) {
        if (Object.prototype.hasOwnProperty.call(o, key)) {
          destKey = (
            key.charAt(0).toLowerCase() + key.slice(1) || key
          ).toString();
          value = o[key];
          if (value !== null && typeof value === 'object') {
            value = toCamel(value);
          }

          build[destKey] = value;
        }
      }
    }
    return build;
  }

  function removeFlow(list, flowId) {
    const index = lodashService.findIndex(list, function (item) {
      return item.FlowId === flowId;
    });
    if (index > -1) {
      list.splice(index, 1);
    }
  }

  function removeStep(list, flowId, stepId) {
    const index = lodashService.findIndex(list, function (item) {
      const intMatch = item.FlowId === flowId;
      const stepFound = lodashService.some(item.Steps, function (step) {
        return step.Id === stepId;
      });
      return intMatch && stepFound;
    });
    if (index > -1) {
      list.splice(index, 1);
    }
  }

  function isMobileDevice() {
    let check = false;

    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || (window as any).opera);

    return check;
  }

  function isTablet() {
    let check = false;

    (function (a) {
      if (/ipad|playbook|silk/i.test(a)) check = true;
    })(navigator.userAgent);

    return check;
  }

  function getStateParamValue(key) {
    let value;

    if ($stateParams.data && $stateParams.data[key]) {
      value = $stateParams.data[key];
    } else if ($location.search()[key]) {
      value = $location.search()[key];
    } else {
      value = undefined;
    }

    return value;
  }

  function isInSupportHours() {
    if (
      APP_CONFIG.supportHours === '' ||
      APP_CONFIG.outOfHoursSupportMessage === ''
    ) {
      return true;
    }

    try {
      const [utcSupportHourFrom, utcSupportHourTo] =
        APP_CONFIG.supportHours.split('|');
      return momentService().isBetween(
        momentService(utcSupportHourFrom),
        momentService(utcSupportHourTo)
      );
    } catch (e) {
      console.error(e);
      return true;
    }
  }
}

export type FlowsUtilityServiceType = ReturnType<typeof flowsUtilityService>;
