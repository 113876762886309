import angular from 'angular';

class WorkflowApiService {
  static $inject = ['$http', 'APP_CONFIG'];
  constructor(private $http: angular.IHttpService, private APP_CONFIG: any) {}

  getById(flowModelId) {
    const base = this.APP_CONFIG.apiBaseUrl;
    return this.$http
      .get(`${base}workflow/${flowModelId}`)
      .then((result) => result.data);
  }
}

angular
  .module('flowingly.services')
  .service('workflowApiService', WorkflowApiService);
