/**
 * @ngdoc service
 * @name appInsightsService
 * @module flowingly.services
 *
 * @description A service responsible for app insights
 *
 * ###API
 * * startAppInsights - Initialise app insights so logging can start
 * * trackPageView - Log a page view event
 * * trackMetric - Log a metric
 * * startEventTimer - Start a timer for the named event
 * * getAndClearElapsedEventSeconds - Returns the seconds elapsed since the named event started, and clears the timer
 */
'use strict';
import angular from 'angular';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

angular
  .module('flowingly.services')
  .factory('appInsightsService', appInsightsService);

appInsightsService.$inject = [];

export type AppInsightsConfig = {
  instrumentationKey: string;
  [key: string]: string | number | boolean | string[] | null | undefined;
};

function appInsightsService() {
  enum Events {
    FLOW_STARTED = 'flowStartTriggered',
    FLOW_SELECTED_FROM_LIST = 'flowSelectedFromList',
    FLOW_LOAD_FROM_EXTERNAL_LINK = 'flowLoadByExternalLinkDateTime'
  }

  const _eventTimers = {};
  // Some event timers needs to be stored in localStorage than in a service. This array contains such events for
  // for processing accordingly.
  const _localStorageEventTimers = [Events.FLOW_LOAD_FROM_EXTERNAL_LINK];
  let _appInsights;

  const service = {
    Events: Events,
    startAppInsights: startAppInsights,
    trackPageView: trackPageView,
    trackMetric: trackMetric,
    startEventTimer: startEventTimer,
    getAndClearElapsedEventSeconds: getAndClearElapsedEventSeconds
  };

  return service;

  //////////// Private Methods
  function getAndClearElapsedEventSecondsFromLocalStorage(eventName) {
    try {
      const eventStart = (window as any).localStorage.getItem(eventName);
      if (eventStart) {
        (window as any).localStorage.removeItem(eventName);
        return (new Date().getTime() - parseInt(eventStart)) / 1000;
      }
      return undefined;
    } catch (err) {
      return undefined;
    } finally {
      // Remove the item from localStorage if we didn't get to it.
      (window as any).localStorage.removeItem(eventName);
    }
  }

  //////////// Public API Methods
  function startAppInsights(cfg: AppInsightsConfig) {
    /**
     * instrumentationKey field always exist
     * but rest of fields are dynamic depending on the setup of AppInsights in Azure
     */
    const { instrumentationKey, ...restOfConfig } = cfg;

    const config = {
      connectionString: 'InstrumentationKey=' + instrumentationKey,
      ...restOfConfig
    };

    _appInsights = new ApplicationInsights({
      config
    });

    _appInsights.loadAppInsights();
    // Manually call trackPageView to establish the current user/session/pageview
    _appInsights.trackPageView();
  }

  function trackPageView(pageName: string, properties: object) {
    if (!_appInsights) {
      return;
    }
    try {
      _appInsights.trackPageView({
        name: pageName,
        properties: properties
      });
    } catch {
      /* empty */
    }
  }

  function trackMetric(name: string, average: number, properties: object) {
    if (!_appInsights) {
      return;
    }
    try {
      _appInsights.trackMetric({
        name: name,
        average: average,
        properties: properties
      });
    } catch {
      /* empty */
    }
  }

  function startEventTimer(eventName) {
    if (_localStorageEventTimers.indexOf(eventName) > -1) {
      (window as any).localStorage.setItem(eventName, new Date().getTime());
    } else {
      _eventTimers[eventName] = new Date().getTime();
    }
  }

  function getAndClearElapsedEventSeconds(eventName) {
    if (_localStorageEventTimers.indexOf(eventName) > -1) {
      return getAndClearElapsedEventSecondsFromLocalStorage(eventName);
    }

    const eventStart = _eventTimers[eventName];
    if (eventStart) {
      _eventTimers[eventName] = undefined;
      return (new Date().getTime() - parseInt(eventStart)) / 1000;
    }
    return undefined;
  }
}

export type AppInsightsServiceType = ReturnType<typeof appInsightsService>;
