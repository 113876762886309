/**
 * @ngdoc service
 * @name dialogService
 * @module flowingly.services
 *
 * @description A service for displaying modal dialogs
 *
 * ## Notes
 * This service wraps ngDialog. The idea being to encapsulate the usage of ngDialog, so we can easily switch it out for another
 * dialog service in teh future if we decide to.
 * https://github.com/likeastore/ngDialog
 *
 * ###API
 * * isCloseModalWithCancelAction
 * * showConfirmDialog
 * * showMessageDialog
 * * showPublishDialog
 * * showUnPublishDialog
 * * showDialog
 *
 *
 * ###Usage
 *  dialogService.showMessageDialog({
 *    message: 'A message',
 *    headerText: 'Header text'
 *  });
 *
 * Converted to ts on 14/01/2020
 * SEe https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.services/dialog.service.js?at=master
 */

'use strict';
import angular, { dialog } from 'angular';

angular.module('flowingly.services').factory('dialogService', dialogService);

dialogService.$inject = ['$window', 'ngDialog'];

function dialogService(
  $window: angular.IWindowService,
  ngDialog: dialog.IDialogService
) {
  //API
  const disableAnimation = /MSIE \d|Trident.*rv:/.test(
    $window.navigator.userAgent
  );
  const service = {
    isCloseModalWithCancelAction: isCloseModalWithCancelAction,
    showConfirmDialog: showConfirmDialog,
    showMessageDialog: showMessageDialog,
    showPublishDialog: showPublishDialog,
    showUnPublishDialog: showUnPublishDialog,
    showDialog: showDialog
  };

  return service;

  //////////// Public API Methods

  function isCloseModalWithCancelAction(data) {
    //user closed modal by clicking on overlay (or cancel or press Esc key)
    return data === '$document' || data === '$escape' || data === undefined;
  }

  function showConfirmDialog($scope, customOptions) {
    const initialOptions = {
      title: 'Please Confirm',
      message: 'Please confirm you wish to continue with this action',
      className: 'ngdialog-theme-plain',
      template: 'confirmDialog.tmpl.html'
    };

    //overwrite default options with custom options
    angular.extend(initialOptions, customOptions);

    return ngDialog.openConfirm({
      disableAnimation: disableAnimation,
      showClose: false,
      className: initialOptions.className,
      template: initialOptions.template,
      scope: $scope,
      controller: [
        function () {
          const ctrl = this;

          ctrl.title = initialOptions.title;
          ctrl.message = initialOptions.message;
        }
      ],
      controllerAs: 'ctrl'
    });
  }

  function showDialog(customDialogOptions) {
    const defaultDialogOptions = {
      disableAnimation: disableAnimation,
      className: 'ngdialog-theme-plain',
      appendClassName: '',
      showClose: false,
      controllerAs: 'ctrl'
    };

    angular.extend(defaultDialogOptions, customDialogOptions);

    const dialog = ngDialog.open(defaultDialogOptions);

    return dialog.closePromise.then(function (data) {
      return data.value;
    });
  }

  function showMessageDialog(customOptions) {
    const initialOptions = {
      disableAnimation: disableAnimation,
      headerText: 'Confirm dialog',
      message: undefined,
      big: undefined
    };

    //overwrite default options with custom options
    angular.extend(initialOptions, customOptions);

    const className =
      initialOptions.big != undefined
        ? 'ngdialog-theme-plain big-width'
        : 'ngdialog-theme-plain';

    ngDialog.open({
      className: className,
      showClose: false,
      template: 'services.message.dialog.tmpl.html',
      controller: [
        'ngDialog',
        function (ngDialog: dialog.IDialogService) {
          const ctrl = this;

          ctrl.model = initialOptions;
          ctrl.close = close;

          function close() {
            ngDialog.closeAll();
          }
        }
      ],
      controllerAs: 'ctrl'
    });
  }

  function showPublishDialog(
    scope,
    selectedFlow,
    controllerName,
    templatePath,
    isEdit
  ) {
    /* this dialog can be shown in two modes - validated / published
     *
     *  create - used to publish flows for the first time
     *  edit - used to modify published flows
     */
    scope.isEdit = isEdit;

    const path = templatePath || 'publishFlowDialog.tmpl.html';
    const publishDialog = ngDialog.open({
      disableAnimation: disableAnimation,
      className: 'ngdialog-theme-plain',
      appendClassName: 'ngdialog-publish ngdialog-normal pad-50',
      showClose: false,
      template: path,
      controller: controllerName, //"publishDialogController",
      controllerAs: 'ctrl',
      scope: scope,
      data: selectedFlow
    });

    return publishDialog.closePromise.then(function (data) {
      return data.value;
    });
  }

  function showUnPublishDialog(scope, flowCount) {
    const unPublishDialog = ngDialog.open({
      disableAnimation: disableAnimation,
      className: 'ngdialog-theme-plain ngdialog-normal pad-top-50',
      showClose: false,
      template: 'unPublishFlowDialog.tmpl.html',
      controller: 'UnPublishWorkflowController',
      controllerAs: 'ctrl',
      scope: scope,
      data: flowCount
    });

    return unPublishDialog.closePromise.then(function (data) {
      return data.value;
    });
  }
}

export type DialogServiceType = ReturnType<typeof dialogService>;
