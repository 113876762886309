/**
 * Converted to ts on 14/01/2020
 *  https://bitbucket.org/flowingly-team/flowingly-source-code/src/fd20388d1ec8061a4d9a9326c458b8f4ebd7666a/src/Flowingly.Shared.Angular/flowingly.services/customdb.service.js?at=master
 */

'use strict';
import angular from 'angular';
import { Services } from '../@types/services';

angular
  .module('flowingly.services')
  .factory('customdbService', customdbService);

customdbService.$inject = ['APP_CONFIG', '$http', 'flowinglyConstants'];

function customdbService(
  APP_CONFIG: Services.AppConfigCommon,
  $http: angular.IHttpService,
  flowinglyConstants: Services.FlowinglyConstants
) {
  const databasesApiBaseUrl = APP_CONFIG.apiBaseUrl + 'customdatabase';
  //API
  const service = {
    populateValueOptions: populateValueOptions,
    getColumnsUnfiltered,
    getColumns,
    getAllDatabases
  };

  return service;

  //////////// Public API Methods
  function getAllDatabases() {
    console.info(
      'customdbService.getAllDatabases can be potentially slow. We might need pagination in the future.'
    );
    return $http
      .get(databasesApiBaseUrl + '?fromModeler=true', { noSpinner: true })
      .then((response) => response.data);
  }

  function getColumns(...args) {
    return (getColumnsUnfiltered as any)(...args).then((columns) => {
      const idColumnIndex = columns.findIndex((d) => d.name === 'Id');
      const customIdColumnIndex = columns.findIndex(
        (d) => d.name === 'customdatabaseid'
      );

      if (idColumnIndex >= 0 && customIdColumnIndex < 0) {
        columns.splice(idColumnIndex, 1);
      } else if (customIdColumnIndex >= 0) {
        columns.splice(customIdColumnIndex, 1);
      }
      return columns;
    });
  }

  function getColumnsUnfiltered(dbName, _config_ = {}) {
    const config = {
      noSpinner: true,
      ..._config_
    };
    return $http
      .get(`${databasesApiBaseUrl}/columns/${dbName}`, config)
      .then((response) => response.data);
  }

  function populateValueOptions(data, field, dataType, insertOptions) {
    let valueOptions = [];
    const isTable = data.cells !== undefined;

    // FLOW-2617
    if (insertOptions && insertOptions.addInitiatorOption) {
      valueOptions.push({
        name: 'Initiator',
        text: 'Initiator',
        type: 'text'
      });
    }
    // FLOW-6927 Add current actor option.
    if (insertOptions && insertOptions.addCurrentActorOptions) {
      valueOptions.push({
        name: flowinglyConstants.lookupPreviousFieldTypeAndText
          .CurrentActorEmail,
        text: flowinglyConstants.lookupPreviousFieldTypeAndText
          .CurrentActorEmailText,
        type: 'text'
      });
      valueOptions.push({
        name: flowinglyConstants.lookupPreviousFieldTypeAndText
          .CurrentActorName,
        text: flowinglyConstants.lookupPreviousFieldTypeAndText
          .CurrentActorNameText,
        type: 'text'
      });
    }

    if (data.allFields || data.cells) {
      if (isTable) {
        for (const c of data.cells) {
          if (c.id === field.id) {
            break;
          } else if (
            c.type === 1 || //text
            c.type === 2 || //currency
            c.type === 3 || //number
            c.type === 6
          ) {
            //dropdown
            valueOptions.push({
              name: c.id + '', //kendo don't like int by some reason
              text: c.header,
              type: c.type
            });
          }
        }
      } else {
        for (const f of data.allFields) {
          if (f.name === field.name) {
            break;
          } else if (
            f.type === 'text' ||
            f.type === 'number' ||
            f.type === 'selectlist' ||
            f.type === 'radiobuttonlist' ||
            f.type === 'currency' ||
            f.type === 'email'
          ) {
            valueOptions.push({
              name: f.name,
              text: f.displayName,
              type: f.type
            });
          } else if (
            f.type === 'lookup' &&
            f.lookupConfig &&
            f.lookupConfig.value !==
              flowinglyConstants.lookupPreviousFieldTypeAndText
                .CurrentActorEmail &&
            f.lookupConfig.value !==
              flowinglyConstants.lookupPreviousFieldTypeAndText.CurrentActorName
          ) {
            // FLOW-6927 - If lookup field contains CurrentActor previous field options we dont add current actor lookup options.
            valueOptions.push({
              name: f.name,
              text: f.displayName,
              type: f.type
            });
          }
        }
      }

      // ensure field type matches data type, eg. validate and prevent short text field with number data column
      if (
        dataType &&
        dataType !== 'text' &&
        insertOptions &&
        !insertOptions.addInitiatorOption
      ) {
        valueOptions = valueOptions.filter((o) => o.type === dataType);
      }
    }

    return valueOptions;
  }
}

export type CustomdbServiceType = ReturnType<typeof customdbService>;
