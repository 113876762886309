'use strict';

import BPMN_CONSTANTS from '../flowingly.bpmn.modeler/flowingly.bpmn.constants';
import angular from 'angular';
import { QueryableWorkflow } from './queryable.workflow';
import {
  FormFieldType,
  LookupPreviousFieldTypeAndText
} from '../flowingly.services/flowingly.constants';
import { Services } from '../@types/services';
import { ModelerValidation } from './@types/services';
import { BpmnModeler } from '@Shared.Angular/flowingly.bpmn.modeler/@types/services';

/**
 * @ngdoc service
 * @name modelerValidationService
 * @module flowingly.modeler.validation
 *
 * @description A service responsible solely for validating flowmodel
 *
 *
 */

interface IValidationContext {
  diagram: any;
  flow: any;
  model: any;
  allCustomDbs: any;
  allModelNodes: any;

  /*  equivalent to
   *     context.queryable.goNodeData.toList()
   *     mainDiagram.model.nodeDataArray
   */
  allLinks: any;

  activityNodes: any;
  componentNodes: any;
  /*equivalent to
   *      getNodes() [DEPRECATED]
   *      context.queryable.nodes.toList()
   */
  workflowNodes: any;
  publishedProcessMapComponents: any;
  publishedWorkflowComponents: any;
  queryable: QueryableWorkflow;
}

angular
  .module('flowingly.modeler.validation')
  .factory('modelerValidationService', modelerValidationService);

modelerValidationService.$inject = [
  '$http',
  'lodashService',
  'flowinglyGatewayService',
  'flowinglyActivityService',
  'flowinglyModelUtilityService',
  'rulesetParserService',
  'rulesetValidationService',
  'rulesetService',
  'validationService',
  'guidService',
  'intercomService',
  '$q',
  'componentApiService',
  'flowinglyConstants',
  'jQuery',
  'BpmnQueryableWorkflow',
  'modelerValidationErrorsService',
  'BpmnDiagramService'
];

function modelerValidationService(
  $http: angular.IHttpService,
  lodash: Lodash,
  flowinglyGatewayService: Services.FlowinglyGatewayService,
  flowinglyActivityService: Services.FlowinglyActivityService,
  flowinglyModelUtilityService: Services.FlowinglyModelUtilityService,
  rulesetParserService: ModelerValidation.RulesetParserService,
  rulesetValidationService: ModelerValidation.RuleSetValidationService,
  rulesetService: ModelerValidation.RuleSetService,
  validationService: Services.ValidationService,
  guidService: Services.GuidService,
  intercomService: Services.IntercomService,
  $q: angular.IQService,
  componentApiService: Services.ComponentApiService,
  flowinglyConstants: Services.FlowinglyConstants,
  $: JQueryStatic,
  BpmnQueryableWorkflow: ModelerValidation.BpmnQueryableWorkflow,
  modelerValidationErrorsService: ModelerValidation.ModelerValidationErrorsService,
  BpmnDiagramService: BpmnModeler.BpmnDiagramService
) {
  let errors = []; // tsk tsk bad man. bad bad man. - cassey
  let workflow;
  let appConfig;
  let allModelNodes;
  let allLinks;
  let isComponent = false;
  const service = {
    validateWorkFlow: validateWorkFlow,
    validateWorkflowComponent: validateWorkflowComponent,
    validateProcessMap: validateProcessMap,
    validateWorkflowPassXssCheck: validateWorkflowPassXssCheck
  };

  return service;

  function validateProcessMap(flow, config) {
    modelerValidationErrorsService.resetAllErrors();

    workflow = flow;
    errors = [];
    appConfig = config;
    const model = JSON.parse(flow.FlowSchema);
    allModelNodes = getAllModelNodes(model);

    validatePublicFormExistsInProcessMap(allModelNodes);

    const diagram = BpmnDiagramService.getDiagram('main');

    console.log('Start validateProcessMap updateAllTargetBindings');

    // We update the graph to remove and add the errors
    diagram.startTransaction('ValidateProcessMap_Update');
    diagram.updateAllTargetBindings();
    diagram.commitTransaction('ValidateProcessMap_Update');

    console.log('Commit validateProcessMap updateAllTargetBindings');

    return $q.when(errors);
  }

  function validateWorkFlow(flow, config) {
    modelerValidationErrorsService.resetAllErrors();
    const getCustomDBPromise = $http.get(
      `${config.apiBaseUrl}customdatabase?fromModeler=true`,
      { noSpinner: true } as any
    );

    return $q
      .all([
        getCustomDBPromise,
        componentApiService.getProcessMapComponents(),
        componentApiService.getWorkflowComponents()
      ])
      .then(function (result) {
        const allCustomDbs = result[0].data;
        const publishedProcessMapComponents = result[1];
        const publishedWorkflowComponents = result[2];

        workflow = flow;
        errors = [];
        appConfig = config;
        const model = JSON.parse(flow.FlowSchema);
        allModelNodes = getAllModelNodes(model);
        allLinks = getAllModelLinks(model);
        const activityNodes =
          flowinglyModelUtilityService.getActivityNodes(allModelNodes);
        const componentNodes =
          flowinglyModelUtilityService.getComponentNodes(allModelNodes);

        const context = {
          // shitty solution but cleaner than what we had before
          diagram: BpmnDiagramService.getDiagram('main'), // this is the actual go js diagram

          flow,
          model,
          allCustomDbs,
          allModelNodes, // equivalent to
          //      context.queryable.goNodeData.toList()
          //      mainDiagram.model.nodeDataArray
          allLinks,

          activityNodes,
          componentNodes,
          workflowNodes: flow.Nodes, // equivalent to
          //      getNodes() [DEPRECATED]
          //      context.queryable.nodes.toList()
          publishedProcessMapComponents,
          publishedWorkflowComponents,

          queryable: new BpmnQueryableWorkflow.QueryableWorkflow(flow)
        } as IValidationContext;

        validateModelNodes(context);
        validateEachComponentNode(context);
        validateCustomValidation(context);
        validatePublicForm(context);
        validateContentInWorkflowNodes(context);
        validateBacklinks(allModelNodes, allLinks);

        // @TODO whoever works on this code file just know that the goal of this
        // block of code is for us to encapsulate the errors and remove the global-like
        // accessing of the errors. This is so we con convert it to separate classes,
        // and eventually make it unit testable.
        //                                               - Cassey
        validateNodeRelationships(context, errors);
        validateStepRules(flow.Nodes, allModelNodes);

        // TODO: FLOW-6978 here we validate conditional forms.
        validateFieldConditions(context);

        if (context.queryable._rootQueryableNode != null) {
          for (let i = 0; i < context.allModelNodes.length; i++) {
            // validateNodeFields should be moved to this pattern slowly.
            const modelNode = context.allModelNodes[i]; // a.k.a goNodeData
            const workflowNode = context.queryable.nodes.getByIndex(
              'ModelerNodeId',
              modelNode.id
            ); // a.k.a workflowNode, node

            const nodeLinks = getLinks(model, modelNode);
            const newContext = { ...context, modelNode, nodeLinks };

            // validate links or connectors
            validateThatStartNodeOnlyHasFromLinks(nodeLinks);
            validateThatEndNodeOnlyHasToLinks(nodeLinks);
            validateThatNodeDoesNotHaveMultipleFromLinks(nodeLinks, modelNode);
            validateNodeHasToConnector(nodeLinks, modelNode);
            validateNodeHasFromConnector(nodeLinks, modelNode);

            // node specific validations
            switch (modelNode.category) {
              case BPMN_CONSTANTS.GatewayType.EXCLUSIVE_GATEWAY:
                validateExclusiveGateWay(
                  model.nodeDataArray,
                  modelNode,
                  nodeLinks
                );
                validateDecisionField(
                  model.nodeDataArray,
                  model.linkDataArray,
                  modelNode
                );
                break;

              case BPMN_CONSTANTS.GatewayType.DIVERGE:
                validateDivergeGateWay(
                  allModelNodes,
                  allLinks,
                  modelNode,
                  nodeLinks
                );
                break;

              case BPMN_CONSTANTS.GatewayType.CONVERGE:
                validateConvergeGateWay(
                  allModelNodes,
                  allLinks,
                  modelNode,
                  nodeLinks
                );
                break;

              case flowinglyConstants.nodeCategory.ACTIVITY:
                validateDynamicActor(modelNode, allModelNodes, allLinks);
                validateHasValidActor(modelNode);

                validateNodeFields(modelNode, allCustomDbs); // @TODO merge these 2
                validateNodeFieldsV2(newContext, errors); // @TODO merge these 2

                // @TODO move loops of formfields here
                for (const formElement of workflowNode.Card.formElements) {
                  validatePreviousField(
                    modelNode,
                    workflowNode,
                    context.workflowNodes,
                    formElement
                  );

                  switch (formElement.type) {
                    case 'lookup':
                      validateLookupField(
                        modelNode,
                        formElement.lookupConfig,
                        'Lookup',
                        formElement.displayName,
                        allCustomDbs,
                        errors
                      );
                      break;
                    case 'instruction':
                      validateInstructionField(
                        formElement,
                        context,
                        workflowNode,
                        modelNode,
                        errors
                      );
                      break;
                    default:
                      break;
                  }
                }

                break;
              case 'event':
                break;
              default:
                console.error(
                  'Found unknown category type supplied to node',
                  modelNode
                );
                break;
            }

            // sequential approval and parallel approval
            if (
              flowinglyModelUtilityService.isMultipleApprovalNode(modelNode)
            ) {
              //@TODO is this also an activity?
              validateMultipleApproval(modelNode);
            }
          }
        }

        // We update the graph to remove and add the errors
        const diagram = BpmnDiagramService.getDiagram('main');

        console.log('Start validateWorkFlow updateAllTargetBindings');

        // We update the graph to remove and add the errors
        diagram.startTransaction('ValidateWorkFlow_Update');
        diagram.updateAllTargetBindings();
        diagram.commitTransaction('ValidateWorkFlow_Update');

        console.log('Commit validateWorkFlow updateAllTargetBindings');

        if (errors.length > 0) {
          return $q.when(errors);
        } else {
          return validateWorkflowSchema();
        }
      });
  }

  function validateNodeFieldsV2(context, errors) {
    const { modelNode, queryable } = context;

    if (modelNode.category === 'activity') {
      const node = queryable.nodes.getByIndex('ModelerNodeId', modelNode.id);
      const card = node.Card;
      const formElements = card.formElements;

      for (let i = 0; i < formElements.length; i++) {
        const formElement = formElements[i];

        const goNodeData = queryable.goNodeData
          .toQueryable()
          .limit(1)
          .where((goNode) => goNode.id == modelNode.id)
          .first();

        if (formElement.defaultPreviousStepId) {
          let isValid;
          try {
            isValid = validateIfPreviousFieldIsAccessible({
              ...context,
              goNodeData,
              formElement
            });
          } catch (err) {
            isValid = false;
            console.error(err);
          }
          if (!isValid) {
            const error = {
              message: `${goNodeData.text} has the field "${formElement.displayName}" referencing a step it has no access to.`
            };
            modelerValidationErrorsService.addError(goNodeData.key, error);
            errors.push(error);
          }
        }
      }
    }
  }

  /**
   * This is an expensive process of iterating through the nodes, but atleast we are using
   * the gojs built in node finder isntead of our own version of the node finder.
   */
  function validateIfPreviousFieldIsAccessible({
    goNodeData,
    formElement,
    diagram
  }): boolean {
    const startingNode = diagram.findNodeForKey(goNodeData.key);
    const foundGoNode = flowinglyModelUtilityService.findPreviousUntil(
      startingNode,
      (goJsNode) => {
        return goJsNode.data.id == formElement.defaultPreviousStepId;
      },
      BpmnDiagramService.getDiagram('main')
    );

    return !!foundGoNode;
  }

  function validateNodeRelationships({ queryable }, errors) {
    queryable.queryableNodes
      .toQueryable()
      .where(
        (n) =>
          n.goNodeData.category == 'activity' &&
          n.parents.filter((p) => n.depth != p.depth).length
      )
      .toList()
      .forEach(({ goNodeData }) => {
        const error = {
          message: `${goNodeData.text} has a link coming from a different path following a diverge`
        };
        modelerValidationErrorsService.addError(goNodeData.key, error);
        errors.push(error);
      });
  }

  function validateWorkflowComponent(flow, config) {
    modelerValidationErrorsService.resetAllErrors();

    workflow = flow;
    errors = [];
    appConfig = config;
    isComponent = true;
    validateAdditionalRulesForWorkflowComponent(flow);

    if (errors.length > 0) {
      const diagram = BpmnDiagramService.getDiagram('main');

      console.log('Start validateWorkflowComponent updateAllTargetBindings');

      // // We update the graph to remove and add the errors
      diagram.startTransaction('ValidateWorkflowComponent_Update');
      diagram.updateAllTargetBindings();
      diagram.commitTransaction('ValidateWorkflowComponent_Update');

      console.log('Commit validateWorkflowComponent updateAllTargetBindings');

      return $q.when(errors);
    }

    return validateWorkFlow(flow, config);
  }

  function validateSchema(schema) {
    ///
    /// validate supplied schema
    ///
    return $http.post(appConfig.apiBaseUrl + 'modeler/validateschema', {
      Schema: schema
    });
  }

  function validateWorkflowSchema() {
    return validateSchema(workflow.FlowSchema).then((response) => {
      // transform server error to client side error
      for (const error of (response.data as any).dataModel) {
        errors.push({ message: error.errorMessage, isServerError: true });
      }
      if (errors.length > 0) {
        intercomService.trackEvent('Flow Model Failed Server Side Validation', {
          'flow name': workflow.Name
        });
      }
      return errors;
    });
  }

  function validateModelNodes({
    allModelNodes,
    activityNodes,
    componentNodes
  }) {
    validateAtleastOneObject(allModelNodes);
    validateStartObject(allModelNodes);
    validateEndObject(allModelNodes);
    validateUniqueNodeId(allModelNodes);
    activityAndComponentNodesHaveOkNames(activityNodes.concat(componentNodes));
  }

  function validateEachComponentNode({
    componentNodes,
    allModelNodes,
    allLinks,
    publishedProcessMapComponents,
    publishedWorkflowComponents
  }) {
    lodash.forEach(componentNodes, function (componentNode) {
      validateComponentNode(
        componentNode,
        allModelNodes,
        allLinks,
        publishedProcessMapComponents,
        publishedWorkflowComponents
      );
    });
  }

  function validatePublicForm({ allModelNodes, allLinks }) {
    const publicFormNodes = getPublicFormNodes();
    if (publicFormNodes == null || publicFormNodes.length === 0) {
      return;
    }
    // Validate all instruction fields in all the public form.
    for (const publicFormNode of publicFormNodes) {
      for (const formElement of publicFormNode.Card.formElements) {
        if (
          formElement.type === 'instruction' &&
          containsStepOrFlowAttributeVariables(formElement)
        ) {
          const error = {
            message: `'${publicFormNode.StepName}' contains variables in an instruction field, variables within instruction fields are not supported on Public Forms, please remove these in order to publish.`
          };
          const modelNodePublicForm = allModelNodes.find(
            (modelNode) => modelNode.id === publicFormNode.ModelerNodeId
          );
          modelerValidationErrorsService.addError(
            modelNodePublicForm.key,
            error
          );
          errors.push(error);
          break;
        }
      }
    }

    validatePublicFormSubject(publicFormNodes);

    const publicFormNodesViaActor = getPublicFormNodesViaActorName();
    if (publicFormNodesViaActor.length > 1 || !publicFormNodes[0].IsFirstNode) {
      const error = {
        message: `A Public Form can only be used once in the first step of a flow.`
      };

      // special case for redbox validations : color all public forms red
      publicFormNodesViaActor.forEach((goNodeData) => {
        if (publicFormNodes[0].ModelerNodeId != goNodeData.data) {
          modelerValidationErrorsService.addError(goNodeData.key, error);
        }
      });

      errors.push(error);
      return;
    }

    const publicFormModelNode = allModelNodes.find(
      (modelNode) => modelNode.id === publicFormNodes[0].ModelerNodeId
    );

    const activityNodesAfterPublicForm =
      flowinglyModelUtilityService.getNextActivityNodesForNodeRecursive(
        publicFormModelNode.key,
        allModelNodes,
        allLinks
      );

    validateAssignedActorAndApproverForNodesAfterPublicForm(
      publicFormModelNode,
      activityNodesAfterPublicForm
    );
  }

  function validatePublicFormSubject(publicFormNodes) {
    if (appConfig.enableCustomPublicFormSubjects) {
      for (const publicFormNode of publicFormNodes) {
        const modelerNode = allModelNodes.find(
          (modelNode) => modelNode.id === publicFormNode.ModelerNodeId
        );
        if (
          modelerNode.flowSubject == null ||
          modelerNode.flowSubject.replaceAll(' ', '') === ''
        ) {
          const error = {
            message: `'${publicFormNode.StepName}' Workflow Subject field cannot be blank.`
          };
          modelerValidationErrorsService.addError(modelerNode.key, error);
          errors.push(error);
          break;
        }
        const invalidVariables = getInvalidTemplateVariablesForNode(
          modelerNode.flowSubject,
          publicFormNode
        );
        if (invalidVariables) {
          invalidVariables.forEach((variable) => {
            const error = {
              message: `'${publicFormNode.StepName}' contains invalid step field variable '${variable}' in its Workflow Subject.`
            };
            modelerValidationErrorsService.addError(modelerNode.key, error);
            errors.push(error);
          });
        }
      }
    }
  }

  function containsStepOrFlowAttributeVariables(formElement) {
    const instructionFieldValue = formElement.value;
    if (instructionFieldValue != null) {
      const template = htmlDecode(instructionFieldValue);
      const stepVariableRegex = /{step./g;
      const flowVariableRegex = /{flow./g;

      return (
        stepVariableRegex.test(template) || flowVariableRegex.test(template)
      );
    }
    return false;
  }

  function validatePublicFormExistsInProcessMap(allModelNodes) {
    const publicFormNodes = getPublicFormNodes();
    if (publicFormNodes && publicFormNodes.length > 0) {
      const error = {
        message: `A Public Form can not be used in a process map.`
      };

      // special case for redbox validations : color all public forms red
      getPublicFormNodesViaActorName().forEach((goNodeData) =>
        modelerValidationErrorsService.addError(goNodeData.key, error)
      );

      errors.push(error);
    }

    return;
  }

  function getPublicFormNodesViaActorName() {
    return BpmnDiagramService.getDiagram('main').model.nodeDataArray.filter(
      (goNodeData) => goNodeData.actorName == 'Public User'
    );
  }

  function getPublicFormNodes() {
    const publicFormNode = getNodes().filter((n) => {
      return (
        n.IsPublicForm && (n.isDeleted === undefined || n.isDeleted === false)
      );
    });

    return publicFormNode;
  }

  function validateContentInWorkflowNodes({
    workflowNodes,
    allModelNodes,
    allLinks
  }) {
    validateSelectApproversNode(workflowNodes, allModelNodes, allLinks);
    validateCustomEmailNode(workflowNodes, allModelNodes, allLinks);
  }

  function validateComponentNode(
    modelNode,
    allModelNodes,
    allLinks,
    publishedProcessMapComponents,
    publishedWorkflowComponents
  ) {
    if (!modelNode.componentSchemaId) {
      const error = {
        message: `A component needs to be selected for step '${modelNode.text}'.`
      };
      modelerValidationErrorsService.addError(modelNode.key, error);
      errors.push(error);
    } else if (
      publishedProcessMapComponents.find(
        (c) => c.ComponentSchemaId === modelNode.componentSchemaId
      )
    ) {
      const error = {
        message: `The Process Map component '${modelNode.text}' cannot be used in a Workflow.`
      };
      modelerValidationErrorsService.addError(modelNode.key, error);
      errors.push(error);
    } else if (
      !publishedWorkflowComponents.find(
        (c) => c.ComponentSchemaId === modelNode.componentSchemaId
      )
    ) {
      const error = {
        message: `The component used for step '${modelNode.text}' is invalid.`
      };
      modelerValidationErrorsService.addError(modelNode.key, error);
      errors.push(error);
    }

    validateDynamicActorNodeAfterComponent(modelNode, allModelNodes, allLinks);
  }

  function validateDynamicActorNodeAfterComponent(
    modelNode,
    allModelNodes,
    allLinks
  ) {
    const nextNodes =
      flowinglyModelUtilityService.getNextActivityNodesForNodeRecursive(
        modelNode.key,
        allModelNodes,
        allLinks
      );
    if (!nextNodes || nextNodes.length === 0) return;

    const node = nextNodes.find(
      (n) =>
        n.actorType === appConfig.modeler_ActorType.DYNAMIC &&
        n.actor === appConfig.modeler_DynamicActor.SELECT_DYNAMIC_ACTORS
    );
    if (node) {
      const error = {
        message: `'${node.text}' cannot be assigned to dynamic actor when the preceding step is a component.`
      };
      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }
  }

  function validateAdditionalRulesForWorkflowComponent(flow) {
    const model = JSON.parse(flow.FlowSchema);
    allModelNodes = getAllModelNodes(model);
    componentDoesNotContainOtherComponents(allModelNodes);
    componentDoesNotContainPublicForm();
  }

  function componentDoesNotContainOtherComponents(nodes) {
    lodash.forEach(nodes, function (node) {
      if (node.category === flowinglyConstants.nodeCategory.COMPONENT) {
        const error = {
          message: `Component step '${node.text}' cannot be used inside a component.`
        };
        modelerValidationErrorsService.addError(node.key, error);
        errors.push(error);
      }
    });
  }

  function componentDoesNotContainPublicForm() {
    const publicFormNodes = getPublicFormNodes();
    if (publicFormNodes && publicFormNodes.length > 0) {
      const error = {
        message: 'Public form cannot be used inside a component.'
      };

      // special case for redbox validations : color all public forms red
      publicFormNodes.forEach((node) => {
        const goNodeData = BpmnDiagramService.getDiagram(
          'main'
        ).model.nodeDataArray.find((n) => n.id == node.ModelerNodeId);
        modelerValidationErrorsService.addError(goNodeData.key, error);
      });

      errors.push(error);
    }
  }

  function activityAndComponentNodesHaveOkNames(nodes) {
    const names = [];
    lodash.forEach(nodes, function (node) {
      if (node.text === undefined || node.text.trim() === '') {
        if (node.refSequence) {
          const error = {
            message: 'Step ' + node.refSequence + ' requires a step name.'
          };
          modelerValidationErrorsService.addError(node.key, error);
          errors.push(error);
        } else {
          const error = {
            message: 'A step requires a step name.'
          };
          modelerValidationErrorsService.addError(node.key, error);
          errors.push(error);
        }
      } else if (
        lodash.indexOf(names, node.text.toLowerCase()) === -1 &&
        node.text !== 'Start' &&
        node.text !== 'End'
      ) {
        names.push(node.text.toLowerCase());
      } else {
        if (node.text === 'Start' || node.text === 'End') {
          const error = {
            message: "Steps cannot be named 'Start' or 'End'"
          };
          modelerValidationErrorsService.addError(node.key, error);
          errors.push(error);
        } else {
          const error = {
            message: `No STEPS can have the same name: ${node.text.toLowerCase()}`
          };
          modelerValidationErrorsService.addError(node.key, error);
          errors.push(error);
        }
      }
    });
  }

  function validateHasValidActor(node) {
    if (!node.actor || node.actor === '') {
      errors.push({
        message: `No ACTOR assigned for step ${node.text}`
      });
    }
  }

  function validateThatEndNodeOnlyHasToLinks(links) {
    if (links.endConnectors.length > 1) {
      errors.push({ message: 'Connectors can only flow to an END node.' });
    }
  }

  function validateDecisionField(nodes, links, gatewayNode) {
    let decisionFieldStep = nodes.find(
      (n) => n.key === gatewayNode.selectedNodeKey
    );
    if (decisionFieldStep === undefined) {
      let incomingLinks = flowinglyModelUtilityService.getLinksToNode(
        gatewayNode.key,
        links
      );
      let incomingNodes = flowinglyModelUtilityService.findNodesAtStartOfLinks(
        incomingLinks,
        nodes
      );
      while (
        incomingNodes &&
        incomingNodes.length > 0 &&
        incomingNodes[0].category !== 'activity'
      ) {
        incomingLinks = flowinglyModelUtilityService.getLinksToNode(
          incomingNodes[0].key,
          links
        );
        incomingNodes = flowinglyModelUtilityService.findNodesAtStartOfLinks(
          incomingLinks,
          nodes
        );
      }
      if (incomingNodes && incomingNodes.length > 0) {
        decisionFieldStep = nodes.find((n) => n.key === incomingNodes[0].key);
      }
    }

    if (decisionFieldStep === undefined) {
      const error = {
        message: `Cannot find the decision step for '${gatewayNode.text}'`
      };
      modelerValidationErrorsService.addError(gatewayNode.key, error);
      errors.push(error);
      return;
    }

    const fields = findFields([decisionFieldStep]);

    const decisionField = lodash.find(fields, function (f) {
      if (!gatewayNode.gateway.fieldId) {
        return;
      }
      const gatewayFieldIds = gatewayNode.gateway.fieldId.split('__');
      const gatewayFieldId = gatewayFieldIds[0];
      return f.fieldId === gatewayFieldId;
    });

    if (!decisionField) {
      const error = {
        message: `'${gatewayNode.text}' has no decision field selected.`
      };
      modelerValidationErrorsService.addError(gatewayNode.key, error);
      errors.push(error);
      return;
    }

    const isMultiSelect =
      decisionField.Type === 'selectlist' ||
      decisionField.Type === 'radiobuttonlist' ||
      decisionField.Type === 'checkbox';

    if (isMultiSelect && gatewayNode.gateway.dbName === '') {
      if (decisionField.options.length < gatewayNode.gateway.gates.length) {
        // Less options than gateways
        const error = {
          message: `'${gatewayNode.text}' has too many paths. Add options to the previous task or remove paths.`
        };
        modelerValidationErrorsService.addError(gatewayNode.key, error);
        errors.push(error);
      }

      gatewayNode.gateway.gates.forEach((gate) => {
        if (gate.IsDefault) {
          return;
        }
        if (
          !gate.condition ||
          (gate.condition.Name === '' &&
            gate.condition.Value === guidService.empty()) ||
          (decisionField.Type !== 'checkbox' &&
            (!gate.multiCondition || gate.multiCondition.length === 0))
        ) {
          const error = {
            message: `'${gatewayNode.text}' has an invalid decision field option.`
          };
          modelerValidationErrorsService.addError(gatewayNode.key, error);
          errors.push(error);
        } else if (!gate.multiCondition || gate.multiCondition.length <= 1) {
          const option = decisionField.options.find(
            (option) =>
              gate.condition.Value === option.value ||
              gate.condition.Name === option.text
          );
          if (!option) {
            // Option was not located
            if (gate.condition.Name == null || gate.condition.Name === '') {
              const error = {
                message: `'${gatewayNode.text}' has an empty decision field option.`
              };
              modelerValidationErrorsService.addError(gatewayNode.key, error);
              errors.push(error);
            } else {
              // Check it is not a valid database dropdown list
              if (gate.condition.Name !== gate.condition.Value) {
                const error = {
                  message: `'${gatewayNode.text}' '${gate.condition.Name}' does not match a decision field option.`
                };
                modelerValidationErrorsService.addError(gatewayNode.key, error);
                errors.push(error);
              }
            }
          }
        }
      });
    }
  }

  function validateGateWayConditions(modelNode, links) {
    const gatewayName = modelNode.text;
    let gotError = false;

    lodash.forEach(links, function (link) {
      if (
        link &&
        link.Trigger &&
        link.Trigger.NameRef &&
        link.Trigger.NameRef === 'GatewayDecisionCommand' &&
        !link.Conditions &&
        !link.isBacklink
      ) {
        gotError = true;
      }
    });

    if (gotError) {
      const error = {
        message: `'${gatewayName}' gateway contains invalid conditions.`
      };
      modelerValidationErrorsService.addError(modelNode.key, error);
      errors.push(error);
    }
  }

  function validateDynamicActor(modelNode, nodes, links) {
    const node = getNodeById(modelNode.id);

    if (
      node &&
      !isComponent &&
      node.IsFirstNode &&
      modelNode.actorType === appConfig.modeler_ActorType.DYNAMIC &&
      modelNode.actor === appConfig.modeler_DynamicActor.PREVIOUS_ACTOR
    ) {
      const error = {
        message: `'${modelNode.text}' cannot assign to previous actor as it is the first step`
      };
      modelerValidationErrorsService.addError(modelNode.key, error);
      errors.push(error);
    }

    if (
      node &&
      modelNode.actorType === appConfig.modeler_ActorType.DYNAMIC &&
      modelNode.actor === appConfig.modeler_DynamicActor.SELECT_DYNAMIC_ACTORS
    ) {
      const previousNodes =
        flowinglyModelUtilityService.getPreviousAcitivityNodesForNodeRecursive(
          modelNode.key,
          nodes,
          links,
          null,
          false
        );
      if (
        previousNodes.length > 0 &&
        previousNodes.some(
          (n) => n.taskType === flowinglyConstants.taskType.CUSTOM_EMAIL
        )
      ) {
        const error = {
          message: `Dynamic actor step '${modelNode.text}' cannot follow custom email step`
        };
        modelerValidationErrorsService.addError(modelNode.key, error);
        errors.push(error);
      } else if (
        !node.SelectedDynamicActors ||
        node.SelectedDynamicActors.length === 0
      ) {
        const error = {
          message: `'${modelNode.text}' needs dynamic actors to be selected`
        };
        modelerValidationErrorsService.addError(modelNode.key, error);
        errors.push(error);
      }
    }

    if (
      firstStepIsNonIntraPublicNode() &&
      (modelNode.actor === appConfig.modeler_DynamicActor.INITIATOR ||
        modelNode.actor === appConfig.modeler_DynamicActor.INITIATOR_MANAGER)
    ) {
      const error = {
        message: `'${modelNode.text}' cannot be assigned to initiator or initiator manager when the first step is a non-intranet public form`
      };
      errors.push(error);
      modelerValidationErrorsService.addError(modelNode.key, error);
    }
  }

  function validateUniqueNodeId(modelNodes) {
    lodash.forEach(modelNodes, function (modelNode) {
      const items = lodash.filter(modelNodes, function (n) {
        return n.id === modelNode.id;
      });

      if (items.length > 1) {
        lodash.forEach(items, function (n) {
          const error = { message: `'${n.text}' has duplicate ID.` };
          modelerValidationErrorsService.addError(modelNode.key, error);
          errors.push(error);
        });
      }
    });
  }

  function validateMultipleApproval(modelNode) {
    const node = getNodeById(modelNode.id);

    if (node && node.IsFirstNode) {
      const error = {
        message: `The multiple approval step '${node.StepName}' cannot be used as the first step of a flow.`
      };
      modelerValidationErrorsService.addError(modelNode.key, error);
      errors.push(error);
      return;
    }

    if (!node.SelectedApprovers || node.SelectedApprovers.length === 0) {
      const error = {
        message: `'${modelNode.text}' needs approvers to be selected`
      };
      modelerValidationErrorsService.addError(modelNode.key, error);
      errors.push(error);
    }
  }

  function validateAssignedActorAndApproverForNodesAfterPublicForm(
    publicFormModelNode,
    modelNodes
  ) {
    const nodes = getNodes();
    for (const modelNode of modelNodes) {
      const matchNode = nodes.find((n) => {
        return n.ModelerNodeId === modelNode.id;
      });

      if (matchNode == null || matchNode.isDeleted) return;

      const isPublicForm = getPublicFormType(publicFormModelNode) == 0;

      // for both public and intranet forms
      if (
        modelNode.actor === appConfig.modeler_DynamicActor.SELECT_DYNAMIC_ACTORS
      ) {
        const error = {
          message: `'${modelNode.text}' cannot be assigned to select dynamic actors when the first step is of any public form type`
        };
        modelerValidationErrorsService.addError(modelNode, error);
        errors.push(error);
      } else if (
        flowinglyModelUtilityService.isMultipleApprovalNode(modelNode)
      ) {
        const error = {
          message: `'${modelNode.text}' cannot be parallel/sequential approval when the first step is a public form`
        };
        modelerValidationErrorsService.addError(modelNode, error);
        errors.push(error);
      }
    }
  }

  function validateBacklinks(nodes, links) {
    const backlinks = links.filter((l) => l.isBacklink);
    backlinks.forEach((backlink) => {
      const fromNode = nodes.find((n) => n.key === backlink.from);
      const toNode = nodes.find((n) => n.key === backlink.to);

      let error;

      //@TODO consolidate the backlink result fromm valdiation.service.js
      // instead of hardcoding the cases
      switch (
        validationService.isValidBackLink(fromNode, toNode, nodes, links)
      ) {
        case 0: // success
          break;
        case 10:
          error = {
            message: `A non-intranet public form cannot have a backlink connected to it`
          };
          modelerValidationErrorsService.addError(fromNode.key, error);
          errors.push(error);
          break;
        default:
          error = {
            message: `Back link from '${fromNode.text}' to '${toNode.text}' is invalid.`
          };
          modelerValidationErrorsService.addError(fromNode.key, error);
          errors.push(error);
          break;
      }
    });
  }

  function validateSelectApproversNode(workflowNodes, nodes, links) {
    workflowNodes.forEach((workflowNode) => {
      const goNodeData = nodes.find((n) => n.id == workflowNode.ModelerNodeId);

      if (
        !!workflowNode.isDeleted ||
        workflowNode.WhenApproversSelected ===
          flowinglyConstants.whenApproversSelected.MODEL_TIME ||
        (workflowNode.StepType !==
          flowinglyConstants.taskType.PARALLEL_APPROVAL &&
          workflowNode.StepType !==
            flowinglyConstants.taskType.SEQUENTIAL_APPROVAL)
      )
        return;

      const modelNode = flowinglyModelUtilityService.getNodeById(
        nodes,
        workflowNode.ModelerNodeId
      );
      if (!workflowNode.SelectApproverModelerNodeId) {
        const previousNodes =
          flowinglyModelUtilityService.getPreviousNodesForNode(
            modelNode.key,
            nodes,
            links
          );
        if (previousNodes.length === 1) {
          if (
            previousNodes[0].category ===
            flowinglyConstants.nodeCategory.CONVERGE_GATEWAY
          ) {
            const error = {
              message: `'${modelNode.text}' following a merge gateway requires you to select a step where approvers will be chosen`
            };
            modelerValidationErrorsService.addError(goNodeData.key, error);
            errors.push(error);
          } else if (
            previousNodes[0].taskType ===
            flowinglyConstants.taskType.CUSTOM_EMAIL
          ) {
            const error = {
              message: `'${modelNode.text}' following a custom email step requires you to select a step where approvers will be chosen`
            };
            modelerValidationErrorsService.addError(goNodeData.key, error);
            errors.push(error);
          } else if (
            previousNodes[0].taskType === flowinglyConstants.taskType.COMPONENT
          ) {
            const error = {
              message: `'${modelNode.text}' following a component requires you to select a step where approvers will be chosen`
            };
            modelerValidationErrorsService.addError(goNodeData.key, error);
            errors.push(error);
          } else if (
            previousNodes[0].taskType ===
              flowinglyConstants.taskType.PARALLEL_APPROVAL ||
            previousNodes[0].taskType ===
              flowinglyConstants.taskType.SEQUENTIAL_APPROVAL
          ) {
            const error = {
              message: `'${modelNode.text}' following a multiple approval step requires you to select a step where approvers will be chosen`
            };
            modelerValidationErrorsService.addError(goNodeData.key, error);
            errors.push(error);
          }
        }
        return;
      }

      const allPreviousNodes =
        flowinglyModelUtilityService.getPreviousAcitivityNodesForNodeRecursive(
          modelNode.key,
          nodes,
          links,
          null,
          true
        );
      const selectApproverNode = allPreviousNodes.find(
        (pn) => pn.id === workflowNode.SelectApproverModelerNodeId
      );
      if (
        !selectApproverNode ||
        !flowinglyActivityService.isTaskOrSingleApprovalActivity(
          selectApproverNode
        )
      ) {
        const error = {
          message: `'${modelNode.text}' requires you to select valid step where approvers will be chosen`
        };
        modelerValidationErrorsService.addError(goNodeData.key, error);
        errors.push(error);
      }
    });
  }

  function validateCustomEmailNode(workflowNodes, nodes, links) {
    workflowNodes.forEach((workflowNode) => {
      const goNodeData = nodes.find((n) => n.id == workflowNode.ModelerNodeId);
      if (
        !!workflowNode.isDeleted ||
        workflowNode.StepType !== flowinglyConstants.taskType.CUSTOM_EMAIL
      )
        return;

      if (workflowNode.IsFirstNode) {
        const error = {
          message: `The custom email step '${workflowNode.StepName}' cannot be used as the first step of a flow.`
        };
        modelerValidationErrorsService.addError(goNodeData.key, error);
        errors.push(error);
        return;
      }

      if (!workflowNode.NodeCustomEmail) {
        const error = {
          message: `'${workflowNode.StepName}' requires you to configure custom email data`
        };
        modelerValidationErrorsService.addError(goNodeData.key, error);
        errors.push(error);
        return;
      }

      if (!workflowNode.NodeCustomEmail.FromName) {
        const error = {
          message: `'${workflowNode.StepName}' requires you to configure custom email From Name`
        };
        modelerValidationErrorsService.addError(goNodeData.key, error);
        errors.push(error);
      }
      if (!workflowNode.NodeCustomEmail.ReplyTo) {
        const error = {
          message: `'${workflowNode.StepName}' requires you to configure custom email Reply To`
        };
        modelerValidationErrorsService.addError(goNodeData.key, error);
        errors.push(error);
      }

      const template = workflowNode.NodeCustomEmail.TemplateHtml;
      if (!template) {
        const error = {
          message: `'${workflowNode.StepName}' requires you to define custom email template`
        };
        modelerValidationErrorsService.addError(goNodeData.key, error);
        errors.push(error);
      } else {
        const invalidVariables = getInvalidTemplateVariablesForPriorNodes(
          template,
          workflowNode,
          workflowNodes,
          nodes,
          links
        );
        if (invalidVariables) {
          invalidVariables.forEach((variable) => {
            const error = {
              message: `'${workflowNode.StepName}' contains invalid step field variable '${variable}' in its email template`
            };
            modelerValidationErrorsService.addError(goNodeData.key, error);
            errors.push(error);
          });
        }
      }

      const recipients = workflowNode.NodeCustomEmail.SelectedEmailRecipients;
      if (
        !workflowNode.NodeCustomEmail.AdditionalRecipients &&
        (!recipients ||
          recipients.length === 0 ||
          isAllEmailRecipientInvalidFields(
            recipients,
            workflowNode,
            workflowNodes,
            nodes,
            links
          ))
      ) {
        const error = {
          message: `'${workflowNode.StepName}' requires you to configure custom email Recipients`
        };
        modelerValidationErrorsService.addError(goNodeData.key, error);
        errors.push(error);
      }

      if (!workflowNode.NodeCustomEmail.Subject) {
        const error = {
          message: `'${workflowNode.StepName}' requires you to configure custom email Subject`
        };
        modelerValidationErrorsService.addError(goNodeData.key, error);
        errors.push(error);
      } else {
        const invalidVariables = getInvalidTemplateVariablesForPriorNodes(
          workflowNode.NodeCustomEmail.Subject,
          workflowNode,
          workflowNodes,
          nodes,
          links
        );
        if (invalidVariables) {
          invalidVariables.forEach((variable) => {
            const error = {
              message: `'${workflowNode.StepName}' contains invalid step field variable '${variable}' in its email subject`
            };
            modelerValidationErrorsService.addError(goNodeData.key, error);
            errors.push(error);
          });
        }
      }

      if (customEmailNodeExistAloneBeforeMerge(workflowNode, nodes, links)) {
        const error = {
          message: `'${workflowNode.StepName}' needs to follow or precede activity step between a Diverge and a Merge`
        };
        modelerValidationErrorsService.addError(goNodeData.key, error);
        errors.push(error);
      }
    });
  }

  function getInvalidTemplateVariablesForPriorNodes(
    template,
    workflowNode,
    workflowNodes,
    nodes,
    links
  ) {
    template = htmlDecode(template);
    const modelNode = flowinglyModelUtilityService.getNodeById(
      nodes,
      workflowNode.ModelerNodeId
    );
    let stepFields = [];
    const allPreviousNodes =
      flowinglyModelUtilityService.getPreviousAcitivityNodesForNodeRecursive(
        modelNode.key,
        nodes,
        links,
        null,
        true
      );
    allPreviousNodes.forEach((modelerNode) => {
      const node = workflowNodes.find(
        (wfn) => wfn.ModelerNodeId === modelerNode.id
      );
      const variables = getStepFieldVariables(node);
      stepFields = stepFields.concat(variables);
    });

    return checkTemplateForInvalidStepFields(template, stepFields);
  }

  function getInvalidTemplateVariablesForNode(template, node) {
    template = htmlDecode(template);
    const stepFields = getStepFieldVariables(node);
    return checkTemplateForInvalidStepFields(template, stepFields);
  }

  function checkTemplateForInvalidStepFields(template, stepFields) {
    stepFields.forEach((sf) => {
      const re = new RegExp(escapeRegExp(sf), 'g');
      template = template.replace(re, '');
    });
    const regexForInvalidStepField = /{step\..+?\..+?}/g;
    return template.match(regexForInvalidStepField);
  }

  function getStepFieldVariables(node) {
    if (!node || !node.Card || !node.Card.formElements) {
      return;
    }
    const stepFields = [];
    const formElements = node.Card.formElements;
    formElements.forEach((field) => {
      stepFields.push(`{step.${node.StepName}.${field.displayName}}`);
    });

    const tableFields = formElements.filter(
      (field) => field.typeName == 'Table'
    );
    if (tableFields && tableFields.length > 0) {
      const tableCells = flowinglyModelUtilityService.transformTableCellToField(
        flowinglyModelUtilityService.getEligibleCellsInFields(tableFields)
      );

      if (tableCells && tableCells.length > 0) {
        tableCells.forEach((cell) => {
          stepFields.push(`{step.${node.StepName}.${cell.displayName}}`);
        });
      }
    }

    // add extra step variables
    stepFields.push(`{step.${node.StepName}.Step Name}`);
    stepFields.push(`{step.${node.StepName}.User Who Approved}`);
    stepFields.push(`{step.${node.StepName}.Approval Date}`);
    stepFields.push(`{step.${node.StepName}.Comment Made By User}`);
    return stepFields;
  }

  function htmlDecode(value) {
    return $('<div/>').html(value).text(); // -- *shakes fist* damn ts
  }

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  function isAllEmailRecipientInvalidFields(
    recipients,
    workflowNode,
    workflowNodes,
    nodes,
    links
  ) {
    return recipients.every((r) => {
      if (r.SearchEntityType !== 'StepFormField') return false;

      const modelNode = flowinglyModelUtilityService.getNodeById(
        nodes,
        workflowNode.ModelerNodeId
      );
      const allPreviousModelerNodes =
        flowinglyModelUtilityService.getPreviousAcitivityNodesForNodeRecursive(
          modelNode.key,
          nodes,
          links,
          null,
          true
        );
      const recipientModelerNode = allPreviousModelerNodes.find(
        (n) => n.id === r.FieldModelerNodeId
      );
      if (!recipientModelerNode) return true;

      const formElements = workflowNodes.find(
        (wfn) => wfn.ModelerNodeId === recipientModelerNode.id
      ).Card.formElements;
      const formField = formElements.find((formElement) => {
        return formElement.name === r.FieldName;
      });
      if (
        !formField ||
        (formField.type !== flowinglyConstants.formFieldType.EMAIL &&
          formField.type !== flowinglyConstants.formFieldType.LOOKUP)
      )
        return true;

      return false;
    });
  }

  function customEmailNodeExistAloneBeforeMerge(workflowNode, nodes, links) {
    const modelNode = flowinglyModelUtilityService.getNodeById(
      nodes,
      workflowNode.ModelerNodeId
    );
    const nextModelerNodes = flowinglyModelUtilityService.getNextNodesForNode(
      modelNode.key,
      nodes,
      links
    );
    let hasFollowActivityNode = true;
    let hasPrecedeActivityNode = true;

    if (
      nextModelerNodes.length === 1 &&
      nextModelerNodes[0].category ===
        flowinglyConstants.nodeCategory.CONVERGE_GATEWAY
    ) {
      hasFollowActivityNode = false;
    }

    let currentNode = modelNode;
    while (currentNode.stepType === flowinglyConstants.stepType.CUSTOM_EMAIL) {
      const prevModelerNodes =
        flowinglyModelUtilityService.getPreviousNodesForNode(
          currentNode.key,
          nodes,
          links
        );
      if (prevModelerNodes.length !== 1) return false;
      currentNode = prevModelerNodes[0];
    }

    if (
      currentNode.category === flowinglyConstants.nodeCategory.DIVERGE_GATEWAY
    ) {
      hasPrecedeActivityNode = false;
    }

    return !hasFollowActivityNode && !hasPrecedeActivityNode;
  }

  function findLinksFrom(gatewayNode, links) {
    const connectedLinks = lodash.filter(links, function (link) {
      return link.from === gatewayNode.key;
    });
    return connectedLinks;
  }

  function findNodesFrom(nodes, links) {
    const connectedNodes = [];
    lodash.forEach(links, function (link) {
      const node = lodash.find(nodes, function (node) {
        return node.key === link.to;
      });
      connectedNodes.push(node);
    });
    return connectedNodes;
  }

  function findFields(nodes) {
    const fields = [];
    lodash.forEach(nodes, function (value) {
      const node = getNodeById(value.id);
      const nodeName = value.text;
      if (node.Card && node.Card.formElements) {
        lodash.forEach(node.Card.formElements, function (val) {
          if (isAcceptedFieldType(val.type)) {
            fields.push({
              nodeName: nodeName,
              name: val.displayName,
              fieldId: val.name,
              options: val.options,
              Type: val.type,
              label: nodeName + ' - ' + val.displayName,
              isRequired: val.validation.required
            });
          }
        });
      }
    });
    return fields;
  }

  // This and other functionality used here was copy and pasted and improved from gateway.store.js! Yeah I know ...
  // ToDo we need to refactor design into this code so we have a single component to access such functionality
  // For example we might have a FieldType component. Right now in the code base we have four major issues:
  //  1. Functionality is all over the place and functions are dependant on state (ie arguments not used)
  //  2. We dont follow CQS - so for example a method called FindGates will set the first gate to being the default (ie it changes state)
  //  3. State is duplicated all over the place - so we need a stateful, organised model.  eg nodeDataArray is stored in many places
  //  4. Events and watches conspire to make tracking what happens when, very difficult
  function isAcceptedFieldType(type) {
    switch (type) {
      case 'instruction':
      case 'text':
      case 'textarea':
      case 'email':
      case 'password':
      case 'fileupload':
      case 'approvalrule':
      case 'tasklist':
      case 'multiselectlist':
      case 'date':
      case '7': //for few cards having this type
      case 'attachdocument':
        return false;

      default:
        //checkbox
        //radioButtonList
        //selectList
        //number
        //currency
        return true;
    }
  }

  function validateDivergeGateWay(allModelNodes, allLinks, node, nodeLinks) {
    if (nodeLinks.foreToConnectorCount > 1) {
      const error = {
        message: `Diverge nodes can only have one incoming link. Remove multiple incoming links.`
      };
      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }
    if (nodeLinks.foreFromConnectorCount < 2) {
      const error = {
        message: `One Diverge gateway only has one/has no outgoing links. Connect at least two outgoing links."`
      };
      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
      return;
    }

    const nodes = flowinglyModelUtilityService.getNextNodesForNode(
      node.key,
      allModelNodes,
      allLinks
    );

    const divergeRuleData = rulesetParserService.divergeParser(nodes);
    if (divergeRuleData && divergeRuleData.length > 0) {
      const validationResult = rulesetValidationService.validateRuleData(
        rulesetService.DIVERGE_RULESET,
        divergeRuleData
      );

      if (!validationResult.isValid) {
        const error = {
          message: `${validationResult.errorMessage} is not allowed for diverge`
        };
        modelerValidationErrorsService.addError(node.key, error);
        errors.push(error);
      }
    }
  }
  function isDecisionGatewayConnectedToEmailNode(
    node,
    allLinks,
    allModelNodes
  ) {
    const toLinks = lodash.filter(allLinks, function (link) {
      return link.to === node.key && !link.isBacklink;
    });
    if (toLinks && toLinks.length > 0) {
      //find email links connected to the node
      const foundEmailNodeLinks = lodash.filter(toLinks, function (link) {
        const prevNode = flowinglyModelUtilityService.getNodeByKey(
          allModelNodes,
          link.from
        );
        if (prevNode) {
          return prevNode.taskType === flowinglyConstants.taskType.CUSTOM_EMAIL;
        }
      });
      if (foundEmailNodeLinks && foundEmailNodeLinks.length > 0) {
        for (
          let linkIndex = 0;
          linkIndex < foundEmailNodeLinks.length;
          linkIndex++
        ) {
          const toEmailNodeLinks = lodash.filter(allLinks, function (link) {
            return (
              link.to === foundEmailNodeLinks[linkIndex].from &&
              !link.isBacklink
            );
          });
          if (toEmailNodeLinks && toEmailNodeLinks.length > 0) {
            //find decision gateway links connected to the email link
            const foundDecisionLinks = lodash.filter(
              toEmailNodeLinks,
              function (link) {
                const prevNode = flowinglyModelUtilityService.getNodeByKey(
                  allModelNodes,
                  link.from
                );
                if (prevNode) {
                  return (
                    prevNode.category ===
                    flowinglyConstants.nodeCategory.EXCLUSIVE_GATEWAY
                  );
                }
              }
            );
            if (
              foundDecisionLinks &&
              foundDecisionLinks.length > 0 &&
              !checkCategoryInPrevNodes(
                foundDecisionLinks,
                flowinglyConstants.nodeCategory.DIVERGE_GATEWAY,
                allLinks
              )
            ) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }
  //function to check a particular node Category is present in the previous nodes
  function checkCategoryInPrevNodes(fromLinks, nodeCategory, allLinks) {
    for (let i = 0; i < fromLinks.length; i++) {
      let invalidNode = false;
      let linkKey = fromLinks[i].from;
      while (!invalidNode) {
        const toLinks = lodash.filter(allLinks, function (link) {
          return link.to === linkKey && !link.isBacklink;
        });
        if (toLinks && toLinks.length > 0) {
          for (let tl = 0; tl < toLinks.length; tl++) {
            const prevNode = flowinglyModelUtilityService.getNodeByKey(
              allModelNodes,
              toLinks[tl].from
            );
            if (prevNode) {
              linkKey = prevNode.key;
              if (prevNode.category === nodeCategory) {
                return true;
              } else if (
                prevNode.IsInitial === 'true' ||
                prevNode.IsFinal === 'true'
              ) {
                invalidNode = true;
              }
            } else {
              invalidNode = true;
            }
          }
        } else {
          invalidNode = true;
        }
      }
    }
    return false;
  }
  function validateConvergeGateWay(allModelNodes, allLinks, node, nodeLinks) {
    if (nodeLinks.foreToConnectorCount < 2) {
      const error = {
        message: `One Merge gateway only has one/has no incoming links. Connect at least two incoming links."`
      };
      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
      return;
    }

    if (isDecisionGatewayConnectedToEmailNode(node, allLinks, allModelNodes)) {
      const error = {
        message:
          'Custom email cannot connect directly to merge node when following a Decision Gateway.'
      };
      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
      return;
    }

    const nodes = flowinglyModelUtilityService.getPreviousNodesForNode(
      node.key,
      allModelNodes,
      allLinks
    );

    const convergeRuleData = rulesetParserService.convergeParser(nodes);
    if (convergeRuleData && convergeRuleData.length > 0) {
      const validationResult = rulesetValidationService.validateRuleData(
        rulesetService.CONVERGE_RULESET,
        convergeRuleData
      );

      if (!validationResult.isValid) {
        const error = {
          message: `${validationResult.errorMessage} is not allowed`
        };
        modelerValidationErrorsService.addError(node.key, error);
        errors.push(error);
      }
    }
  }

  function validateExclusiveGateWay(modelNodes, node, links) {
    const validateNodeValues = function (gates) {
      for (let firstIndex = 0; firstIndex < gates.length; firstIndex++) {
        const gate = gates[firstIndex];
        const foundModelNodes = lodash.filter(modelNodes, function (modelNode) {
          return modelNode.key === gate.RouteToKey;
        });
        if (
          foundModelNodes === undefined ||
          foundModelNodes === null ||
          foundModelNodes.length < 1
        ) {
          const error = {
            message: `Gateway ${node.text} has an invalid path to ${gate.name}`
          };
          modelerValidationErrorsService.addError(node.key, error);
          errors.push(error);
        }

        if (gate.IsDefault) continue;
      }

      /*
       * Check if any of the options are used in multiple gateway paths.
       */
      const duplicateValues = [];
      lodash
        .chain(node.gateway.gates)
        .flatMap((gate) => lodash.get(gate, 'condition.Name', '').split('|'))
        .groupBy()
        .map((grouping, key) => {
          if (grouping.length > 1 && key !== '') {
            duplicateValues.push(key);
          }
        })
        .value();
      if (duplicateValues.length) {
        const error = {
          message: `"${
            node.text
          }" has conditions which are the same "${duplicateValues.join(
            ','
          )}". Select unique conditions for each path`
        };
        modelerValidationErrorsService.addError(node.key, error);
        errors.push(error);
      }
    };

    if (links.foreToConnectorCount > 1) {
      const error = {
        message: `'${node.text}' can only have one incoming link. Remove multiple incoming links.`
      };
      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }
    if (links.foreFromConnectorCount < 2) {
      const error = {
        message: `'${node.text}' only has one/has no outgoing links. Connect at least two outgoing links.`
      };
      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }

    ////check that the node has a valid name
    if (node.text === '') {
      const error = { message: 'Decision must have a name' };
      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }

    if (node.gateway) {
      if (node.gateway.fieldId === '' || node.gateway.fieldId == undefined) {
        const error = {
          message: `'${node.text}' must have a decision field. Select '${node.text}' and choose a decision field.`
        };
        modelerValidationErrorsService.addError(node.key, error);
        errors.push(error);
      }
      let conditionsMissing = false;
      if (node.gateway.gates) {
        lodash.forEach(node.gateway.gates, function (gate) {
          if (
            (gate.condition == undefined ||
              gate.condition.Value === '' ||
              gate.condition.Value == undefined) &&
            !gate.IsDefault
          ) {
            conditionsMissing = true;
          }
        });
        if (conditionsMissing) {
          const error = {
            message: `'${node.text}' is missing conditions. Select unique conditions for each path.`
          };
          modelerValidationErrorsService.addError(node.key, error);
          errors.push(error);
        }
        if (node.gateway.gates.length !== links.foreFromConnectorCount) {
          const error = {
            message: `'${node.text}' having invalid link(s). Remove unnecessary link(s)`
          };
          modelerValidationErrorsService.addError(node.key, error);
          errors.push(error);
        }
        validateNodeValues(node.gateway.gates);
      } else {
        const error = {
          message:
            "'" +
            node.text +
            "' is missing conditions. Select unique conditions for each path."
        };
        modelerValidationErrorsService.addError(node.key, error);
        errors.push(error);
      }
    }
  }

  function getAllModelNodes(model) {
    return model.nodeDataArray;
  }

  function getAllModelLinks(model) {
    return model.linkDataArray;
  }

  /**
   * Returns all the workflow nodes (the none gojs one).
   *
   * This function is depracted because it s reliant on a global function.
   * Consider using context either
   *      context.queryable.nodes.toList()
   *      workflow.Nodes
   * @deprecated
   */
  function getNodes() {
    console.debug(new Error('@deprecated function getNodes() used'));
    return workflow.Nodes || [];
  }

  function getNodeById(modelerNodeId) {
    console.debug(new Error('@deprecated function getNodeById() used'));
    const allWorkflowNodes = getNodes();
    const node = allWorkflowNodes.find((n) => {
      return n.ModelerNodeId.toLowerCase() === modelerNodeId.toLowerCase();
    });

    return node;
  }

  function getLinks(model, node) {
    const res = {
      startConnectors: [],
      endConnectors: [],
      foreToConnectorCount: 0,
      foreFromConnectorCount: 0,
      backToConnectorCount: 0,
      backFromConnectorCount: 0,
      hasToConnector: false
    } as any;
    const arr = model.linkDataArray;
    lodash.forEach(arr, function (link) {
      if (node.IsInitial === 'true' && node.key === link.to) {
        res.startConnectors.push(node);
      } else if (node.IsFinal === 'true' && node.key === link.from) {
        res.endConnectors.push(node);
      } else if (node.key === link.to) {
        res.hasToConnector = true;
        link.isBacklink
          ? res.backToConnectorCount++
          : res.foreToConnectorCount++;
      } else if (node.key === link.from) {
        res.hasFromConnector = true;
        link.isBacklink
          ? res.backFromConnectorCount++
          : res.foreFromConnectorCount++;
      }
    });
    return res;
  }

  function validateEndObject(allModelNodes) {
    const endNodes = lodash.filter(allModelNodes, function (item) {
      return item.IsFinal === 'true';
    });
    if (endNodes === undefined || endNodes === null || endNodes.length < 1) {
      errors.push({ message: 'There must be at least one END node.' });
    }
  }

  function validateStartObject(allModelNodes) {
    const startNodes = lodash.filter(allModelNodes, function (item) {
      return item.IsInitial === 'true';
    });
    if (
      startNodes === undefined ||
      startNodes === null ||
      startNodes.length < 1
    ) {
      errors.push({ message: 'There must be a START node.' });
    } else if (startNodes.length > 1) {
      errors.push({ message: 'Only one START node is allowed.' });
    }
  }

  function validateThatStartNodeOnlyHasFromLinks(links) {
    if (links.startConnectors.length > 1) {
      errors.push({ message: 'Connectors can only flow from a START node.' });
    }
  }

  function validateThatNodeDoesNotHaveMultipleFromLinks(links, node) {
    if (flowinglyActivityService.isApprovalActivity(node)) {
      approvalNodeDoesNotHaveMultipleBackOrForeFromConnectors(links, node);
      approvalNodeHasForelinkWhenBackFromConnectorExists(links, node);
    } else if (
      (!flowinglyGatewayService.isGateway(node) &&
        links.foreFromConnectorCount > 1) ||
      (flowinglyGatewayService.isConvergeGateway(node) &&
        links.foreFromConnectorCount > 1)
    ) {
      const error = {
        message: `Step ${node.text} cannot have multiple FROM connectors`
      };
      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }
  }

  function approvalNodeDoesNotHaveMultipleBackOrForeFromConnectors(
    links,
    node
  ) {
    if (links.foreFromConnectorCount > 1) {
      const error = {
        message: `Step ${node.text} cannot have more than two FROM connectors`
      };
      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }
    if (links.backFromConnectorCount > 1) {
      const error = {
        message: `Step ${node.text} cannot have more than two back FROM connectors`
      };
      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }
  }

  function approvalNodeHasForelinkWhenBackFromConnectorExists(links, node) {
    if (
      links.backFromConnectorCount > 0 &&
      links.foreFromConnectorCount === 0
    ) {
      const error = {
        message: `Step ${node.text} cannot have back FROM connector without fore From connector`
      };
      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }
  }

  function validateNodeHasFromConnector(links, node) {
    if (
      !links.hasFromConnector &&
      node.IsFinal !== 'true' &&
      node.category !== 'Pool' &&
      node.category !== 'Lane'
    ) {
      const error = {
        message: 'No FROM connector exists for step ' + node.text
      };
      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }
  }

  function validateNodeHasToConnector(links, node) {
    if (
      !links.hasToConnector &&
      node.IsInitial !== 'true' &&
      node.category !== 'Pool' &&
      node.category !== 'Lane'
    ) {
      const error = {
        message: 'No TO connector exists for step ' + node.text
      };
      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }
  }

  function validateAtleastOneObject(allModelNodes) {
    if (allModelNodes.length === 0) {
      errors.push({ message: 'Flow requires at least one step' });
    }
  }

  function validateNodeFields(modelNode, allCustomDbs) {
    if (
      modelNode.category !== 'activity' ||
      modelNode.taskType === flowinglyConstants.taskType.CUSTOM_EMAIL
    ) {
      return;
    }
    const card = getNodeById(modelNode.id).Card;
    const nonLogoFieldCount = card.formElements.filter(
      (field) => field.type !== flowinglyConstants.formFieldType.IMAGE
    ).length;

    const defaultInstructionFieldExists = card.formElements.some(
      (field) =>
        field.type === flowinglyConstants.formFieldType.INSTRUCTION &&
        field.value === 'Enter the instructions to be displayed here.'
    );

    const logoField = card.formElements.find(
      (field) => field.type === flowinglyConstants.formFieldType.IMAGE
    );

    if (nonLogoFieldCount === 0) {
      const error = {
        message:
          `${modelNode.text} needs to have at least one field on the card` +
          (logoField ? ' in addition to the Logo' : '')
      };
      modelerValidationErrorsService.addError(modelNode.key, error);
      errors.push(error);
    } else if (
      nonLogoFieldCount === 1 &&
      defaultInstructionFieldExists &&
      (!logoField || logoField.disabled)
    ) {
      // Although this warning talks about the instruction field
      // Really, it's a reminder to the user that they haven't made any changes to this step's form
      const error = {
        message: `${modelNode.text} requires you to edit the default instruction text`
      };
      modelerValidationErrorsService.addError(modelNode.key, error);
      errors.push(error);
    }

    validateOptions(
      modelNode,
      card.formElements,
      'tasklist',
      'Task',
      allCustomDbs
    );
    validateOptions(
      modelNode,
      card.formElements,
      'multiselectlist',
      'Multi-selection',
      allCustomDbs
    );
    validateOptions(
      modelNode,
      card.formElements,
      'radiobuttonlist',
      'Option',
      allCustomDbs
    );
    validateOptions(
      modelNode,
      card.formElements,
      'selectlist',
      'DropDown',
      allCustomDbs
    );
    validateTable(modelNode, card.formElements, allCustomDbs);
  }

  /**
   * This is a matrix of the expected results
   *
   *      publicFormType  | PublicFormType    | result
   *            0         |        1          | 0
   *            1         |        0          | 1
   *           "1"        |        0          | 1
   *           "0"        |        1          | 0
   *        <null|undef>  |       "1"         | 1
   *        <null|undef>  |        1          | 1
   *        <null|undef>  |       "0"         | 0
   *        <null|undef>  |   <null|undef>    | 0
   */
  function getPublicFormType(someNodeWhoseTypeImUnsureOf) {
    const tmp = someNodeWhoseTypeImUnsureOf;
    const var1 = tmp.publicFormType;
    const var2 = tmp.PublicFormType;

    const v = +coalesce(var1, var2);

    return isNaN(v) ? 0 : v;
  }

  function coalesce(...args) {
    for (const arg of args) {
      if (arg !== null && arg !== undefined && !isNaN(arg)) {
        return arg;
      }
    }
  }

  function getFirstStep() {
    let fs = getNodes().find((n) => n.IsFirstNode);
    fs = typeof fs !== 'undefined' ? fs : [];
    return fs;
  }

  function firstStepIsAnyPublicNode() {
    const firstStep = getFirstStep();

    const p1 = firstStep.publicFormType;
    const p2 = firstStep.PublicFormType;
    const hasPublicType = p1 != null || p2 != null;

    return (
      firstStep.StepType == flowinglyConstants.stepType.PUBLIC_FORM ||
      (hasPublicType && firstStep.ActorName == 'Public User')
    );
  }

  function firstStepIsIntraPublicNode() {
    const firstStep = getFirstStep();
    return firstStepIsAnyPublicNode() && !firstStepIsNonIntraPublicNode();
  }

  /**
   * This is a frigging joke. Why are there two cases of publicFormType? Sometimes you get it in camelcase
   * sometimes you get it in pascal case. Depends on when the publicFormType is set.
   *
   * @TODO standardize this! ONLY HAVE ONE CASE
   *                                                  -Cassey
   */
  function firstStepIsNonIntraPublicNode() {
    const firstStep = getFirstStep();
    const publicFormType =
      typeof firstStep !== 'undefined' ? getPublicFormType(firstStep) : false;
    return firstStepIsAnyPublicNode() && publicFormType == 0;
  }

  /**
   * How to trigger:
   *
   *      Create a field with a field that gets its value from a previous step
   *      Delete the referenced field
   *
   * @param param0
   */
  function validatePreviousField(goNodeData, node, allWorkflowNodes, field) {
    const hasPreviousField =
      field.defaultValueOption &&
      field.defaultPreviousStepId &&
      field.defaultFormFieldId &&
      field.defaultValueOption === 'previousValue';

    if (!hasPreviousField) {
      return;
    }

    const previousStep = allWorkflowNodes.find(
      (n) => n.ModelerNodeId === field.defaultPreviousStepId
    );

    if (!previousStep) {
      const error = {
        message: `The default field setup for step '${node.StepName}' field '${field.displayName}' is missing.`
      };
      modelerValidationErrorsService.addError(goNodeData.key, error);
      errors.push(error);
      return;
    } else {
      let allFields = angular.copy(previousStep.Card.formElements);
      const tableFields = allFields.filter((f) => f.typeName === 'Table');
      if (tableFields && tableFields.length > 0) {
        const tableCells =
          flowinglyModelUtilityService.transformTableCellToField(
            flowinglyModelUtilityService.getEligibleCellsInFields(tableFields)
          );
        allFields = allFields.concat(tableCells);
      }
      const defaultFormField = allFields.find(
        (pf) => pf.name === field.defaultFormFieldId
      );

      if (!defaultFormField) {
        const error = {
          message: `The default field setup for step '${node.StepName}' field '${field.displayName}' is missing.`
        };
        modelerValidationErrorsService.addError(goNodeData.key, error);
        errors.push(error);
        return;
      }
    }
  }

  function validateFieldConditions(context) {
    const allWorkflowNodes = context.workflowNodes;

    allWorkflowNodes.forEach((node) => {
      const fieldsWithConditions = node.Card.formElements.filter(
        (f) => f.conditions && f.conditions.length > 0
      );
      const goNodeData = context.queryable.goNodeData.getByIndex(
        'id',
        node.ModelerNodeId
      );

      fieldsWithConditions.forEach((field) => {
        field.conditions.forEach((fieldCondition) => {
          if (!fieldCondition.name) {
            const error = {
              message: `Field Condition for field '${field.displayName}' in '${node.StepName}' must have a name for condition`
            };
            modelerValidationErrorsService.addError(goNodeData.key, error);
            errors.push(error);
          }

          if (
            fieldCondition.decisions == null ||
            fieldCondition.decisions.length === 0
          ) {
            const error = {
              message: `Field Condition '${fieldCondition.name}' for field '${field.displayName}' in '${node.StepName}' must have decisions set`
            };
            modelerValidationErrorsService.addError(goNodeData.key, error);
            errors.push(error);
          }

          if (
            fieldCondition.actions == null ||
            fieldCondition.actions.length === 0
          ) {
            const error = {
              message: `Field Condition '${fieldCondition.name}' for field '${field.displayName}' in '${node.StepName}' must have actions set`
            };
            modelerValidationErrorsService.addError(goNodeData.key, error);
            errors.push(error);
          }

          fieldCondition.decisions.forEach((decision) => {
            const formFieldConditionOption =
              flowinglyConstants.formFieldConditionOption;
            const condition = getEnumValue(
              formFieldConditionOption,
              decision.condition
            ).toString();

            if (!decision.decisionField) {
              const error = {
                message: `Field Condition '${fieldCondition.name}' for field '${field.displayName}' in '${node.StepName}' must have a decision field`
              };
              modelerValidationErrorsService.addError(goNodeData.key, error);
              errors.push(error);
            }

            if (condition == '-1') {
              const error = {
                message: `Field Condition '${fieldCondition.name}' for field '${field.displayName}' in '${node.StepName}' must have a condition`
              };
              modelerValidationErrorsService.addError(goNodeData.key, error);
              errors.push(error);
            }

            if (
              (condition == formFieldConditionOption.Equals.toString() ||
                condition == formFieldConditionOption.NotEquals.toString()) &&
              decision.value.toString() == '-1'
            ) {
              const error = {
                message: `Field Condition '${fieldCondition.name}' for field '${field.displayName}' in '${node.StepName}' must have a value`
              };
              modelerValidationErrorsService.addError(goNodeData.key, error);
              errors.push(error);
            }
          });

          fieldCondition.actions.forEach((action) => {
            if (action.actionField.toString() == '-1') {
              const error = {
                message: `Field Condition '${fieldCondition.name}' for field '${field.displayName}' in '${node.StepName}' must have an action field`
              };
              modelerValidationErrorsService.addError(goNodeData.key, error);
              errors.push(error);
            }

            if (action.action == null || action.action.toString() == '-1') {
              const error = {
                message: `Field Condition '${fieldCondition.name}' for field '${field.displayName}' in '${node.StepName}' must have an action`
              };
              modelerValidationErrorsService.addError(goNodeData.key, error);
              errors.push(error);
            }
          });
        });
      });
    });
  }

  function getEnumValue(enumCollection: any, text: any) {
    if (isNaN(text)) {
      return enumCollection[
        Object.keys(enumCollection)
          .map((key) => enumCollection[key])
          .find(
            (e) => e.toString().toLowerCase() == text.toString().toLowerCase()
          )
      ];
    }
    return text;
  }

  function validateCustomValidation({ queryable }) {
    const allWorkflowNodes = getNodes();
    allWorkflowNodes.forEach((node) => {
      const Validationfields = node.Card.formElements.filter(
        (f) => f.customValidation && f.customValidation.required
      );
      const goNodeData = queryable.goNodeData.getByIndex(
        'id',
        node.ModelerNodeId
      );

      Validationfields.forEach((f) => {
        const custvalidationObj = f.customValidation;
        const type: FormFieldType = f.type;
        switch (type) {
          case FormFieldType.SHORT_TEXT:
            if (
              !lodash.isNumber(custvalidationObj.maxLength) ||
              custvalidationObj.maxLength < 0 ||
              Math.floor(custvalidationObj.maxLength) !=
                custvalidationObj.maxLength
            ) {
              const error = {
                message: `Custom value of field '${f.displayName}' in '${node.StepName}' must be a whole number`
              };
              modelerValidationErrorsService.addError(goNodeData.key, error);
              errors.push(error);
            }
            return;
          case FormFieldType.MULTISELECT_LIST:
            break;
          default:
            if (
              custvalidationObj.rule === undefined ||
              custvalidationObj.rule === ''
            ) {
              const error = {
                message: `Select validation rule  of field '${f.displayName}' in '${node.StepName}'.`
              };

              modelerValidationErrorsService.addError(goNodeData.key, error);
              errors.push(error);
              return;
            }

            if (custvalidationObj.valueOption === 'none') {
              if (!custvalidationObj.value) {
                const error = {
                  message: `Provide custom value of field '${f.displayName}' in '${node.StepName}'.`
                };

                modelerValidationErrorsService.addError(goNodeData.key, error);
                errors.push(error);
              }
            } else if (custvalidationObj.valueOption === 'previousValue') {
              const previousStep = allWorkflowNodes.find(
                (n) => n.ModelerNodeId === custvalidationObj.previousStepId
              );

              if (!previousStep) {
                const error = {
                  message: `The custom validation field setup for step '${node.StepName}' field '${f.displayName}' is missing.`
                };
                modelerValidationErrorsService.addError(goNodeData.key, error);
                errors.push(error);
                return;
              } else {
                const defaultFormField = previousStep.Card.formElements.find(
                  (pf) => pf.name === custvalidationObj.formFieldId
                );

                if (!defaultFormField) {
                  const error = {
                    message: `The custom validation field setup for step '${node.StepName}' field '${f.displayName}' is missing.`
                  };
                  modelerValidationErrorsService.addError(
                    goNodeData.key,
                    error
                  );
                  errors.push(error);
                  return;
                }
              }
            } else if (custvalidationObj.valueOption === 'createdDate') {
              if (!custvalidationObj.stepCreatedDateOffset) {
                const error = {
                  message: `Provide step created date value of field '${f.displayName}' in '${node.StepName}'.`
                };
                modelerValidationErrorsService.addError(goNodeData.key, error);
                errors.push(error);
              }
            }
            break;
        }
      });
    });
  }

  function validateTable(node, formElements, allCustomDbs) {
    const tables = lodash.filter(formElements, function (formElement) {
      return formElement.type === 'table';
    });
    lodash.forEach(tables, function (table) {
      if (table.tableSchema != undefined && table.tableSchema !== null) {
        let rows = [];
        if (Array.isArray(table.tableSchema)) {
          rows = table.tableSchema;
        } else {
          rows = JSON.parse(table.tableSchema);
        }
        // [FLOW-5425] Flag to indicate whether any of the columns in the table are required.
        let hasRequiredColumn = false;

        // "rows" are not table rows its actually columns. TODO: Misleading name for rows here instead of columns
        lodash.forEach(rows, function (row) {
          // [FLOW-5425] If the column is set as required and the flag is not already set, then set it.
          if (row.isRequired && !hasRequiredColumn) {
            hasRequiredColumn = true;
          }
          if (row.type === 6) {
            // dropdown
            validateCustomDatabase(
              node,
              'database',
              row.dbDataSource,
              table.displayName,
              row.header,
              allCustomDbs,
              errors
            );
          } else if (row.type === 7) {
            // lookup
            validateLookupField(
              node,
              row.lookupConfig,
              table.displayName,
              row.header,
              allCustomDbs,
              errors
            );
          }
        });
        // [FLOW-5425] If table is required then it must have at-least one required column.
        if (
          table.validation != null &&
          table.validation.required &&
          !hasRequiredColumn
        ) {
          const error = {
            message: `${table.displayName} on ${node.text} is set to required. At least one column of that table must also be set to required.`
          };
          errors.push(error);
        }
      } else {
        const error = {
          message: `table schema in ${node.text} for ${table.displayName} is missing.`
        };
        modelerValidationErrorsService.addError(node.key, error);
        errors.push(error);
      }
    });
  }

  function validateOptions(
    node,
    formElements,
    listType,
    displayname,
    allCustomDbs
  ) {
    const lists = lodash.filter(formElements, function (formElement) {
      return formElement.type === listType;
    });

    lodash.forEach(lists, function (list) {
      const listValues = lodash.map(list.options, 'value');
      const hasDuplicates =
        lodash.uniq(listValues).length !== listValues.length;
      if (hasDuplicates) {
        const error = {
          message: `${displayname} list option values in ${node.text} [${list.displayName}] should be unique`
        };
        modelerValidationErrorsService.addError(node.key, error);
        errors.push(error);
      }
      validateCustomDatabase(
        node,
        list.dataSource,
        list.dbDataSource,
        displayname,
        list.displayName,
        allCustomDbs,
        errors
      );
    });
  }

  function validateInstructionField(
    formElement,
    context,
    workflowNode,
    modelNode,
    errors
  ) {
    const instructionValue = formElement.value;
    if (instructionValue != null) {
      const invalidVariables = getInvalidTemplateVariablesForPriorNodes(
        instructionValue,
        workflowNode,
        context.workflowNodes,
        context.allModelNodes,
        context.allLinks
      );
      if (invalidVariables) {
        invalidVariables.forEach((variable) => {
          const error = {
            message: `'${workflowNode.StepName}' contains invalid step field variable '${variable}' in instruction field 'step.${workflowNode.StepName}.${formElement.displayName}'`
          };
          modelerValidationErrorsService.addError(modelNode.key, error);
          errors.push(error);
        });
      }
    }
  }

  function validateLookupField(
    node,
    lookupConfig,
    displayName,
    fieldDisplayName,
    allCustomDbs,
    errors
  ) {
    if (isValueEmpty(lookupConfig)) {
      const error = {
        message: `${displayName}  data source config in ${node.text} for ${fieldDisplayName} is missing.`
      };
      errors.push(error);
      modelerValidationErrorsService.addError(node.key, error);
      return;
    }

    if (isValueEmpty(lookupConfig.dbName)) {
      const error = {
        message: `${displayName} database in ${node.text} for ${fieldDisplayName} is missing.`
      };
      errors.push(error);
      modelerValidationErrorsService.addError(node.key, error);
    }

    if (
      isLookupDisplayOrQueryValueValid(
        lookupConfig.displayValue,
        lookupConfig.value
      )
    ) {
      const error = {
        message: `${displayName} display column in ${node.text} for ${fieldDisplayName} is missing.`
      };
      errors.push(error);
      modelerValidationErrorsService.addError(node.key, error);
    }

    if (
      isLookupDisplayOrQueryValueValid(
        lookupConfig.queryValue,
        lookupConfig.value
      )
    ) {
      const error = {
        message: `${displayName} query column in ${node.text} for ${fieldDisplayName} is missing.`
      };
      errors.push(error);
      modelerValidationErrorsService.addError(node.key, error);
    }
    if (isValueEmpty(lookupConfig.value)) {
      const error = {
        message: `${displayName} previous field in ${node.text} for ${fieldDisplayName} is missing.`
      };
      errors.push(error);
      modelerValidationErrorsService.addError(node.key, error);
    } else if (
      lookupConfig.value === LookupPreviousFieldTypeAndText.CurrentActorName ||
      lookupConfig.value === LookupPreviousFieldTypeAndText.CurrentActorEmail
    ) {
      const currentActorOptionText =
        lookupConfig.value === LookupPreviousFieldTypeAndText.CurrentActorName
          ? LookupPreviousFieldTypeAndText.CurrentActorNameText
          : LookupPreviousFieldTypeAndText.CurrentActorEmailText;

      const error = {
        message: `${fieldDisplayName} on ${node.text} has the '${currentActorOptionText}' value selected for the Previous Field query, this value is only valid on Public Intranet Forms.`
      };

      if (node.taskType != 99) {
        errors.push(error);
        modelerValidationErrorsService.addError(node.key, error);
      } else if (node.publicFormType != 1) {
        errors.push(error);
        modelerValidationErrorsService.addError(node.key, error);
      }
    }

    if (
      !isValueEmpty(lookupConfig.dbName) &&
      allCustomDbs.find((db) => db.name === lookupConfig.dbName) === undefined
    ) {
      const error = {
        message: `${displayName} data source database name ${lookupConfig.dbName} in ${node.text}  for ${fieldDisplayName} is not exist.`
      };
      errors.push(error);
      modelerValidationErrorsService.addError(node.key, error);
    }
  }

  function validateCustomDatabase(
    node,
    dataSource,
    dbDataSource,
    displayName,
    fieldDisplayName,
    allCustomDbs,
    errors
  ) {
    if (isValueEmpty(dataSource) || dataSource !== 'database') {
      return;
    }

    if (isValueEmpty(dbDataSource)) {
      const error = {
        message: `${displayName} data source config in ${node.text} for ${fieldDisplayName} is missing.`
      };

      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
      return;
    }

    if (isValueEmpty(dbDataSource.dbName)) {
      const error = {
        message: `${displayName} data source database name in ${node.text} for ${fieldDisplayName} is missing.`
      };

      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }
    if (
      isValueEmpty(dbDataSource.displayValue) ||
      dbDataSource.displayValue === flowinglyConstants.EMPTY_NAME
    ) {
      const error = {
        message: `${displayName} data source display value in ${node.text} for ${fieldDisplayName} is missing.`
      };

      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }

    if (
      !isValueEmpty(dbDataSource.dbName) &&
      allCustomDbs.find((db) => db.name === dbDataSource.dbName) === undefined
    ) {
      const error = {
        message: `${displayName} data source database name ${dbDataSource.dbName} in ${node.text} for ${fieldDisplayName} is not exist.`
      };

      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }

    if (dbDataSource.filters[0]) {
      if (
        !isValueEmpty(dbDataSource.filters[0].column) ||
        !isValueEmpty(dbDataSource.filters[0].operation) ||
        !isValueEmpty(dbDataSource.filters[0].value)
      ) {
        validateCustomDatabaseFilters(
          displayName,
          node,
          dbDataSource,
          fieldDisplayName,
          errors
        );
      }
    }
  }

  function validateWorkflowPassXssCheck(workflow) {
    return $http.post(
      appConfig.apiBaseUrl + 'modeler/validateWorkflowPassXssCheck',
      workflow
    );
  }

  function validateStepRules(formElements, allModelNodes) {
    if (allModelNodes) {
      lodash.forEach(allModelNodes, function (node) {
        if (
          node.rules !== null ||
          node.rules !== undefined ||
          node.rules !== ''
        ) {
          lodash.forEach(node.rules, function (rule) {
            const isvoid = function (x) {
              return typeof x === 'undefined' || x === null;
            };
            // Trigger Condition validation
            if (
              !isvoid(rule.isEnableRule) &&
              rule.isEnableRule === true &&
              isNaN(rule.condition)
            ) {
              const error = {
                message: `${node.text} is missing conditions. ${
                  isValueEmpty(rule.ruleName) ? 'Rule 1' : rule.ruleName
                } select a Condition for each step rule.`
              };
              modelerValidationErrorsService.addError(node.key, error);
              errors.push(error);
            } else if (
              !isvoid(rule.isEnableRule) &&
              rule.isEnableRule === true &&
              isValueEmpty(rule.condition)
            ) {
              const error = {
                message: `${node.text} is missing conditions. ${
                  isValueEmpty(rule.ruleName) ? 'Rule 1' : rule.ruleName
                } select a Condition for each step rule.`
              };
              modelerValidationErrorsService.addError(node.key, error);
              errors.push(error);
            }
            // Trigger Form Field validation
            if (
              !isvoid(rule.isEnableRule) &&
              rule.isEnableRule === true &&
              !isvoid(rule.triggerFieldId)
            ) {
              const triggerFieldIdExists = [];
              lodash.filter(formElements, function (item) {
                if (!isvoid(item)) {
                  if (!isvoid(item.Card)) {
                    if (!isvoid(item.Card.formElements)) {
                      lodash.filter(
                        item.Card.formElements,
                        function (formElement) {
                          if (formElement.name === rule.triggerFieldId) {
                            triggerFieldIdExists.push({
                              id: formElement.name,
                              name: rule.triggerFieldId
                            });
                          }
                        }
                      );
                    }
                  }
                }
              });

              if (
                triggerFieldIdExists === undefined ||
                triggerFieldIdExists === null ||
                triggerFieldIdExists.length < 1
              ) {
                const error = {
                  message: `${node.text} is missing conditions. ${
                    isValueEmpty(rule.ruleName) ? 'Rule 1' : rule.ruleName
                  } select a Form Field for each step rule.`
                };
                modelerValidationErrorsService.addError(node.key, error);
                errors.push(error);
              }
            } else if (
              !isvoid(rule.isEnableRule) &&
              rule.isEnableRule === true &&
              isValueEmpty(rule.triggerFieldId)
            ) {
              //console.info("else form field");
              const error = {
                message: `${node.text} is missing conditions. ${
                  isValueEmpty(rule.ruleName) ? 'Rule 1' : rule.ruleName
                } select a Form Field for each step rule.`
              };
              modelerValidationErrorsService.addError(node.key, error);
              errors.push(error);
            }
            // Action validation

            lodash.forEach(rule.actions, function (action) {
              //Action Event
              if (
                !isvoid(rule.isEnableRule) &&
                rule.isEnableRule === true &&
                isNaN(action.action)
              ) {
                const error = {
                  message: `${node.text} is missing conditions. ${
                    isValueEmpty(rule.ruleName) ? 'Rule 1' : rule.ruleName
                  } select an Event for each step rule.`
                };
                modelerValidationErrorsService.addError(node.key, error);
                errors.push(error);
              } else if (
                !isvoid(rule.isEnableRule) &&
                rule.isEnableRule === true &&
                isValueEmpty(action.action)
              ) {
                const error = {
                  message: `${node.text} is missing conditions. ${
                    isValueEmpty(rule.ruleName) ? 'Rule 1' : rule.ruleName
                  } select an Event for each step rule.`
                };
                modelerValidationErrorsService.addError(node.key, error);
                errors.push(error);
              }

              //Action Step
              if (
                !isvoid(rule.isEnableRule) &&
                rule.isEnableRule === true &&
                !isValueEmpty(action.applyToNodeId)
              ) {
                const applyToNodeExists = [];
                lodash.filter(allModelNodes, function (item) {
                  if (
                    item.id === action.applyToNodeId &&
                    item.actorType === 'dynamic'
                  ) {
                    applyToNodeExists.push({
                      id: item.id,
                      name: action.applyToNodeId
                    });
                  }
                });

                if (
                  applyToNodeExists === undefined ||
                  applyToNodeExists === null ||
                  applyToNodeExists.length < 1
                ) {
                  const error = {
                    message: `${node.text} is missing conditions. ${
                      isValueEmpty(rule.ruleName) ? 'Rule 1' : rule.ruleName
                    } select a Step for each step rule.`
                  };
                  modelerValidationErrorsService.addError(node.key, error);
                  errors.push(error);
                }
              } else if (
                !isvoid(rule.isEnableRule) &&
                rule.isEnableRule === true &&
                isValueEmpty(action.applyToNodeId)
              ) {
                const error = {
                  message: `${node.text} is missing conditions. ${
                    isValueEmpty(rule.ruleName) ? 'Rule 1' : rule.ruleName
                  } select a Step for each step rule.`
                };
                modelerValidationErrorsService.addError(node.key, error);
                errors.push(error);
              }
            });
          });
        }
      });
    }
  }

  // FLOW-6927 - Display and Query value can be empty in case of special lookup previous field condition.
  function isLookupDisplayOrQueryValueValid(valueToCheck, lookupValue) {
    return (
      isValueEmpty(valueToCheck) &&
      lookupValue !== LookupPreviousFieldTypeAndText.CurrentActorName &&
      lookupValue !== LookupPreviousFieldTypeAndText.CurrentActorEmail
    );
  }

  function isValueEmpty(valueToCheck) {
    return (
      valueToCheck === undefined || valueToCheck === null || valueToCheck === ''
    );
  }

  function validateCustomDatabaseFilters(
    displayName,
    node,
    dbDataSource,
    fieldDisplayName,
    errors
  ) {
    //atrocious code, not extensible. We actually wanted to use Object.values but we're having problems using it inside typescript so there...
    if (isValueEmpty(dbDataSource.filters[0].column)) {
      const error = {
        message: `${displayName} Column filter in ${node.text} for ${fieldDisplayName} is missing.`
      };

      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }

    if (isValueEmpty(dbDataSource.filters[0].operation)) {
      const error = {
        message: `${displayName} Operation filter in ${node.text} for ${fieldDisplayName} is missing.`
      };

      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }

    if (isValueEmpty(dbDataSource.filters[0].value)) {
      const error = {
        message: `${displayName} Value filter in ${node.text} for ${fieldDisplayName} is missing.`
      };

      modelerValidationErrorsService.addError(node.key, error);
      errors.push(error);
    }
  }
}

export type ModelerValidationServiceType = ReturnType<
  typeof modelerValidationService
>;
