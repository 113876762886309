/**
 * @name userMenuSearch
 * @module flowingly.user.menu
 * @description display search on user menu
 * ### Notes
 * 
 * @usage
 * ```
    <user-menu-search></user-menu-search>
 * ```
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/8a21340c678cd67fd35e69c09d99ae5cbd7a2b6a/src/Flowingly.Shared.Angular/flowingly.directives/usermenu/user.menu.search.component.js?at=master
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.user.menu').component('userMenuSearch', {
  bindings: {
    link: '<'
  },
  template: `
                    <div class="user-menu-search">
                        <i class="fa-light fa-magnifying-glass mr-5" ng-click="$ctrl.searchForTerm()"></i>
                        <input type="text" ng-model="$ctrl.searchTerm" ng-keypress="$ctrl.onKeyPressed($event)" class="text left-align" placeholder="Search by flow ID or subject" flowingly-xss-validation />
                    </div>
                `,
  controller: [
    '$state',
    function ($state: angular.ui.IStateService) {
      const $ctrl = this;

      $ctrl.searchForTerm = searchForTerm;
      $ctrl.onKeyPressed = onKeyPressed;

      function onKeyPressed(keyEvent) {
        if (keyEvent.which === 13) {
          searchForTerm();
        }
        keyEvent.stopImmediatePropagation();
      }

      function searchForTerm() {
        if ($ctrl.searchTerm) {
          $state.go('app.runner.search', { term: $ctrl.searchTerm });
        }
      }
    }
  ]
});
