/*
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/0e2326496816cda49c6b5e5ee10e2a2e5dc9c993/src/Flowingly.Shared.Angular/flowingly.components/facade/kendo.helper.js?at=master
 */

(function () {
  const KENDO_INSTANCE_KEY = '.kendo';

  const $inject = ['lodashService'];
  angular.module('flowingly.components').service('kendoHelper', [
    ...$inject,
    function (_) {
      return class KendoHelper {
        /**
         * This is basically a duck typing test. The kendo object always has the
         * ns and dataSource properties.
         */
        static isKendoInstance(param) {
          return (
            param.ns &&
            param.ns.substr(0, KENDO_INSTANCE_KEY.length) ==
              KENDO_INSTANCE_KEY &&
            param.dataSource
          );
        }

        /**
         * Returns the kendo controls of an _instantiated_ widget.
         * This can return any kendo widget, no matter what type it is
         *
         * @param {string|jQuery|KendoWidget} param
         */
        static getInstance(param) {
          const instance = this.isKendoInstance(param)
            ? param
            : kendo.widgetInstance(this.toJQuery(param));
          if (!instance) {
            throw new Error(
              'Tried to get instance of a non-kendo or a not yet initialized kendo element'
            );
          }
          return instance;
        }

        /**
         * This is the same as getInstance but it asserts that the instance
         * can use the kendo's api select methods
         *
         * @param {string|jQuery|KendoWidget} param
         */
        static getSelectableInstance(param) {
          const instance = this.getInstance(param);
          if (typeof instance.select !== 'function') {
            throw new Error(
              `This kendo object (${instance.ns}) does not support the select method!`
            );
          }
          return instance;
        }

        /**
         * Set the datasource of a given kendo widget.
         *
         * @param {string|jQuery|KendoWidget} param
         * @param {any} newDs
         */
        static setDatasource(param, newDs) {
          const kendoWidget = this.getInstance(param);
          kendoWidget.setDataSource(newDs);
          kendoWidget.refresh();
        }

        /**
         * Select using the dataTextField and dataValueField as the
         * criterions for the search.
         *
         * See https://docs.telerik.com/kendo-ui/api/javascript/ui/dropdownlist/methods/select
         * for specific examples of this implementation
         *
         * @param {string|jQuery|KendoWidget} param
         * @param {any} value
         */
        static selectByHtml(param, value) {
          const kendoInstance = this.getSelectableInstance(param);
          const elements = kendoInstance.ul
            .children()
            .toArray()
            .filter((e) => e.innerHTML);

          if (elements.length == 0) {
            throw new Error(
              `Did not find any child of this widget with an innerHTML of ${value}`
            );
          } else {
            if (elements.length > 1) {
              console.warn(`Found more ${elements.length} results using selectByHtml using the criteria ${value}. 
                                Consider using selectByCriteria instead. This will default to first element found`);
            }
            kendoInstance.select(elements[0]);
            kendoInstance.trigger('change');
          }
        }

        /**
         * See https://docs.telerik.com/kendo-ui/api/javascript/ui/dropdownlist/methods/select
         * for specific examples of this implementation
         *
         * @param {string|jQuery|KendoWidget} param
         * @param {object} criteria See https://lodash.com/docs/4.17.11#matches
         */
        static selectByCriteria(param, criteria = {}) {
          const kendoInstance = this.getSelectableInstance(param);
          kendoInstance.select(_.matches(criteria));
          kendoInstance.trigger('change');
        }

        static toJQuery(param) {
          if (param instanceof angular.element) {
            return param;
          } else if (typeof param == 'string') {
            return angular.element(param);
          } else {
            throw new Error(
              `Expected a angular-jq obj or a string. Receieved ${param}`
            );
          }
        }
      };
    }
  ]);
})();
