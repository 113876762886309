/**
 * @ngdoc module
 * @name flowingly.user.menu
 * @module flowingly.user.menu
 * @requires templates-directives
 * @description User Menu
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/0e79da5eea4ea73285a3c42d2f3e41bfcb223521/src/Flowingly.Shared.Angular/flowingly.directives/usermenu/usermenu.module.js?at=master
 */

'use strict';
import angular from 'angular';

const userMenuModule = angular.module('flowingly.user.menu', [
  'templates-directives',
  'flowingly.user.notifications',
  'flowingly.delegation.banner'
]);

userMenuModule.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'usermenu.tmpl.html',
      require('./usermenu.tmpl.html').default
    );
  }
]);
