/**
 * @name flowinglyGrid
 * @module flowingly.directives
 * @description Bootstrap Kendo Grid with auto resize re window resize event, uploaded files initialization ready event etc... 
 * and potentially add on more helper logic for grid 
 * 
 * Input attributes
 * id - grid id (essential for this directive to functional)
 * gridIsGrouping - if grid has grouping columns (need set different heights etc...)
 * gridInitDone - set to true when grid initialization finish
 * gridNeedResize - flag to notify if need resize grid
 * autoFit - auto fit columns to content if TRUE
 * offset - offset from to top of page in pixels - used to calculate height to fill page
 * ### Notes
 * @usage
 * ```
    <kendo-grid id="reportGrid" flowingly-grid grid-is-grouping="true" grid-init-done="$ctrl.gridInitDone" grid-need-resize="$ctrl.gridNeedResize" options="$ctrl.allFlowDataGridOptions"></kendo-grid>
 * ```
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.directives/grid/flowingly.grid.directive.js?at=master
 */

'use strict';
import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

angular.module('flowingly.directives').directive('flowinglyGrid', [
  '$window',
  '$parse',
  'pubsubService',
  'lodashService',
  function (
    $window: angular.IWindowService,
    $parse: angular.IParseService,
    pubsubService: Services.PubSubService,
    lodashService: Lodash
  ) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        const gridInitWatch = scope.$watch(attrs.gridInitDone, function (val) {
          if (!val) {
            return;
          }

          setup();
          gridInitWatch();
        });

        function setup() {
          const window = angular.element($window);
          const onGridResize = function () {
            resizeGrid();
            _gridElement.getKendoGrid().refresh();
          };
          const _gridElement: any = angular.element('#' + attrs.id);
          const gridData = _gridElement.data('kendoGrid');
          const dataArea = _gridElement.find('.k-grid-content');
          let scrollLeftPosition = 0;
          const gridNeedResizeWatch = scope.$watch(
            attrs.gridNeedResize,
            function (newValue) {
              if (newValue === true) {
                onGridResize();
                scope.$ctrl.gridNeedResize = false; // Reset flag
              }
            }
          );

          if (!gridData) {
            //todo - why is this sometimes undefined?
            return;
          }

          gridData.bind('dataBinding', handleDataBounding);

          window.bind('resize', onGridResize);
          pubsubService.subscribe(
            'FILEUPLOAD_FILE_READY',
            autoFitFileUploadColumn,
            'flowingly.grid'
          );
          pubsubService.subscribe(
            'RUNNER_RESIZE_GRID',
            onGridResize,
            'flowingly.grid'
          );

          scope.$on('$destroy', function () {
            window.unbind('resize', onGridResize);
            gridNeedResizeWatch();
          });

          autoFitColumn();

          resizeGrid();

          _gridElement.getKendoGrid().refresh();

          // Only auto fit for file upload column for big performance gain
          function autoFitFileUploadColumn() {
            autoFitColumn('FileUpload'); // File upload
            resizeGrid();

            dataArea.scrollTop(0);

            // Restore scroll position
            if (scrollLeftPosition !== 0) {
              dataArea.scrollLeft(0); // Have to do this to align grid properly
              dataArea.scrollLeft(scrollLeftPosition);
            }
          }

          function autoFitColumn(fieldType?) {
            if (!$parse(attrs.autoFit)(scope)) {
              return;
            }

            const grid = _gridElement.getKendoGrid();

            lodashService.forEach(grid.columns, function (col) {
              // Auto fit for grouped columns
              if (col.columns) {
                lodashService.forEach(col.columns, function (groupedCol) {
                  if (
                    fieldType === undefined ||
                    groupedCol.fieldType === fieldType
                  ) {
                    grid.autoFitColumn(groupedCol);
                  }
                });
              } else {
                if (fieldType === undefined || col.fieldType === fieldType) {
                  grid.autoFitColumn(col);
                }
              }
            });
          }

          function resizeGrid() {
            const offset = parseInt(attrs.offset) || 200;
            const window: any = angular.element($window);
            const dataAreaTable = _gridElement.find('.k-grid-content table');
            const gridHeightToDisplayAllRows =
              _gridElement.find('.k-grid-header').innerHeight() +
              dataAreaTable.innerHeight() +
              _gridElement.find('.k-grid-pager').innerHeight();
            const adjustedWindowHeight = window.innerHeight() - offset;
            const newHeight = Math.min(
              adjustedWindowHeight,
              gridHeightToDisplayAllRows
            );
            let adjust = 0;
            let dataAreaAdjust = 0;

            if (gridHeightToDisplayAllRows > adjustedWindowHeight) {
              dataAreaAdjust = -22;
            } else {
              if (attrs.gridIsGrouping) {
                adjust = 22;
              } else {
                adjust = 3;
              }
            }

            _gridElement.height(newHeight + adjust);

            // special style when no rows available
            if (scope.$ctrl.noRows) {
              dataArea.addClass('gridNoRows');
              _gridElement.find('.k-grid-pager').hide();
            } else {
              dataArea.height(
                newHeight -
                  _gridElement.find('.k-grid-header').innerHeight() -
                  _gridElement.find('.k-grid-pager').innerHeight() +
                  dataAreaAdjust +
                  20
              );
              dataArea.removeClass('gridNoRows');
              _gridElement.find('.k-grid-pager').show();
            }
          }

          // Remember current scroll position for later restore after column auto fit
          // The grid will out of alignment if not do this
          function handleDataBounding() {
            scrollLeftPosition = dataArea.scrollLeft();
          }
        }
      }
    };
  }
]);
