/**
 * @ngdoc service
 * @name sideMenuService
 * @module flowingly.directives.sidemenu
 *
 * @description A service that maintains a list of menu items, for consumption by a directive that displays them
 *
 * ## Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Sidemenu+Directive
 *
 * ###API
 * * menu - list of menu items to display
 * * addmenuItem - add a menu item to the menu
 * * clearMenu - remove all menu items
 * * removeMenuItemByName - remove specific menu item
 * * updateItemStatusByName - update statis of specific menu item (e.g. count)
 *
 * ###Usage
 *     Consumed by flowSidemenu directive.
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/c299f716c9e0b2796659c7db833d08b988e74e6a/src/Flowingly.Shared.Angular/flowingly.directives/sidemenu/sidemenu.service.js?at=master
 */

'use strict';
import angular from 'angular';

angular
  .module('flowingly.directives.sidemenu')
  .factory('sideMenuService', sideMenuService);

sideMenuService.$inject = ['lodashService'];

function sideMenuService(lodashService: Lodash) {
  let clickCount = 0;

  //API
  const service = {
    //menu is a list of sections, that each contain a list of items
    menu: [],
    addmenuItem: addmenuItem,
    clearMenu: clearMenu,
    removeMenuItemByName: removeMenuItemByName,
    updateItemStatusByName: updateItemStatusByName,
    menuClick: menuClick,
    menuTimeOut: menuTimeOut
  };

  return service;

  /// Public API Methods /////////////////////////////////////////////////////////////

  function menuClick() {
    clickCount++;
    return clickCount;
  }

  function menuTimeOut() {
    if (clickCount > 0) clickCount--;
    return clickCount;
  }

  function addmenuItem(item) {
    service.menu.push(item);
  }

  function clearMenu() {
    service.menu.splice(0, service.menu.length);
  }

  function removeMenuItemByName(name) {
    lodashService.remove(service.menu, function (i) {
      return i.name === name;
    });
  }

  function updateItemStatusByName(name, status) {
    const item = lodashService.find(service.menu, function (i) {
      return i.name === name;
    });
    item.status = status;
  }
}

export type SideMenuServiceType = ReturnType<typeof sideMenuService>;
