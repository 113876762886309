/**
 * @ngdoc service
 * @name tempModelerUrlService
 * @module flowingly.services
 *
 * @description A service responsible solely for opening the modeler in a new tab
 *
 * ## Notes
 * It is expected that this will be a temporary service
 *
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/f55534999e2330de1cc9cfab426e29804f613cf2/src/Flowingly.Shared.Angular/flowingly.services/temp.modeler.url.service.js?at=master
 */
'use strict';

/// i.e. we return response.data.DataModel so the consumer can work with the data directly

angular
  .module('flowingly.services')
  .factory('tempModelerUrlService', tempModelerUrlService);

tempModelerUrlService.$inject = ['APP_CONFIG', 'guidService', 'sessionService'];

function tempModelerUrlService(APP_CONFIG, guidService, sessionService) {
  const service = {
    openModeler: openModeler,
    getModelerUrl: getModelerUrl
  };

  return service;

  /// Public API Methods /////////////////////////////////////////////////////////////////

  function openModeler(
    flowId,
    cloneModel: boolean,
    openFeedback: boolean,
    templateKey
  ) {
    cloneModel = cloneModel ?? false;
    openFeedback = openFeedback ?? false;
    const url = getModelerUrl(flowId, cloneModel, openFeedback, templateKey);
    openLinkInNewTab(url);
  }

  /// PRIVATE METHODS /////////////////////////////////////////////////////////////////

  function getModelerUrl(
    flowId,
    cloneModel: boolean,
    openFeedback: boolean,
    templateKey
  ) {
    const workflowId = flowId === undefined ? guidService.empty() : flowId;
    const token = sessionService.getToken();

    return (
      `${APP_CONFIG.modelerUrl}/modeler/#/workflow?workflowId=${workflowId}` +
      `&clone=${cloneModel}` +
      `&openFeedback=${openFeedback}` +
      (templateKey === undefined ? '' : `&templateKey=${templateKey}`) +
      `&token=${token}`
    );
  }

  //firefox & IE11 need a DOM click event to be fired!
  // otherwise simply a.click() would work with Chrome etc.
  function openLinkInNewTab(url) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    if (document.createEvent) {
      const ev = document.createEvent('MouseEvent');
      ev.initMouseEvent(
        'click',
        true /* bubble */,
        true /* cancelable */,
        window,
        null,
        0,
        0,
        0,
        0 /* coordinates */,
        false,
        false,
        false,
        false /* modifier keys */,
        0 /*left*/,
        null
      );
      a.dispatchEvent(ev);
    } else {
      a.click();
    }
  }
}

export type TempModelerUrlServiceType = ReturnType<
  typeof tempModelerUrlService
>;
