/**
 * @ngdoc service
 * @name tokenService
 * @module flowingly.runner.services
 *
 * @description Service responsible for managing tokens
 *
 * ## Notes
 *
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/0f13da24ee39ce3f610b78a4c1f3281fc67c8a09/src/Flowingly.Shared.Angular/flowingly.services/tokenService.js?at=master
 */
declare const moment;

('use strict');
import angular from 'angular';
// Service responsible for persisting user information using angular-storage

export interface ITenantBusiness {
  name: string;
  id: string;
}

angular.module('flowingly.services').factory('tokenService', tokenService);

tokenService.$inject = [
  '$rootScope',
  '$interval',
  '$cookies',
  '$location',
  'APP_CONFIG',
  'store',
  'jwtHelper',
  'angularAuth0',
  'momentService',
  'authLoggingApiService'
];

function tokenService(
  $rootScope,
  $interval,
  $cookies,
  $location,
  APP_CONFIG,
  store,
  jwtHelper,
  angularAuth0,
  momentService,
  authLoggingApiService
) {
  // We are using a different namespace for Development, Staging and Production (see web.config)
  const nameSpace = 'flowingly.' + APP_CONFIG.flowinglyEnvironment;
  const tokenStoreName = nameSpace + '.token';
  const ngidelExpiryName = 'ngIdle.expiry';
  let _token = store.get(tokenStoreName); //Returned when user authenticates against Auth0.com (see auth.service.js)
  let _unauthorised = false;
  let inLogoutProcess = false;

  const service = {
    getPermissions: getPermissions,
    getTenant: getTenant,
    getToken: getToken,
    checkTokenExpire: checkTokenExpire,
    clearToken: clearToken,
    refreshTokenIfPermissionsDonotExist: refreshTokenIfPermissionsDonotExist,
    setToken: setToken,
    setIdelExpire: setIdelExpire,
    setUIIdelExpire: setUIIdelExpire,
    manualIdelCheck: manualIdelCheck,
    isAllApplicationIdle: isAllApplicationIdle,
    setUnauthorisedAccess: setUnauthorisedAccess,
    wasUnauthorisedAccess: wasUnauthorisedAccess,
    setInLogoutProcess: setInLogoutProcess,
    rehome: rehome
  };

  return service;

  //////////// Public API Methods

  function setInLogoutProcess(val) {
    inLogoutProcess = val;
  }

  function setUnauthorisedAccess(access) {
    _unauthorised = access;
  }

  function wasUnauthorisedAccess() {
    return _unauthorised;
  }

  function getPermissions() {
    if (_token) {
      return jwtHelper.decodeToken(_token).flowinglypermissions;
    }
  }

  function getTenant(): ITenantBusiness {
    if (_token) {
      const tokenData = jwtHelper.decodeToken(_token);
      return {
        id: tokenData.businessidentifier,
        name: tokenData.businessname
      };
    }
  }

  function getToken() {
    _token = store.get(tokenStoreName);
    return _token;
  }

  function isAllApplicationIdle() {
    const idleExpiry = $cookies.get(ngidelExpiryName);

    if (
      idleExpiry &&
      momentService.utc(idleExpiry).isBefore(momentService.utc())
    )
      return true;

    return false;
  }

  function manualIdelCheck() {
    const idleExpiry = $cookies.get(ngidelExpiryName),
      interval = momentService(idleExpiry) - momentService() + 2000;

    console.log(`manualIdelCheck after seconds Web: ${interval / 1000}`);

    if (interval > 0) {
      $interval(
        () => {
          $rootScope.$broadcast('IdleStart');
        },
        interval,
        1
      );
    }
  }

  function refreshTokenIfPermissionsDonotExist(isRequestUserCondition) {
    const domainPort =
      window.location.port !== '' ? `:${window.location.port}` : '';
    const currentDomain = `${window.location.protocol}//${window.location.hostname}${domainPort}`;

    authLoggingApiService.log(
      'tokenService.ts refreshTokenIfPermissionsDonotExist() called'
    );
    const checkoldTokenPromise = new Promise((resolve, reject) => {
      // for invite user, shouldn't look local storage token at all!
      if (
        $location.path().toLowerCase() === '/acceptinvite' &&
        $location.search().inviteToken &&
        !isRequestUserCondition
      ) {
        resolve(undefined);
      } else {
        const token = getToken();

        if (token) {
          // if token expired, don't refresh it
          if (isAllApplicationIdle() && !inLogoutProcess) {
            clearToken();
            authLoggingApiService.log(
              'tokenService.ts refreshTokenIfPermissionsDonotExist() - rejecting with message Error: session expired! and sending re-dire-ct-url as /flowsactive'
            );
            reject({
              error: 'Error: session expired!',
              redirectUri: currentDomain + '/flowsactive/'
            });
          }

          if (!getPermissions()) {
            angularAuth0.checkSession(
              {
                responseType: 'id_token token',
                clientID: APP_CONFIG.auth0ClientId,
                redirectUri: currentDomain + '/flowsactive/'
              },
              function (err, delegationResult) {
                if (delegationResult) {
                  setToken(delegationResult.idToken);
                  authLoggingApiService.log(
                    'tokenService.ts refreshTokenIfPermissionsDonotExist() resolving with id token'
                  );
                  resolve(delegationResult.idToken);
                } else {
                  authLoggingApiService.log(
                    'tokenService.ts refreshTokenIfPermissionsDonotExist() rejecting with message Error: Refresh of the token failed!'
                  );
                  reject('Error: Refresh of the token failed!');
                }
              }
            );
          } else {
            authLoggingApiService.log(
              'tokenService.ts refreshTokenIfPermissionsDonotExist() rejecting with message undefined'
            );
            resolve(undefined); // Token was not expired
          }
        } else {
          authLoggingApiService.log(
            'tokenService.ts refreshTokenIfPermissionsDonotExist() rejecting with message Missing token'
          );
          resolve({ error: 'Missing token' });
        }
      }
    });

    return checkoldTokenPromise;
  }

  function setToken(token) {
    authLoggingApiService.log('tokenService.ts setToken() called');

    _token = token;
    store.set(tokenStoreName, _token);
    setIdelExpire();
  }

  function setIdelExpire() {
    const expireTime = moment
      .utc()
      .add(APP_CONFIG.sessionTimeoutInSecond, 's')
      .toISOString();
    $cookies.put(ngidelExpiryName, expireTime);
  }

  function clearToken() {
    authLoggingApiService.log('tokenService.ts clearToken() called');
    store.remove(tokenStoreName);
    _token = undefined;
  }

  function setUIIdelExpire(val) {
    store.set(ngidelExpiryName, { time: val });
  }

  // Returns new token if it is refreshed, else returns null
  // If there is an error it rejects the promise
  function checkTokenExpire(redirectUri, isRequestUserCondition) {
    authLoggingApiService.log('tokenService.ts checkTokenExpire() called');
    const checkTokenExpirePromise = new Promise((resolve, reject) => {
      // for invite user, shouldn't look local storage token at all!
      if (
        $location.path().toLowerCase() === '/acceptinvite' &&
        $location.search().inviteToken &&
        !isRequestUserCondition
      ) {
        reject();
      } else {
        const token = getToken();

        if (token) {
          if (isTokenExpired(token)) {
            const domainPort =
              window.location.port !== '' ? `:${window.location.port}` : '';
            const currentDomain = `${window.location.protocol}//${window.location.hostname}${domainPort}`;

            angularAuth0.checkSession(
              {
                responseType: 'id_token token',
                clientID: APP_CONFIG.auth0ClientId,
                redirectUri: currentDomain + (redirectUri || '')
              },
              function (err, delegationResult) {
                if (delegationResult) {
                  setToken(delegationResult.idToken);
                  authLoggingApiService.log(
                    'tokenService.ts checkTokenExpire() resolving with id token'
                  );
                  resolve(delegationResult.idToken);
                } else {
                  authLoggingApiService.log(
                    'tokenService.ts checkTokenExpire() rejecting with message Error: Refresh of the token failed!'
                  );
                  reject('Error: Refresh of the token failed!');
                }
              }
            );
          } else {
            authLoggingApiService.log(
              'tokenService.ts checkTokenExpire() rejecting with message null'
            );
            resolve(null); // Token was not expired
          }
        } else {
          authLoggingApiService.log(
            'tokenService.ts checkTokenExpire() rejecting with message Error: Missing token'
          );
          reject('Error: Missing token');
        }
      }
    });

    return checkTokenExpirePromise;
  }

  function isTokenExpired(token) {
    const willExpireInSeconds = 45;
    return (
      jwtHelper.getTokenExpirationDate(token) - new Date().getTime() <
      willExpireInSeconds * 1000
    );
  }

  function rehome(home: string) {
    const currentUrl = new URL(window.location.toString());
    const homeUrl = new URL(home);
    if (
      currentUrl.protocol !== homeUrl.protocol ||
      currentUrl.hostname !== homeUrl.hostname ||
      currentUrl.port !== homeUrl.port
    ) {
      const token = getToken();
      if (token && token !== 'null') {
        const rehomeUrl = currentUrl;
        rehomeUrl.protocol = homeUrl.protocol;
        rehomeUrl.hostname = homeUrl.hostname;
        rehomeUrl.port = homeUrl.port;
        const rehomeParams = rehomeUrl.searchParams;
        rehomeParams.delete('token');
        rehomeParams.append('token', token);
        window.location.href = rehomeUrl.href;
      }
    }
  }
}

export type TokenServiceType = ReturnType<typeof tokenService>;
