/*
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/f460f2f16cf3d6f22680828e178182d507cef9f1/src/Flowingly.Shared.Angular/flowingly.services/flowingly.constants.js?at=master
 */
import * as angular from 'angular';

export enum StepTypeName {
  TASK = 'Task',
  APPROVAL = 'Approval',
  PARALLEL_APPROVAL = 'Parallel Approval',
  SEQUENTIAL_APPROVAL = 'Sequential Approval',
  CUSTOM_EMAIL = 'Custom Email',
  PUBLIC_FORM = 'Public Form'
}

export enum StepType {
  TASK = '1',
  APPROVAL = '3',
  PARALLEL_APPROVAL = '4',
  SEQUENTIAL_APPROVAL = '5',
  CUSTOM_EMAIL = '6',
  COMPONENT = '11',
  PUBLIC_FORM = '99' // This is a virtual Task Type - its only needed by the UI!
}

export enum TaskType { // this is also Node.StepType
  TASK = 0,
  CUSTOM_EMAIL = 6,
  APPROVAL = 8,
  PARALLEL_APPROVAL = 9,
  SEQUENTIAL_APPROVAL = 10,
  COMPONENT = 11,
  PUBLIC_FORM = 99
}

export enum NodeCategory {
  ACTIVITY = 'activity',
  EXCLUSIVE_GATEWAY = 'exclusiveGateway',
  DIVERGE_GATEWAY = 'divergeGateway',
  CONVERGE_GATEWAY = 'convergeGateway',
  COMPONENT = 'component',
  EVENT = 'event'
}

export enum ApprovalTaskOptions {
  REJECT_AND_COMPLETE = '0',
  APPROVE = '1',
  REJECT_AND_GO_BACK = '2',
  REJECT_AND_GO_BACK_TO_FIRST_TASK = '3'
}

export enum FormFieldType {
  INSTRUCTION = 'instruction',

  TEXT = 'text',
  SHORT_TEXT = TEXT, // alias

  TEXTAREA = 'textarea',
  EMAIL = 'email',
  PASSWORD = 'password',
  TABLE = 'table',
  DATE = 'date',
  DATETIME = 'datetime',
  SIGNATURE = 'signature',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  CURRENCY = 'currency',
  FILE_UPLOAD = 'fileupload',
  TASK_LIST = 'tasklist',
  MULTISELECT_LIST = 'multiselectlist',
  APPROVAL_RULE = 'approvalrule',

  RADIO_BUTTON_LIST = 'radiobuttonlist', // Option list
  OPTIONLIST = RADIO_BUTTON_LIST, // alias

  SELECT_LIST = 'selectlist', // Dropdown list
  DROPDOWN_LIST = SELECT_LIST, // alias
  LOOKUP = 'lookup',

  FORMULA = 'formula',
  APPROVAL_COMMENT = 'approvalcomment',
  ATTACH_DOCUMENT = 'attachdocument',
  IMAGE = 'image'
}
export enum FormFieldTypePascal {
  INSTRUCTION = 'Instruction',

  TEXT = 'Text',
  SHORT_TEXT = TEXT, // alias

  TEXTAREA = 'Textarea',
  EMAIL = 'Email',
  PASSWORD = 'Password',
  TABLE = 'Table',
  DATE = 'Date',
  DATETIME = 'Datetime',
  SIGNATURE = 'Signature',
  NUMBER = 'Number',
  CHECKBOX = 'Checkbox',
  CURRENCY = 'Currency',
  FILE_UPLOAD = 'Fileupload',
  TASK_LIST = 'Tasklist',
  MULTISELECT_LIST = 'MultiSelectList',
  APPROVAL_RULE = 'ApprovalRule',

  RADIO_BUTTON_LIST = 'RadiobuttonList', // Option list
  OPTIONLIST = RADIO_BUTTON_LIST, // alias

  SELECT_LIST = 'Selectlist', // Dropdown list
  DROPDOWN_LIST = SELECT_LIST, // alias
  LOOKUP = 'LookUp',

  FORMULA = 'Formula',
  APPROVAL_COMMENT = 'ApprovalComment',
  ATTACH_DOCUMENT = 'AttachDocument',
  IMAGE = 'Image'
}

export enum FormFieldTypeEnum {
  APPROVAL_COMMENT = 0,
  APPROVAL_RULE = 1,
  BUTTON = 2,
  CHECKBOX = 3,
  CURRENCY = 4,
  EMAIL = 5,
  FILE_UPLOAD = 6,
  INSTRUCTION = 7,
  TABLE = 8,
  TEXT = 9,
  TEXT_AREA = 10,
  TEXT_BOX = 11,
  NUMBER = 12,
  PASSWORD = 13,
  RADIO_BUTTON_LIST = 14,
  SELECT_LIST = 15,
  SUBMIT = 16,
  TASK_LIST = 17,
  MULTISELECT_LIST = 18,
  DATE = 19,
  DATETIME = 20,
  SIGNATURE = 21,
  DYNAMIC_ACTORS = 22,
  LOOKUP = 23,
  FORMULA = 24,
  ATTACH_DOCUMENT = 25,
  IMAGE = 26
}

export enum NumberOfApproversRequiredType {
  ALL = 0,
  SUBSET = 1
}

export enum WhenApproversSelected {
  RUN_TIME = 0,
  MODEL_TIME = 1
}

export enum FlowinglyRoles {
  BUSINESS_ADMINISTRATOR = 'Business Administrator',
  FLOWINGLY_ADMINISTRATOR = 'Flowingly Administrator',
  WORKFLOW_USER = 'Workflow User',
  FLOW_MODEL_ADMINISTRATOR = 'Flow Model Administrator',
  REPORT_ADMINISTRATOR = 'Report Administrator'
}

export enum Permissions {
  ADMINISTRATION_ACCESS = 'admin.access',
  BUSINESS_CLONE = 'business.clone',
  BUSINESS_SIGNUP = 'business.signup',
  FLOW_ALL_ACCESS = 'flow.all.access',
  FLOW_CANCEL = 'flow.cancel',
  FLOW_STARTONBEHALF = 'flow.startonbehalf',
  FLOWMODEL_DELETE = 'flowmodel.delete',
  FLOWMODEL_OWNER_CANDIDATE = 'flowmodel.owner.candidate',
  FLOWMODEL_OWNER_UPDATE = 'flowmodel.owner.update',
  FLOWMODEL_REPORTOWNER_CANDIDATE = 'flowmodel.reportowner.candidate',
  FLOWMODEL_REPORTOWNER_UPDATE = 'flowmodel.reportowner.update',
  FLOWMODEL_UPDATE = 'flowmodel.update',
  FLOW_NONCONFIDENTIAL_ACCESS = 'flow.nonconfidential.access',
  FLOW_START = 'flow.start',
  FLOW_STEP_REASSIGN = 'flow.step.reassign',
  FLOW_STEPTASK_CANCEL = 'flow.steptask.cancel',
  FLOW_STEPTASK_REASSIGN = 'flow.steptask.reassign',
  FLOW_STEPTASK_VIEW = 'flow.steptask.view',
  FLOW_WEBHOOK_SKIP = 'flow.webhook.skip',
  LIBRARY_ACCESS = 'library.access',
  MODELER_ACCESS = 'modeler.access',
  PUBLICAPI_ACCESS = 'publicapi.access',
  REPORT_BI_ACCESS = 'report.bi.access',
  SETUP_CATEGORY_ACCESS = 'setup.category.access',
  SETUP_DATABASE_ACCESS = 'setup.database.access',
  SETUP_ROLE_ACCESS = 'setup.role.access',
  SETUP_ROLE_ADD = 'setup.role.add',
  SETUP_ROLE_DELETE = 'setup.role.delete',
  SETUP_ROLE_UPDATE = 'setup.role.update',
  SETUP_TEAM_ACCESS = 'setup.team.access',
  SETUP_USER_ACCESS = 'setup.user.access',
  SUPPORT_ACCESS = 'support.access',
  TEMPLATE_ADMIN = 'template.admin',
  TENANT_ACCESS = 'tenant.access',
  TODO_ACCESS = 'todo.access',
  USER_CREATE = 'user.create',
  USER_ROLE_UPDATE = 'user.role.update'
}

export enum SearchEntityType {
  USER = 'User',
  GROUP = 'Group',
  APPROVER = 'Approver',
  STEP_FORM_FIELD = 'StepFormField'
}

export enum LineCoordinator {
  NONE = 'NONE',
  STANDARD = 'STANDARD',
  CUSTOMIZE = 'CUSTOMIZE'
}

export enum FlowModelPublishType {
  WORKFLOW = 0,
  PROCESS_MAP = 1,
  WORKFLOW_COMPONENT = 2,
  PROCESS_MAP_COMPONENT = 3
}

export enum CommentTargetType {
  FLOW = 1,
  FLOW_MODEL = 2,
  STEP_TASK = 3
}

export enum NotificationType {
  FLOW_COMMENT = 1,
  FLOW_MODEL_FEEDBACK = 2,
  STEP_TASK = 3
}

export enum TableCellType {
  TEXT = 1,
  CURRENCY = 2,
  NUMBER = 3,
  FILE = 4,
  DATE = 5,
  DROPDOWN = 6,
  LOOKUP = 7,
  FORMULA = 8
}

export enum TableColumnsName {
  FullName = 'fullName',
  TeamName = 'Team Name',
  Name = 'Name',
  Ppoc = 'Primary Point of Contact',
  PpocAlias = 'Ppoc',
  Teams = 'Teams',
  ManagerName = 'ManagerName',
  Users = 'Users'
}

export enum TableNoRowMessage {
  TABLE_NO_ROW = 'No rows were added'
}

export enum FormulaOperandPrefix {
  FIELD = 'field',
  CELL = 'column'
}

export enum ReassignType {
  Step = 'Step',
  StepTask = 'Step Task'
}

// TODO: Is there a better way to represent this than an enum. Going with enum for the time being
// as all others are enums.
// This matches with WorkflowPrepopulateFieldConstants.cs in the backend.
export enum LookupPreviousFieldTypeAndText {
  CurrentActorEmail = 'CurrentActorEmail',
  CurrentActorEmailText = 'Logged In User Email',
  CurrentActorName = 'CurrentActorName',
  CurrentActorNameText = 'Logged In User Name'
}

export enum FormFieldConditionOption {
  Filled = 0,
  Empty = 1,
  Equals = 2,
  NotEquals = 3
}

export enum FormFieldConditionAction {
  Show = 0,
  Hide = 1
}

export enum FileFolder {
  Stepfiles = 'stepfiles',
  Templates = 'templates'
}

const flowinglyConstants = {
  stepTypeName: StepTypeName,
  stepType: StepType,
  taskType: TaskType,
  nodeCategory: NodeCategory,
  approvalTaskOptions: ApprovalTaskOptions,
  formFieldType: FormFieldType,
  formFieldTypePascal: FormFieldTypePascal,
  formFieldTypeEnum: FormFieldTypeEnum,
  numberOfApproversRequiredType: NumberOfApproversRequiredType,
  whenApproversSelected: WhenApproversSelected,
  flowinglyRoles: FlowinglyRoles,
  permissions: Permissions,
  searchEntityType: SearchEntityType,
  EVERYONE_GROUP_NAME: 'Everyone',
  lineCoordinator: LineCoordinator,
  searchableComboPageSize: 100,
  maxNumberOfUsersToShow: 100,
  flowModelPublishType: FlowModelPublishType,
  commentTargetType: CommentTargetType,
  notificationType: NotificationType,
  tableCellType: TableCellType,
  tableColumnsName: TableColumnsName,
  EMPTY_NAME: 'Please Choose...',
  CUSTOM_EMAIL_REPLY_TO: 'no-reply@flowingly.net',
  CUSTOM_EMAIL_FROM_NAME: 'Flowingly Notifications',
  minTextLengthToSearch: 1,
  emptyData: 'No Data Found',
  numericOperations: ['=', '!=', '<=', '>=', '<', '>'],
  tableNumberCalculationTypes: ['Sum'],
  tableNoRowMessage: TableNoRowMessage,
  formulaOperandPrefix: FormulaOperandPrefix,
  reassignType: ReassignType,
  lookupPreviousFieldTypeAndText: LookupPreviousFieldTypeAndText,
  formFieldConditionOption: FormFieldConditionOption,
  formFieldConditionAction: FormFieldConditionAction,
  fileFolder: FileFolder
};

angular
  .module('flowingly.services')
  .constant('flowinglyConstants', flowinglyConstants);

export type FlowinglyConstantsType = typeof flowinglyConstants;
