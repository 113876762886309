/**
 * @name intercomMessageLauncher
 * @module flowingly.runner.directives
 * @description display custom launcher for intercom messenger
 * ### Notes
 * 
 * @usage
 * ```
    <intercom-message-launcher></intercom-message-launcher>
 * ```
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/37bb6cdcbb0c1c6551b1f51a25cff368f175269d/src/Flowingly.Shared.Angular/flowingly.directives/intercom.message.launcher/intercom.message.launcher.js?at=master
 */

'use strict';

import { Services } from '@Shared.Angular/@types/services';

angular.module('flowingly.directives').component('intercomMessageLauncher', {
  bindings: {
    intercomAppId: '<'
  },
  controller: [
    'APP_CONFIG',
    'pubsubService',
    'flowsUtilityService',
    'notificationService',
    'intercomService',
    function (
      APP_CONFIG: Services.AppConfigCommon,
      pubsubService: Services.PubSubService,
      flowsUtilityService: Services.FlowsUtilityService,
      notificationService: Services.NotificationService,
      intercomService: Services.IntercomService
    ) {
      const $ctrl = this;
      $ctrl.unreadCount = undefined;
      $ctrl.showBadge = false;
      $ctrl.isMobile = flowsUtilityService.isMobileDevice();
      $ctrl.onSupportClick = () => {
        if (!flowsUtilityService.isInSupportHours()) {
          intercomService.turnOffLauncher();
          notificationService.showInfoToast(
            APP_CONFIG.outOfHoursSupportMessage
          );
        }
      };

      $ctrl.$onInit = function () {
        pubsubService.subscribe(
          'INTERCOM_UNREAD_MESSAGE_COUNT_CHANGED',
          onUnreadCountChange,
          'intercom.launcher'
        );
      };

      function onUnreadCountChange(event, data) {
        $ctrl.unreadCount = data;

        $ctrl.showBadge = $ctrl.unreadCount > 0;
      }
    }
  ],
  templateUrl: 'intercom.message.launcher.tmpl.html'
});
