/*
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/f460f2f16cf3d6f22680828e178182d507cef9f1/src/Flowingly.Shared.Angular/flowingly.directives/user-notifications/user.notifications.component.js?at=master
 */

'use strict';
import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';
///
/// This component is used to display the notifications to user

angular.module('flowingly.user.notifications').component('userNotifications', {
  templateUrl: 'user.notifications.tmpl.html',
  bindings: {
    isMobile: '<'
  },
  controller: [
    '$state',
    '$window',
    '$timeout',
    'userNotificationsApiService',
    'pubsubService',
    'tempModelerUrlService',
    'flowinglyConstants',
    function (
      $state: angular.ui.IStateService,
      $window: angular.IWindowService,
      $timeout: angular.ITimeoutService,
      userNotificationsApiService: Services.UserNotificationsApiService,
      pubsubService: Services.PubSubService,
      tempModelerUrlService: Services.TempModelerUrlService,
      flowinglyConstants: Services.FlowinglyConstants
    ) {
      const $ctrl = this;
      $ctrl.notificationList = [];
      $ctrl.showNotifications = false;

      $ctrl.dismissNotification = dismissNotification;
      $ctrl.onNotificationClick = onNotificationClick;
      $ctrl.readNotification = readNotification;
      $ctrl.getMoreNotifications = getMoreNotifications;
      $ctrl.getNotificationCount = getNotificationCount;
      $ctrl.hasMoreUnreadNotifications = true;

      $ctrl.pageNumber = 0;
      $ctrl.pageSize = 10;

      if (!$ctrl.isMobile) {
        $(window).on('click', function (event) {
          const target = event.target;
          if (
            target.id === 'userNotifications' ||
            target.id === 'tab-inprogress-link-large-screen' ||
            angular.element(target).hasClass('yay-toggle hamburger') ||
            angular.element(target).hasClass('yay-toggle side-toggle') ||
            angular.element(target).hasClass('unreadNotificationLink')
          )
            return;

          if ((angular.element('div#notification') as any).has(target).length) {
            return;
          }

          $timeout(function () {
            $ctrl.showNotifications = false;
            $ctrl.notificationList = [];
          });
        });
      }

      this.$onInit = function () {
        if ($ctrl.isMobile) {
          onNotificationClick();
        }
        userNotificationsApiService.getNotificationCount(false);
        pubsubService.subscribe(
          'SIGNALR_USER_NOTIFICATION_COUNT_CHANGED',
          updateUserNotificationCountChanged,
          'runner.user.notifications'
        );
      };

      this.$onDestroy = function () {
        $window.click = null;
      };

      function dismissNotification(notification) {
        userNotificationsApiService
          .dismissNotification(notification.id, false)
          .then(function () {
            $ctrl.notificationList = $ctrl.notificationList.filter(function (
              obj
            ) {
              return obj.id !== notification.id;
            });
            if (notification.read === false) {
              userNotificationsApiService.notificationCount--;
            }
          });
      }

      function getMoreNotifications() {
        $ctrl.pageNumber = $ctrl.pageNumber + 1;
        getNotificationsByPageIndex($ctrl.pageNumber);
      }

      function getNotificationCount() {
        return userNotificationsApiService.notificationCount;
      }

      function onNotificationClick() {
        $ctrl.showNotifications = !$ctrl.showNotifications;
        if (!$ctrl.showNotifications) {
          $ctrl.notificationList = [];
          return;
        }
        $ctrl.pageNumber = 0;
        getNotificationsByPageIndex($ctrl.pageNumber);
      }

      function readNotification(flowId, notificationType, stepTask) {
        $ctrl.showNotifications = false;
        if (
          notificationType === flowinglyConstants.notificationType.FLOW_COMMENT
        ) {
          $state.go('app.runner.flow', {
            flowId: flowId,
            showComments: true
          });
        } else if (
          notificationType === flowinglyConstants.notificationType.STEP_TASK
        ) {
          $state.go('app.runner.flow', {
            flowId: flowId,
            stepTask: stepTask
          });
        } else {
          tempModelerUrlService.openModeler(flowId, false, true);
        }

        userNotificationsApiService.readNotification(flowId);
      }

      //private methods
      function updateUserNotificationCountChanged() {
        userNotificationsApiService.getNotificationCount(false);
      }

      function getNotificationsByPageIndex(pageNumber) {
        userNotificationsApiService
          .getNotifications(pageNumber, false)
          .then(function (response) {
            $ctrl.notificationList = $ctrl.notificationList.concat(response);
            $ctrl.hasMoreUnreadNotifications =
              (response.length < $ctrl.pageSize ? false : true) ||
              $ctrl.pageNumber === 0;
          });
      }
    }
  ]
});
