/*
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/21cc14ca61e6228593c0763c04d7fdb857c15761/src/Flowingly.Shared.Angular/flowingly.components/easyinput/flow.select.user.js?at=master
 */
(function () {
  'use strict';
  class SelectUserController {
    //bindings
    ngModel: any;
    exclude: any;
    include: any;
    isDisabled: any;
    noEmpty: any;
    onSelect: any;
    onChange: any;
    onClose: any;
    onOpen: any;

    svcs: any;

    constructor($attrs, $q, lodashService, userApiService, sessionService) {
      this.svcs = { userApiService, sessionService, lodashService, $q };
      this.fetchUsers = this.fetchUsers.bind(this);
      this.$onChanges = this.$onChanges.bind(this);
      this.multipleStandardsAdapter = this.multipleStandardsAdapter.bind(this);
    }

    $onChanges() {
      this.ngModel = this.multipleStandardsAdapter(this.ngModel);
    }

    /**
     * Different APIs return different standards of these values
     * So we do a catch all here and handle the display and
     * value ourselves.
     *
     * NOTE: {} is not blank. Whne combined with no-empty,
     * the behavior is that it is initially empty, then if you
     * select 1, you are unable to go back to the empty state
     */
    multipleStandardsAdapter(user) {
      if (user) {
        user._selectValue = user.Id || user.id;
        user._selectText =
          user.FullName ||
          user.fullName ||
          (user.firstName && `${user.firstName} ${user.lastName}`) ||
          '';
        return user;
      }
    }

    fetchUsers({ term }) {
      const { $q, lodashService, userApiService } = this.svcs;
      const { differenceWith, isArray, toLower, sortedUniqBy, sortBy, concat } =
        lodashService;
      return $q
        .all([
          userApiService.searchUsers({ term }),
          this.fetchSpecificUsers(this.include)
        ])
        .then(([foundActors, specificActors]) => {
          const actors = _.concat(foundActors, specificActors);
          // if there is an id to exclude (via the exclude param), we filter it out here
          if (this.exclude) {
            const excludeSet = (
              isArray(this.exclude) ? this.exclude : [this.exclude]
            ).map(toLower);
            return differenceWith(
              actors,
              excludeSet,
              (actor, id) => toLower(actor.id) === id
            );
          } else {
            return actors;
          }
        })
        .then((users) => {
          // we remap id to Id because the backend returns it as "id"
          // and everywhere else in the app we use "Id"
          return users.map(this.multipleStandardsAdapter);
        })
        .then((users) => sortBy(users, 'fullName'))
        .then((users) => sortedUniqBy(users, 'id'));
    }

    fetchSpecificUsers(idsToFetch) {
      if (!idsToFetch) return [];

      const { $q, lodashService, userApiService } = this.svcs;
      const { isArray } = lodashService;

      idsToFetch = isArray(idsToFetch) ? idsToFetch : [idsToFetch];
      const requests = idsToFetch.map((idToFetch) =>
        userApiService.getUser(idToFetch)
      );
      return $q.all(requests);
    }
  }

  SelectUserController.$inject = [
    '$attrs',
    '$q',
    'lodashService',
    'userApiService',
    'sessionService'
  ];
  angular
    .module('flowingly.components')
    .controller('selectUserController', SelectUserController);

  angular.module('flowingly.components').component('selectUser', {
    bindings: {
      ngModel: '=',
      exclude: '<', // UserId | UserId[] to exclude
      include: '<', // UserId | UserId[] to exclude

      // pass through bindings
      isDisabled: '<',
      noEmpty: '<', // if true, everytime the box is emptied, it returns to the original
      onSelect: '<',
      onChange: '<',
      onClose: '<',
      onOpen: '<'
    },
    controller: 'selectUserController',
    template: `
                <kendo-combo-box-facade 
                    ng-if="$ctrl.fetchUsers"
                    ng-model="$ctrl.ngModel"
                    autocomplete=true
                    is-disabled="isDisabled"
                    placeholder="Select User"
                    field-text="_selectText"
                    field-value="_selectValue"
                    clear-button="false"
                    no-empty="$ctrl.noEmpty"
                    on-select="$ctrl.onSelect"
                    on-change="$ctrl.onChange"
                    on-close="$ctrl.onClose"
                    on-open="$ctrl.onOpen"
                    data="$ctrl.fetchUsers">
                </kendo-combo-box-facade>
                `
  });
})();
