/**
 * @ngdoc service
 * @name permissionsService
 * @module flowingly.directives
 *
 * @description A service for checking user permissions
 *
 * ## Notes
 *
 * ###API
 * * check - check if used meets required permissions
 *
 * ###Usage
 *     Consumed by flow.if.permissions.directive directive.
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.directives/permissions/flow.permissions.service.js?at=master
 */

'use strict';
angular
  .module('flowingly.directives')
  .factory('permissionsService', ['sessionService', permissionsService]);

permissionsService.$inject = [];

function permissionsService(sessionService) {
  const service = {
    checkEnvironment: checkEnvironment,
    currentUserHasPermission: currentUserHasPermission,
    currentUserHasPermissions: currentUserHasPermissions,
    currentUserHasAnyOfPermissions: currentUserHasAnyOfPermissions
  };

  return service;

  /// Public API Methods /////////////////////////////////////////////////////////////

  function checkEnvironment(allowedEnvironments, currentEnvironment) {
    if (allowedEnvironments === undefined) {
      return true;
    }
    return allowedEnvironments.indexOf(currentEnvironment) >= 0;
  }

  function currentUserHasPermission(requiredPermission) {
    return currentUserHasPermissions([requiredPermission]);
  }

  function currentUserHasPermissions(requiredPermissions) {
    if (requiredPermissions === undefined || requiredPermissions.length === 0) {
      return false;
    }
    const userPermissions = sessionService.getPermissions();
    return requiredPermissions.every((permission) => {
      return userPermissions.indexOf(permission) >= 0;
    });
  }

  function currentUserHasAnyOfPermissions(requiredPermissions) {
    if (requiredPermissions === undefined || requiredPermissions.length === 0) {
      return false;
    }
    const userPermissions = sessionService.getPermissions();
    return requiredPermissions.some((permission) => {
      return userPermissions.indexOf(permission) >= 0;
    });
  }
}

export type PermissionsServiceType = ReturnType<typeof permissionsService>;
