/*
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.services/flowingly.gateway.service.js?at=master
 */

'use strict';
import angular from 'angular';

angular
  .module('flowingly.services')
  .factory('flowinglyGatewayService', flowinglyGatewayService);

flowinglyGatewayService.$inject = ['BPMN_CONSTANTS'];

function flowinglyGatewayService(BPMN_CONSTANTS) {
  //API
  const service = {
    isGateway: isGateway,
    isExclusiveGateway: isExclusiveGateway,
    isDivergeGateway: isDivergeGateway,
    isConvergeGateway: isConvergeGateway
  };

  return service;

  //////////// Public API Methods
  function isGateway(modelNode) {
    return (
      modelNode &&
      modelNode.category.toLowerCase().indexOf(BPMN_CONSTANTS.GATEWAY) >= 0
    );
  }

  function isExclusiveGateway(modelNode) {
    return (
      modelNode &&
      modelNode.category === BPMN_CONSTANTS.GatewayType.EXCLUSIVE_GATEWAY
    );
  }

  function isDivergeGateway(modelNode) {
    return (
      modelNode && modelNode.category === BPMN_CONSTANTS.GatewayType.DIVERGE
    );
  }

  function isConvergeGateway(modelNode) {
    return (
      modelNode && modelNode.category === BPMN_CONSTANTS.GatewayType.CONVERGE
    );
  }
}

export type FlowinglyGatewayServiceType = ReturnType<
  typeof flowinglyGatewayService
>;
