/**
 * @ngdoc service
 * @name auditService
 * @module flowingly.Shared.Angular
 *
 * @description A service for logging to the System Audit table on server side
 *
 * Converted to ts on 14/01/2020
 * Original here
 * https://bitbucket.org/flowingly-team/flowingly-source-code/src/397bb4e2c42476f3e5d50971ae18c2b8c5da710e/src/Flowingly.Shared.Angular/flowingly.services/audit.service.js?at=master
 */

'use strict';
import angular from 'angular';
import { Services } from '../@types/services';

angular.module('flowingly.services').factory('auditService', auditService);

auditService.$inject = ['$http', 'APP_CONFIG', 'sessionService'];

function auditService(
  $http: angular.IHttpService,
  APP_CONFIG: Services.AppConfigCommon,
  sessionService: Services.SessionServiceCommon
) {
  const MESSAGE = {
    NOTIFICATION_RECEIVED: 'Notification received',
    NOTIFICATION_HANDLED: 'Notification handled'
  };

  const auditApiBaseUrl = APP_CONFIG.apiBaseUrl + 'log';

  const service = {
    getSystemAuditId: getSystemAuditId,
    updateSystemAuditRecord: updateSystemAuditRecord,
    handledNotification: handledNotification,
    receivedNotification: receivedNotification,
    MESSAGE: MESSAGE
  };

  return service;

  //////////// Public API Methods

  function receivedNotification(parsedData) {
    const auditId = getSystemAuditId(parsedData);
    if (auditId) {
      updateSystemAuditRecord(auditId, MESSAGE.NOTIFICATION_RECEIVED, 0, true);
    }
    return auditId;
  }

  function handledNotification(auditId) {
    if (auditId) {
      updateSystemAuditRecord(auditId, MESSAGE.NOTIFICATION_HANDLED, 1, false);
    }
  }

  function getSystemAuditId(parsedData) {
    if (parsedData.systemAuditId && parsedData.systemAuditId !== '')
      return parsedData.systemAuditId;
    return undefined;
  }

  function updateSystemAuditRecord(systemAuditId, message, success, increment) {
    return $http.put(auditApiBaseUrl + '/audit', {
      SystemAuditId: systemAuditId,
      UserId: getUserId(),
      Message: message,
      Success: success,
      Increment: increment
    });
  }

  //////////// Private Methods

  function getUserId() {
    return sessionService.getUser().id;
  }
}

export type AuditServiceType = ReturnType<typeof auditService>;
